import React from "react";
import {Link} from 'react-router-dom'




class Stearates extends React.Component{
    render(){
        return(
          <section>
          <div>
          <div className="heading">
                
              
                  <h2 className="h1" style={{textAlign:"center"}}>
                    <strong>Micropac 80</strong>{" "}
                  </h2>
               
                </div>
                <div className="container">
            <p>
              MICROPAC 80 is a white pigment with ultrafine particle size and even
              particle shapes which act as a spacer and improves the scattering
              properties of TiO2 pigment, hence increasing the efficiency of white
              pigment and lowering the overall pigment cost. It is fluffy powder with
              high gloss, obtained by a highly complex production process. This newly
              developed opacifier can be used in a number of applications as a partial
              replacement of white pigment. It is dispersible in all medium and provide
              whiteness and brightness to the product.
            </p>
            </div>
          </div>
          <div>
          <div className="container">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th scope="col">SN</th>
                  <th scope="col">PROPERTIES </th>
                  <th scope="col">MICROPAC 80</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Physical Appearance</td>
                  <td>Fine White Powder</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Physical Appearance</td>
                  <td>Fine White Powder</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Physical Appearance</td>
                  <td> &lt; 4.0</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td>Physical Appearance</td>
                  <td>Min 99.00 %</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td>Physical Appearance</td>
                  <td>&lt; 2.0 %</td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td>Physical Appearance</td>
                  <td>7.00 – 9.00</td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td>Physical Appearance</td>
                  <td>20 + 2</td>
                </tr>
                <tr>
                  <th scope="row">8</th>
                  <td>Physical Appearance</td>
                  <td>2.8 + 0.2</td>
                </tr>
                <tr>
                  <th scope="row">9</th>
                  <td>Physical Appearance</td>
                  <td>&lt; 1.0 %</td>
                </tr>
                <tr>
                  <th scope="row">10</th>
                  <td>Physical Appearance</td>
                  <td>110 ml/100 gm</td>
                </tr>
                <tr>
                  <th scope="row">11</th>
                  <td>Physical Appearance</td>
                  <td>&lt; 1.0 %</td>
                </tr>
                <tr>
                  <th scope="row">12</th>
                  <td>Physical Appearance</td>
                  <td>5+</td>
                </tr>
                <tr>
                  <th scope="row">13</th>
                  <td>Physical Appearance</td>
                  <td>95.25 – 97.75</td>
                </tr>
                <tr>
                  <th scope="row">14</th>
                  <td>Physical Appearance</td>
                  <td>-1.50 – +1.50</td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
          <div>
          <div className="heading">
                
              
                <h2 className="h1" style={{textAlign:"center"}}>
                  <strong>Application</strong>{" "}
                </h2>
             
              </div>
              <div className="container">
            <p>
              -Masterbatch, -Paint &amp; Coating, -PVC Compounding, -Paper &amp; Powder
              Coating.
            </p>
            </div>
          </div>
          <div>
           
            <div>
            <div className="heading">
                
              
                <h2 className="h1" style={{textAlign:"center"}}>
                  <strong>Advantages</strong>{" "}
                </h2>
             
              </div>
              <div className="container">
              <p>
              -Replace TiO2 up to 20% - 35% without affecting opacity and gloss,
            -Absolute Whiteness and Coverage, -Improves Physio- Mechanical Properties.
            </p>
            </div>
            </div>
           
        
          </div>
        </section>
        
        

        
        );
    }
}


export  default  Stearates;