import React from "react";
import {Link} from 'react-router-dom'




class IndustriesApplication extends React.Component{
    render(){
        return(
          <>
   
   <>
  <div id="inner_banner_mobile" className="visible-xs-block">
    <img
      src="/assets/images/inner-banner/mobile/5.jpg"
      width={601}
      height={401}
      alt="Industries-Applications"
      style={{ width: "100%", height: "auto" }}
    />
  </div>
  <div id="inner_banner" className="hidden-xs">
    <img
      src="/assets/images/inner-banner/Industries-Applications.jpg"
      width={3038}
      height={505}
      alt="Industries-Applications"
      className="img-responsive"
    />
    <div className="abs_content">
      <div className="container">
        <div className="tagline_content">
          <h1>Industries &amp; Applications</h1>
          <p>
            "You name it and we have it". Our products Range caters almost all
            the Industries.
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="container m40" id="membership">
    <div className="row">
      <div className="col-md-12">
        <div className="heading">
          <div className="titleSeparator3" />
          <p className="no-margin">Wide Range of Products</p>
          <h2 className="h1">
            Industries <strong>&amp; Applications</strong>
          </h2>
        </div>
      </div>
    </div>
    <div className="row m40">
      <Link to="/packaging" className="col-sm-4 products">
        <img src="/assets/images/icons/adhesives.svg" />
        <div>
          <h2 className="h2">Packaging</h2>
        </div>
        <p>
          BOPP Tapes Adhesives, Flexible Lamination Adhesives, Book Binding,
          Personal Care, Home Décor Bags and Textiles.{" "}
        </p>
      </Link>
      <Link to="/construction" className="col-sm-4 products">
        <img src="/assets/images/icons/construction.svg" />
        <div>
          <h2 className="h2">Construction</h2>
        </div>
        <p>
          Construction Chemicals, Road Marking Chemicals, Heavy Civil Engg Aids.{" "}
        </p>
      </Link>
      <Link to="/inks" className="col-sm-4 products">
        <img src="/assets/images/icons/ink.svg" />
        <div>
          <h2 className="h2">Inks</h2>
        </div>
        <p>
          Flexography, Roto Grauver, Screen Printing, Off Set, Vinyl Digital
          Inks.
        </p>
      </Link>
      <div className="clearfix mb30" />
      <Link to="/paints-coating" className="col-sm-4 products">
        <img src="/assets/images/icons/paint-and-coating.svg" />
        <div>
          <h2 className="h2">Paint and Coating</h2>
        </div>
        <p>
          Automotive Coating, Coil Coating, Marine Wood Coating, High
          Performance Coating , Road Marking, Powder Coating, Varnishes.
        </p>
      </Link>
      <Link to="/paper" className="col-sm-4 products">
        <img src="/assets/images/icons/paper.svg" />
        <div>
          <h2 className="h2">Paper</h2>
        </div>
        <p>Processing Additives</p>
      </Link>
      <Link to="/plastic" className="col-sm-4 products">
        <img src="/assets/images/icons/plastic.svg" />
        <div>
          <h2 className="h2">Plastic</h2>
        </div>
        <p>Master Batches, Engg Compounds, Engg Plastics.</p>
      </Link>
      <div className="clearfix mb30" />
      <Link to="/rubber" className="col-sm-4 products">
        <img src="/assets/images/icons/rubber.svg" />
        <div>
          <h2 className="h2">Rubber</h2>
        </div>
        <p>Tire, Insulation Rubber, Cables and Wires.</p>
      </Link>
      <Link to="/textiles" className="col-sm-4 products">
        <img src="/assets/images/icons/textiles.svg" />
        <div>
          <h2 className="h2">Textiles</h2>
        </div>
        <p>
          Treatment Chemicals, Processing Additives, Printing Additives,
          Binders.
        </p>
      </Link>
      <Link to="/product-leather" className="col-sm-4 products">
        <img src="/assets/images/icons/leather.svg" />
        <div>
          <h2 className="h2">Leather</h2>
        </div>
        <p>Foot wear, Art Leather, Leather Finishes.</p>
      </Link>
      <div className="clearfix mb30" />
      <Link to="/detergent-soap" className="col-sm-4 products">
        <img src="/assets/images/icons/detergent-soap.svg" />
        <div>
          <h2 className="h2">Detergent / Soap</h2>
        </div>
        <p>
          Washing Detergent, Bath Soaps, Cleaning Detergent, Laundry Detergents.
        </p>
      </Link>
      <Link to="/pharma" className="col-sm-4 products">
        <img src="/assets/images/icons/pharma.svg" />
        <div>
          <h2 className="h2">Pharma</h2>
          <p>Pharma</p>
        </div>
        <p />
      </Link>
      <Link to="/food-beverages" className="col-sm-4 products">
        <img src="/assets/images/icons/food-and-beverages.svg" />
        <div>
          <h2 className="h2">Food &amp; Beverages</h2>
        </div>
        <p>
          Acetic Acid, Citric Acid, Stearic Acid, Formic Acid, Phosphoric Acid,
          Oxalic Acid.
        </p>
      </Link>
    </div>
  </div>
</>


        </>
        
        );
    }
}


export  default  IndustriesApplication;