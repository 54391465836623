import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from './pages/Home';
import Timeline from './pages/Timeline';
import Topnav from './components/topnav/Topnav';
import Footer from './components/footer/Footer';
import './App.css';
import IndustriesApplication from './pages/IndustriesApplication';
import Career from './pages/Career';
import ReachUS from './pages/ReachUs';
import ContactUS from './pages/ContactUs';
import MembershipAffiations from './pages/MembershipAffiations';
import WhyRevo from './pages/WhyRevo';
import OurProfile from './pages/OurProfile';
import News from './pages/News';
import FiberReinforced from './pages/FiberReinforced';
import Wires from './pages/Wires';
import Films from './pages/Films';
import FoamBoards from './pages/FoamBoards';
import Masterbatch from './pages/Masterbatch';
import Footwear from './pages/Footwear';
import Pipes from './pages/Pipes';
import PVCProfile from './pages/PVCProfile';
import Leather from './pages/Leather';
import Paints from './pages/Paints'
import Pigment from './pages/Pigment';
import MachineColorant from './pages/MachineColorant';
import Emulsion from './pages/Emulsion';
import Oxides from './pages/Oxides';
import FoodDyes from './pages/FoodDyes';
import FinePaste from './pages/FinePaste';
import Fillers from './pages/Fillers';
import Stearates from './pages/Stearates';
import CZStearates from './pages/CZStearates';
import Products from './pages/Products';
import ZincOxide from './pages/ZincOxide';
import Biocides from './pages/Biocides';

function App() {
  return (
 <div>
   <Topnav/>
    
    <Routes>
     
      <Route exact path='/' element={<Home />} />
      <Route exact path='/timeline' element={<Timeline />} />
      <Route exact path='/industries-and-applications' element={<IndustriesApplication />} />
      <Route exact path='/career' element={<Career />} />
      <Route exact path='/reach-us' element={<ReachUS />} />
      <Route exact path='/contact-us' element={<ContactUS />} />
      <Route exact path='/membership-and-affiliations' element={<MembershipAffiations />} />
      <Route exact path='/why-revo' element={<WhyRevo />} />
      <Route exact path='/machine-colorant-stainers' element={<MachineColorant />} />
      <Route exact path='/our-profile' element={<OurProfile />} />
      <Route exact path='/competencies' element={<News />} />
      <Route exact path='/paints-and-coatings' element={<Paints/>} />
      <Route exact path='/leather-cloth' element={<Leather />} />
      <Route exact path='/pvc-profiles' element={<PVCProfile />} />
      <Route exact path='/pipes-and-fittings' element={<Pipes />} />
      <Route exact path='/footwear' element={<Footwear />} />
      <Route exact path='/pigment' element={<Pigment />} />
      <Route exact path='/biocides' element={<Biocides />} />
      <Route exact path='/masterbatch' element={<Masterbatch />} />
      <Route exact path='/foamed-sheets' element={<FoamBoards />} />
      <Route exact path='/sheets-and-films' element={<Films />} />
      <Route exact path='/fiber-reinforced-plastic' element={<FiberReinforced />} />
      <Route exact path='/wires-and-cables' element={<Wires />} />
      <Route exact path='/emulsion' element={<Emulsion />} />
      <Route exact path='/oxides' element={<Oxides />} />
      <Route exact path='/food-dyes' element={<FoodDyes />} />
      <Route exact path='/fine-paste' element={<FinePaste />} />
      <Route exact path='/fillers' element={<Fillers />} />
      <Route exact path='/zinc-oxides' element={<ZincOxide />} />
      <Route exact path='/pvc-additives' element={<Stearates />} />
      <Route exact path='/stearates-stabilizers' element={<CZStearates />} />
      <Route exact path='/packaging' element={<Products />} />
      <Route exact path='/construction' element={<Products />} />
      <Route exact path='/inks' element={<Products />} />
      <Route exact path='/paints-coating' element={<Products />} />
      <Route exact path='/paper' element={<Products />} />
      <Route exact path='/plastic' element={<Products />} />
      <Route exact path='/rubber' element={<Products />} />
      <Route exact path='/textiles' element={<Products />} />
      <Route exact path='/product-leather' element={<Products />} />
      <Route exact path='/detergent-soap' element={<Products />} />
      <Route exact path='/pharma' element={<Products />} />
      <Route exact path='/food-beverages' element={<Products />} />
      </Routes>

<Footer/>
 </div>
  );
}

export default App;
