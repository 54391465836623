import React from "react";




class Fillers extends React.Component{
    render(){
        return(
       
          <>

          
          <div id="inner_banner_mobile" className="visible-xs-block">
        
        </div>
        
         <div>
      <h1>
          
          </h1>
          <div className="heading">
               
                
                <h2 className="h1" style={{textAlign:"center"}}>
             <strong>Common functional fillers</strong>{" "}
                </h2>
              </div>
          <div className="container">
          There are many different functional fillers and each one has a unique property set. This section gives an introduction to each filler, its properties and main application.
          </div>
          </div>
      
        <div className="container m40" id="membership">
    
     






       
  <div className="main">
  
   

    <div className="row Specialities-inner">
                    <div className="col-lg-4">
                      <div className="Specialities-content-parent">
                       
                       
                        <h3>Calcium Carbonate – CaCO3</h3>
                        
                        <p>
                        Calcium carbonate is a soft, inexpensive filler, that is readily available worldwide. High purity calcium carbonate is colourless but appears white in powder form due to light scattering. Different particle sizes are available as are surface treatments. It is the obvious choice, particularly in the less expensive polymers like PE, PP and PVC where it can improve stiffness, retain strength and improve impact resistance while lowering materials costs. 
                        </p>
                       
                      </div>
                    </div>
              
                    <div className="col-lg-4">
                      <div className="Specialities-content-parent">
                       
                      
                        <h3>Talc – Mg3Si4O10(OH)2</h3>
                       
                        <p>
                        Talc is a very soft mineral that is colorless when pure and appears white in powder form. The particles are generally platy so talc does reinforce when the aspect ratio is high enough. It is used extensively for applications needing good mechanicals with low cost and low weight. Automotive use is popular with the newer high aspect ratio (HAR) talc grades gaining traction. 
                        </p>
                        
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="Specialities-content-parent">
                       
                       
                        <h3>Kaolinite – Al2Si2O5(OH)4</h3>
                       
                        <p>
                        Kaolinite clay is a member of the Kaolin family of aluminosilicates. It is a soft white or tan powder where the particles are platy in shape. Similar to talc as a filler for plastic in that it can reinforce when the aspect ratio is high enough. However, high aspect ratio particles are not commercially available as they are too hard to make. Kaolinite is widely available, inert and inexpensive which are positives when searching for functional plastic fillers.
                        </p>
                       
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="Specialities-content-parent">
                       
                       
                        <h3>Silica (precipitated)</h3>
                     
                        <p>
                        Precipitated silica is an amorphous form of silica (silicon dioxide, SiO2); it is a white, powdery material. Precipitated silica is produced by precipitation from a solution containing silicate salts.

The three main classes of amorphous silica are pyrogenic silica,  precipitated silica and silica gel. Among them, precipitated silica has the greatest commercial significance. In 1999, more than one million tons were produced, half of it is used in tires and shoe soles.

Like pyrogenic silica, precipitated silica is essentially not microporous (unless prepared by the Stöber process). 
                        </p>
                     
                      </div>
                    </div>
                 
                    <div className="col-lg-4">
                      <div className="Specialities-content-parent">
                       
                       
                        <h3> Dolomite</h3>
                      
                        <p>
                        Dolomite mineral filler is a double calcium and magnesium carbonate often used in the manufacture of polymer matrix composites, paint industrie, agriculture and other productive sectors. Throughout this post, we introduce the advantages and characteristics of our dolomite mineral fillers and how they can affect the quality of the final product.
                        An alternative name sometimes used for the dolomitic rock type is dolostone.
                        Most dolomite was formed as a magnesium replacement of limestone or of lime mud before lithification.
                        </p>
                     
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="Specialities-content-parent">
                       
                       
                        <h3>China Clay</h3>
                       
                        <p>
                        Industrial manufacturing often uses a combination of minerals, raw materials, and chemicals to produce something. Thus, any industry will always need a constant supply of filler components. In a simpler term, filler components are additional materials added within a production process to improve specific properties. The use of filler will make the product cheaper while retaining its original purposes or properties.

There are a lot of filler components, explicitly used for specific industries. Calcium carbonate, magnesium carbonate, barium sulfate, and barite are just small examples compared to the exact number of all components used as filler.
                        </p>
                       
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="Specialities-content-parent">
                       
                       
                        <h3>GCC</h3>
                       
                        <p>
                        The ability to increase the filler content of paper without significantly sacrificing its mechanical strength is of high interest for papermakers. In this work, three samples of ground calcium carbonate (GCC), differing in size and in brightness, modified with silica via the sol-gel method, were used as fillers in papermaking.
                        </p>
                     
                      </div>
                    </div>
             
                  </div>




  </div>
  
<br />
<br />

<br />
  <div>
     
          <div className="heading">
               
                
               <h2 className="h1" style={{textAlign:"center"}}>
            {/* <strong>  Flame retardant functional fillers</strong>{" "} */}
               </h2>
             </div>
          </div>
          <div className="main">



  <div className="row Specialities-inner">
                
           
              
              
                  </div>






</div>


<div>
<div className="heading">
               
             </div>
         <p>This table lists some of the most common properties such as density, Mohs hardness, refractive index, typical aspect ratio and particle shape. More properties such as CTE, specific heat capacity and chemical resistance can be found on the pages for individual fillers.</p>
          </div>
         
<div>
  <img src="/assets/images/WhatsApp Image 2023-03-01 at 19.11.05.jpg" alt=" " />
</div>


<br />
<br />
<br />
<div>
<div className="heading">
               
                
               <h2 className="h1"  style={{textAlign:"center"}}>
            <strong> </strong>{" "}
               </h2>
             </div>
         
          </div>
          <div className="main">





  <div className="row Specialities-inner">
                    
                    <div className="col-lg-4">
                      {/* <div className="Specialities-content-parent">
                       
                       
                        <h3>Barium Sulfate (Barite or Blanc Fixe) – BaSO4</h3>
                        
                        <p>
                        White in powder form, it occurs naturally as the mineral barite or the corresponding synthetic version is referred to as Blanc Fixe. The two main attributes are high density (4.0-4.5 gcm-3) depending on purity and radio-opacity. It is therefore added to polymers to make them heavy, e.g. for sound damping or to make them x-ray visible for implants to give on example. It is highly unreactive (inert). The particles are blocky in shape and therefore non reinforcing.
                        </p>
                       
                      </div> */}
                    </div>
                    <div className="col-lg-4">
                      {/* <div className="Specialities-content-parent">
                       
                      
                        <h3>Magnetite Black Iron Oxide – Fe3O4</h3>
                    
                        <p>
                        A black lustrous powder with unusual properties. Often used for its high density (5.2 gcm-3) for pure grades. It also has high thermal conductivity and quite high electrical conductivity, although in plastics and coatings, no surface conductivity can be measured on the filled material. It is x-ray opaque and can be used to block radiation including microwaves and radar. Magnetite is non reinforcing due to the low aspect ratio of the particles. The many unusual properties are detailed on this Magnetite Page and in an encyclopedia chapter I authored.
                        </p>
                        
                      </div> */}
                    </div>
                    <div className="col-lg-4">
                      {/* <div className="Specialities-content-parent">
                       
                       
                        <h3>Hematite Red Iron Oxide – Fe2O3</h3>
                       
                        <p>
                        Hematite iron oxide has a high density (5.3 gcm-3). Coarser grades have a shiny grey metallic appearance but when ground below about 5 microns in size, the material appears red in colour which is why it is also known as rouge. Hematite is added to polymers to make them heavy, e.g. for sound damping or to make them x-ray visible for implants to give on example. It is highly unreactive (inert). The particles are blocky in shape and therefore non reinforcing. An exception is MIOX micaceous iron oxide which has a lamellar / platy particle morphology.
                        </p>
                       
                      </div> */}
                    </div>
                    <div className="col-lg-4">
                      {/* <div className="Specialities-content-parent">
                       
                       
                        <h3>Aragonite Renewable – CaCO3</h3>
                      
                        <p>
                        Oolitic aragonite contains 40% by weight of freshly sequestered carbon dioxide and is precipitated naturally in our oceans in vast amounts. Because it replenishes, it is regarded as a renewable mineral filler and because it renews more quickly than it is mined, it can be said to be sustainable as well. It has a slightly higher density  (~2.95 gcm-3) than the more usual calcite form. It is non reinforcing due to the low particle aspect ratio and as such, can be used as a drop-in replacement for calcite. More about Aragonite renewable filler can be found on this dedicated page.
                        </p>
                     
                      </div> */}
                    </div>
                    <div className="col-lg-4">
                      {/* <div className="Specialities-content-parent">
                       
                       
                        <h3>Halloysite – Al2Si2O5(OH)4</h3>
                       
                        <p>
                        Halloysite is an aluminosilicate and member of the Kaolin group of minerals. It has identical chemistry to kaolinite but instead of platy particles, the silicate sheets are rolled up in a scroll-like fashion to create nanotubes. These are typically 50nm in diameter and a few microns long to give an aspect ratio of 10-20:1 and thus moderate reinforcement. Although many potential applications have been postulated, commercialization in polymers has been very slow to take off. More information on halloysite can be found on a dedicated Halloysite Page.
                        </p>
                     
                      </div> */}
                    </div>
                    <div className="col-lg-4">
                      {/* <div className="Specialities-content-parent">
                       
                       
                        <h3>Polyhedral Oligomeric Silsesquioxanes .</h3>
                      
                        <p>
                        These are technically molecules that look like particles and have therefore been described as “molecules”. The centre of the molecule is a silica-like cage which is surrounded by organic groups which impart solubility. This means it is possible to add rigidity but with perfect compatibility and the option to chemically bond to the matrix polymer. Applications are starting to take off in the last couple of years. More information on these unique organic/inorganic hybrid functional filler additives can be found on the POSS Page.
                        </p>
                     
                      </div> */}
                    </div>

             
                  </div>
</div>


  </div>
      </>
      
        
        
        
        );
    }
}


export  default  Fillers;