import { ScrollMenu } from "react-horizontal-scrolling-menu";
import React from "react";



class Pigment extends React.Component{
    render(){
        return(
    
<div className="container-fluid py-5">
  <div className="container">
  <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp" >
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Pigment</h1>
                 
                </div>
                <div className="product-img">
                  <picture>
                    <source
                     
                      srcSet="/assets/images/384_main.jpg"
                    />
                    <img style={{width:"100%", height:"400px",}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluidss"
                    />
                  </picture>
                </div>
              </div>
              
       
</div>
             
            </div>
          </section>
    <br />
    <br />
    <br />
    <div className="row gx-0 mb-3 mb-lg-0">
     
      <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Phthalocyanine Pigments</strong>{" "}
                </h2>
              </div>
              <div className="hidden-mobile">
              <table border={1} className="table table-bordered mb-5"  style={{textAlign: "center" }}>
        <tbody style={{overflow:"hidden"}}>
          
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td
              rowSpan={2}
              className="text-center"
              style={{ width: "13%", verticalAlign: "middle" }}
            >
              Color
            </td>
            <td rowSpan={2} style={{ verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              C.I.No.
            </td>
            <td colSpan={4} className="text-center">
              Application
            </td>
          </tr>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td className="text-center">Plastic</td>
            <td className="text-center">Coatings</td>
            <td className="text-center">Inks</td>
            <td className="text-center">Detergent</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#626a6f", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Green RV 7</td>
            <td>P.G. 7</td>
            <td className="text-center">74260</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#0b5b40", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microsperse Green Sperse</td>
            <td>P.G. 7</td>
            <td className="text-center">74260</td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#33487d", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Blue RV 6010</td>
            <td>P.B. 15:0</td>
            <td className="text-center">74160</td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#1dade2", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Alpha Blue Sperse</td>
            <td>P.B. 15:0</td>
            <td className="text-center">74160</td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#488ecc", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Blue RV 6021</td>
            <td>P.B. 15:1</td>
            <td className="text-center">74160</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#63a8d1", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Blue RV 6040</td>
            <td>P.B. 15:3</td>
            <td className="text-center">74160</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#1dade2", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Blue RV 6041</td>
            <td>P.B. 15:4</td>
            <td className="text-center">74160</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
        </tbody>
              </table>
              </div>

              <ScrollMenu>
              <div className="table1">
             <table border={1} className="table table-bordered mb-5"  style={{textAlign: "center" }}>
        <tbody style={{overflow:"hidden"}}>
          
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td
              rowSpan={2}
              className="text-center"
              style={{ width: "13%", verticalAlign: "middle" }}
            >
              Color
            </td>
            <td rowSpan={2} style={{ verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              C.I.No.
            </td>
            <td colSpan={4} className="text-center">
              Application
            </td>
          </tr>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td className="text-center">Plastic</td>
            <td className="text-center">Coatings</td>
            <td className="text-center">Inks</td>
            <td className="text-center">Detergent</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#626a6f", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Green RV 7</td>
            <td>P.G. 7</td>
            <td className="text-center">74260</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#0b5b40", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microsperse Green Sperse</td>
            <td>P.G. 7</td>
            <td className="text-center">74260</td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#33487d", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Blue RV 6010</td>
            <td>P.B. 15:0</td>
            <td className="text-center">74160</td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#1dade2", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Alpha Blue Sperse</td>
            <td>P.B. 15:0</td>
            <td className="text-center">74160</td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#488ecc", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Blue RV 6021</td>
            <td>P.B. 15:1</td>
            <td className="text-center">74160</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#63a8d1", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Blue RV 6040</td>
            <td>P.B. 15:3</td>
            <td className="text-center">74160</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#1dade2", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Blue RV 6041</td>
            <td>P.B. 15:4</td>
            <td className="text-center">74160</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
        </tbody>
      </table>
      </div>
      </ScrollMenu>
             
      

      <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Inorganic Pigment</strong>{" "}
                </h2>
              </div>
              <div className="hidden-mobile">
              <table border={1} className="table table-bordered mb-5">
        <tbody>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td
              rowSpan={2}
              className="text-center"
              style={{ width: "13%", verticalAlign: "middle" }}
            >
              Color
            </td>
            <td rowSpan={2} style={{ verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              C.I.No.
            </td>
            <td colSpan={4} className="text-center">
              Application
            </td>
          </tr>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td className="text-center">Plastic</td>
            <td className="text-center">Coatings</td>
            <td className="text-center">Inks</td>
            <td className="text-center">Detergent</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow 2510</td>
            <td>P.Y. 34</td>
            <td className="text-center">77603</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fce33e", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow 3510</td>
            <td>P.Y. 34</td>
            <td className="text-center">77603</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f1649b", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Prime Rose</td>
            <td>P.Y. 34</td>
            <td className="text-center">77603</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#ed4344", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red 1510</td>
            <td>P.R. 104</td>
            <td className="text-center">77606</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#3849f7", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Ultramarine Blue</td>
            <td>P.B. 29</td>
            <td className="text-center">77007</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
        </tbody>
      </table>
      </div>
              <ScrollMenu>
              <div className="table1">
      <table border={1} className="table table-bordered mb-5">
        <tbody>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td
              rowSpan={2}
              className="text-center"
              style={{ width: "13%", verticalAlign: "middle" }}
            >
              Color
            </td>
            <td rowSpan={2} style={{ verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              C.I.No.
            </td>
            <td colSpan={4} className="text-center">
              Application
            </td>
          </tr>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td className="text-center">Plastic</td>
            <td className="text-center">Coatings</td>
            <td className="text-center">Inks</td>
            <td className="text-center">Detergent</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow 2510</td>
            <td>P.Y. 34</td>
            <td className="text-center">77603</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fce33e", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow 3510</td>
            <td>P.Y. 34</td>
            <td className="text-center">77603</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f1649b", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Prime Rose</td>
            <td>P.Y. 34</td>
            <td className="text-center">77603</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#ed4344", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red 1510</td>
            <td>P.R. 104</td>
            <td className="text-center">77606</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#3849f7", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Ultramarine Blue</td>
            <td>P.B. 29</td>
            <td className="text-center">77007</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
        </tbody>
      </table>
</div>
      </ScrollMenu>
      <p>This is for representation, original might differ.</p>


      <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Organic Pigment</strong>{" "}
                </h2>
              </div>
              <div className="hidden-mobile">
              <table border={1} className="table table-bordered mb-5">
        <tbody>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td
              rowSpan={2}
              className="text-center"
              style={{ width: "13%", verticalAlign: "middle" }}
            >
              Color
            </td>
            <td rowSpan={2} style={{ verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              C.I.No.
            </td>
            <td colSpan={4} className="text-center">
              Application
            </td>
          </tr>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td className="text-center">Plastic</td>
            <td className="text-center">Coatings</td>
            <td className="text-center">Inks</td>
            <td className="text-center">Detergent</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow RV 12</td>
            <td>P.Y. 12</td>
            <td>21090</td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fde521", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow RV 13</td>
            <td>P.Y. 13</td>
            <td>21100</td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow RV 14</td>
            <td>P.Y. 14</td>
            <td>21095</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#feeb8c", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow RV 74</td>
            <td>P.Y. 74</td>
            <td>11741</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f8a07a", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Orange 13</td>
            <td>P.O. 13</td>
            <td>21110</td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#ef6733", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Orange 34</td>
            <td>P.O. 34</td>
            <td>21115</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f27b97", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 8</td>
            <td>P.R. 8</td>
            <td>12335</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#e86e79", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 48:2</td>
            <td>P.R. 48:2</td>
            <td>15865:2</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#ee3135", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 53:1</td>
            <td>P.R. 53:1</td>
            <td>15585:1</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f27b97", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 57:1</td>
            <td>P.R. 57:1</td>
            <td>15850:1</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f486b3", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 112</td>
            <td>P.R. 112</td>
            <td>12370</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
        </tbody>
      </table>
      </div>
              <ScrollMenu>
              <div className="table1">
      <table border={1} className="table table-bordered mb-5">
        <tbody>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td
              rowSpan={2}
              className="text-center"
              style={{ width: "13%", verticalAlign: "middle" }}
            >
              Color
            </td>
            <td rowSpan={2} style={{ verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              Pigment
            </td>
            <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
              C.I.No.
            </td>
            <td colSpan={4} className="text-center">
              Application
            </td>
          </tr>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td className="text-center">Plastic</td>
            <td className="text-center">Coatings</td>
            <td className="text-center">Inks</td>
            <td className="text-center">Detergent</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow RV 12</td>
            <td>P.Y. 12</td>
            <td>21090</td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fde521", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow RV 13</td>
            <td>P.Y. 13</td>
            <td>21100</td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow RV 14</td>
            <td>P.Y. 14</td>
            <td>21095</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#feeb8c", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Yellow RV 74</td>
            <td>P.Y. 74</td>
            <td>11741</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f8a07a", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Orange 13</td>
            <td>P.O. 13</td>
            <td>21110</td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#ef6733", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Orange 34</td>
            <td>P.O. 34</td>
            <td>21115</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f27b97", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 8</td>
            <td>P.R. 8</td>
            <td>12335</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#e86e79", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 48:2</td>
            <td>P.R. 48:2</td>
            <td>15865:2</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="far fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#ee3135", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 53:1</td>
            <td>P.R. 53:1</td>
            <td>15585:1</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f27b97", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 57:1</td>
            <td>P.R. 57:1</td>
            <td>15850:1</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>
              <div
                className="color_div"
                style={{ backgroundColor: "#f486b3", textAlign: "center" }}
              >
                &nbsp;
              </div>
            </td>
            <td>Microfast Red RV 112</td>
            <td>P.R. 112</td>
            <td>12370</td>
            <td className="text-center">--</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
        </tbody>
      </table>
      </div>
      </ScrollMenu>
      <p>This is for representation, original might differ.</p>
    </div>
  </div>
</div>

        
        );
    }
}


export  default Pigment;