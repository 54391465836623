import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import {FaBars} from 'react-icons/fa'
import {RxCross1} from 'react-icons/rx'
import {BsFillCaretDownFill} from 'react-icons/bs'

const Topnav = () => {



const [menu, setMenu ] = useState(false)

  

  return (
    <nav>
    <div className="navbar">
      <i className='bx bx-menu'>
      <div onClick={() => setMenu(!menu)}>
     {
      menu ?  <RxCross1 className='bar'/> : <FaBars className='bar'/> 
     }
     </div>
      </i>
      <div className="logo"><Link  to="/"><img style={{width:"200px"}}  src="assets/images/logosss.jpg" alt="" /></Link></div>
      <div className={menu ? "nav-links ac" : "nav-links "}>
        
        <ul className="links">
          <li><Link to=" " onClick={()=> setMenu(prev=>!prev)}>Home</Link></li>
          <li>
            <Link to=""  style={{display:"flex", alignItems:"center", gap:"1rem"}}> Profile  <BsFillCaretDownFill /></Link>
            
            <ul className="htmlCss-sub-menu sub-menu">
          
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/our-profile">Introductions</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/timeline">The Timeline</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/why-revo">Why Rivomax ?</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/competencies">Competencies</Link></li>
          
            </ul>
          </li>
          <li>
            <Link to=""   style={{display:"flex", alignItems:"center", gap:"1rem"}}> Products   <BsFillCaretDownFill /></Link>
        
            <ul className="js-sub-menu sub-menu">
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/pigment">Pigment</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/machine-colorant-stainers">Colorants & Stainers</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/biocides">Biocides</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/fine-paste">Fine paste</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/fillers">Fillers</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/emulsion">Emulsions</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/oxides">Oxides</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/pvc-additives">Additives</Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/stearates-stabilizers">Stearates & Stabilizers </Link></li>
              <li><Link onClick={()=> setMenu(prev=>!prev)} to="/zinc-oxides">Zinc Oxides</Link></li>
            </ul>
          </li>
          <li><Link onClick={()=> setMenu(prev=>!prev)} to="/industries-and-applications">Industries & Applications</Link></li>
          <li><Link  onClick={()=> setMenu(prev=>!prev)} to="/career">Careers</Link></li>
          <li><Link onClick={()=> setMenu(prev=>!prev)}  to="/reach-us">Reach</Link></li>
          <li><Link onClick={()=> setMenu(prev=>!prev)} to="/contact-us">Contact Us</Link></li>
        </ul>
      </div>
   
    
  
    </div>
  </nav>
  )
}

export default Topnav
