import React from "react";
import './Wire.css';

import { ScrollMenu } from "react-horizontal-scrolling-menu";

class Products extends React.Component{
    render(){
        return(
          <div className="container m40" id="membership">
          <div className="row">
            <div className="col-md-12">
              <div className="heading">
                <div className="titleSeparator3" />
             
                <h2 className="h1">
                Industries & <strong>Application</strong>
                </h2>
              </div>
            </div>
          </div>
          <ScrollMenu>
          <table className="table table-bordered table-hover table_tick text-center">
            <tbody>
              <tr>
                <th width={151}>
                  <div className="text-center">Products</div>
                  &nbsp;
                </th>
                <th width={1044} colSpan={15}>
                  <div className="text-center">Applications</div>
                </th>
              </tr>
              <tr>
                <td width={222}>&nbsp;</td>
                <td width={78}>Rubber</td>
                <td width={78}>Inks</td>
                <td width={78}>Coatings</td>
                <td width={78}>Plastics</td>
                <td width={118}>Compounding</td>
                <td width={78}>Textiles</td>
                <td width={150}>Construction Chemicals</td>
                <td width={78}>Leather</td>
                <td width={78}>Packaging</td>
                <td width={102}>Soaps and Detergent</td>
                <td width={78}>Pharma</td>
                <td width={78}>Paper</td>
                <td width={78}>Plywood</td>
                <td width={95}>Personal Care</td>
                <td width={78}>Cables</td>
              </tr>
              <tr>
                <td>Adhesives</td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Basic Dyes</td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Carbon Black</td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Chromes</td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Driers</td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Dyes</td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Fillers</td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Calcium Aluminate Cement</td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Dry Mix Admixture</td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Kaolin Clay</td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Oxides</td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Pigments</td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Speciality Polymers</td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
              <tr>
                <td>Sterates</td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Tio2</td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
              </tr>
              <tr>
                <td>Waxes</td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <i className="fa fa-check" />
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
                <td>
                  <span>-</span>
                </td>
              </tr>
            </tbody>
          </table>
          </ScrollMenu>
        </div>
        
        
        );
    }
}


export  default  Products;