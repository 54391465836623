import React from "react";
import {Link} from 'react-router-dom'




class FiberReinforced extends React.Component{
    render(){
        return(
       
          <main className="application-page">
         


          <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Fiber Reinforced Plastics</h1>
                  <br />
                  <br />
                 
                </div>
                <div className="product-img">
                  <picture>
                    <source
                   
                      srcSet="https://www.goldstab.com/images/applications/frps.png"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0 mt-9">
                      About Application:
                    </h4>
                    <br />
                  
                    <div className="product-description">
                      <p>
                        Fiber-Reinforced Polymer (FRP) composites are made from a resin
                        matrix that is reinforced with an engineered, man-made or
                        natural fibre or other reinforcing material. The fibre provides
                        strengths and stiffness while a more flexible matrix (resin)
                        provides shape and protection.
                      </p>
                      <p>
                        Goldstab provides effective accelerators for FRP curing. It
                        affects the curing mechanism of unsaturated polyester resins in
                        combination with catalysts like Potassium Octoate and MEKO
                        (Methyl Ethyl Ketoxime).
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>
          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
              
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application Name
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key features
                      </th>
                   
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td scope="row" rowSpan={3}>
                        Clear Cast
                      </td>
                      <td>Goldstab ACC 1</td>
                      <td>For higher strength and mechanical properties</td>
                    </tr>
                    <tr>
                      <td>Goldstab ACC 6</td>
                      <td>Effective accelerator</td>
                    </tr>
                    <tr>
                      <td>Goldstab ACC 10</td>
                      <td>Especially designed for large articles</td>
                    </tr>
                    <tr>
                      <td scope="row" rowSpan={2} className="align-middle">
                        Dark Colored Cast
                      </td>
                      <td>Goldstab ACLR 3</td>
                      <td rowSpan={2}>Effective low concentration accelerator</td>
                    </tr>
                    <tr>
                      <td>Goldstab ACLR 6</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
            </div>
          </section>
      
        </main>
        
        
        );
    }
}


export  default  FiberReinforced;