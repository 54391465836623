import React from "react";




class Films extends React.Component{
    render(){
        return(
       
          <main className="application-page">
     
 <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Films and Sheets</h1>
                  <br />
                  <br />
                 
                </div>
                <div className="product-img">
                  <picture>
                    <source
                    
                      srcSet="/assets/images/product.jpg"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0 mt-9">
                      About Application:
                    </h4>
                    <br />
                  
                    <div className="product-description">
                      <p>
                        Plasticizers help in making PVC flexible and allowing it to be
                        used for various applications and also replace rubber at some
                        places. Other than flexibility in operation and installation,
                        transparency, durability and chemical resistance properties of
                        PVC make it a suitable material for wall covering, films, flex,
                        transparent healthcare articles, etc.
                      </p>
                      <p>
                        Goldstab offers a range of solid and mixed metal liquid
                        Stabilizers to choose from depending on the type of application,
                        transparency requirements, the technology of processing and
                        specific requirements of the customer such as the use of
                        non-toxic ingredients. The formulations are made to make sure
                        that final articles get excellent transparency.
                      </p>
                      <p>
                        In addition to traditional stabilizers, Goldstab also has a
                        range of modern Calcium-Zinc based solid stabilizer system and
                        Barium-Zinc based liquid stabilizer system to fulfil
                        stabilization requirements in food packaging, toys, and
                        tablecloth applications.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>




          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
           
           
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Calcium Based Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={2}>
                        Healthcare articles and pharmaceutical packaging
                      </td>
                      <td>Goldstab CZ 5142</td>
                      <td rowSpan={2}>
                        Outstanding transparency, chemical resistance and anti-bacterial
                        properties
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 54102</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Mixed Metal Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={8}>Flexible Film</td>
                      <td>Goldstab 810 XL</td>
                      <td>Premium Stabilizer</td>
                    </tr>
                    <tr>
                      <td>Goldstab 812</td>
                      <td>
                        Premium stabilizer with high static and dynamic heat stability
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 830</td>
                      <td>Non-toxic, economical stabilizer</td>
                    </tr>
                    <tr>
                      <td>Goldstab 831</td>
                      <td>Non-toxic stabilizer</td>
                    </tr>
                    <tr>
                      <td>Goldstab 835</td>
                      <td>Non-toxic stabilizer with moderate dynamic heat stability</td>
                    </tr>
                    <tr>
                      <td>Goldstab 838</td>
                      <td>
                        Non toxic stabilizer with excellent static &amp; dynamic heat
                        stability and transparency for all round performance
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 862</td>
                      <td>Economical stabilizer for transparency</td>
                    </tr>
                    <tr>
                      <td>Goldstab 891</td>
                      <td>Stabilizer with moderate strength and high transparency</td>
                    </tr>
                    <tr>
                      <td>Flex banner</td>
                      <td>Goldstab 814</td>
                      <td>
                        Premium stabilizer with excellent color hold and high heat
                        stability performance
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
           
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Organotin Based Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={3}>Transparent and Rigid PVC Sheets</td>
                      <td>Goldstab 901</td>
                      <td>
                        Premium product for best transparency and excellent heat
                        stability
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 955</td>
                      <td rowSpan={2}>Good heat stability and transparency</td>
                    </tr>
                    <tr>
                      <td>Goldstab 975</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
            </div>
          </section>
        
        </main>
        
        
        );
    }
}


export  default  Films;