import React from "react";





class ReachUS extends React.Component{
    render(){


  return(
    
   
   <>
  <div id="inner_banner_mobile" className="visible-xs-block">
    <img
      src="/assets/images/asasasa.jpg"
     
      alt="Reach us"
      style={{ width: "100%", height: "200px" }}
    />
  </div>
  <div id="inner_banner" className="hidden-xs">
    <img
      src="/assets/images/asasasa.jpg"
      width={3038}
      height={505}
      alt="Rivomax"
      className="img-responsive"
    />
    <div className="abs_content">
      <div className="container">
        <div className="tagline_content">
          {/* <h1 style={{color: "red",fontSize:"40px",fontFamily:"serif"}}>Reach us</h1>
          <br />
          <p style={{color: "red",fontSize:"20px",fontFamily:"serif"}}>
            Our Presence is almost everywhere in world, having our reach in 5
            continents.
          </p> */}
        </div>
      </div>
    </div>
  </div>
  <div className="container m40" id="membership">
    <div className="row">
      <div className="col-md-12">
        <div className="heading">
          <div className="titleSeparator3" />
          <p className="no-margin">Reach</p>
          <h2 className="h1">
            Reach <strong> us</strong>{" "}
          </h2>
        </div>
      </div>
    </div>
    
    <div className="mapss" id="mapss"> <iframe
  src="https://www.google.com/maps/d/u/0/embed?mid=1UoywhNnwbRnSxrPmw3FoFqmCyTveFYM&ehbc&ll=20.491942509190523%2C21.38107467032482&z=3"


  style={{width:"100%", height:"586px"}}

 
  
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/> </div>
   </div>
    <div className="mark-desc">
      <img src="/assets/images/marker/green-marker.png" />
      <strong>Import</strong>
      <img src="/assets/images/marker/red-marker.png" /> <strong>Export</strong>
    </div>
 


  






</>



    
        
        );
    }
}


export  default ReachUS;