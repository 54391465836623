import React from "react";

class Masterbatch extends React.Component{
    render(){
        return(
       
          <main className="application-page">
      


          <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Masterbatch</h1>
                  <br />
                </div>
                <div className="product-img">
                  <picture>
                    <source
                     
                      srcSet="/assets/images/master.jpg"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="/assets/images/master.jpg"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0">
                      About Application:
                    </h4>
                    <div className="product-description">
                      <p>
                      Masterbatches are made using higher concentrations of pigments,
                        fillers or other functional additives in carrier polymer resin.
                        The highly concentrated mixture is then mixed in larger quantity
                        of resin. The advantage of making masterbatch is the ease of
                        dispersion and processing. However making masterbatch is quite
                        challenging task. Masterbatch manufacturers face challenges in
                        achieving uniform and homogeneous distribution of fillers and
                        pigments. Since these additives do not melt in extrusion
                        process, hence homogenizing and processing becomes difficult. It
                        also increases the load on extruder motor and hence energy
                        consumption of the machine increases. This affects the cost of
                        processing with double effect as along with increasing energy
                        cost it also decreases output as retention time in the machine
                        increases. Moreover blockages and clogging makes it compulsory
                        for processors to stop the machine for cleaning and maintenance
                        and also hampers the consistency. The reproducibility of color
                        without visible specks on the compounded material determines the
                        quality of the masterbatch.</p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>
          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
       
            
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>  Metallic Stearates</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={2}>Masterbatches</td>
                      <td>Calcium Stearate</td>
                      <td>
                        Pure Calcium salt of high quality stearic acid with no
                        non-melting particles.
                      </td>
                    </tr>
                    <tr>
                      <td>Zinc Stearate</td>
                      <td>
                        Zinc salt of stearic acid that improves dispersion and output.
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
             
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>   One Pack Lubricants</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={4}>
                        Rigid PVC articles and Polyolefin masterbatches
                      </td>
                      <td>Goldstab LUB 102 PE</td>
                      <td>Polyethylene wax</td>
                    </tr>
                    <tr>
                      <td>Goldstab LUB 110 PE</td>
                      <td>Polyethylene wax for better heat stability</td>
                    </tr>
                    <tr>
                      <td>Goldstab LUB 116 PE</td>
                      <td>
                        Polyethylene wax in powder and prill form for better heat
                        stability
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab LUB OPE</td>
                      <td>Oxidized PE wax</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
            </div>
          </section>
        
        </main>
        
        
        );
    }
}


export  default  Masterbatch;