import React from "react";
import {Link} from 'react-router-dom'




class Timeline extends React.Component{
    render(){
        return(
          <>
   
     
        
   <div className="container m40 rel">
  <div className="row">
    <div className="col-md-12">
      <div className="heading">
        <div className="titleSeparator3" />
        <p className="no-margin">Rivomax Dyes &amp; Chemicals Inc history</p>
        <h2 className="h1">
          The <strong>Timeline</strong>{" "}
        </h2>
      </div>
    </div>
  </div>
  <div className="our_company_border" />
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2015</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        A new arm of RIPL group was incorporated to cater needs of local market.
        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2016</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        Signed with one of the largest manfacturer of Specialty Carbon Black  to cater to the needs of various industries for  PAN India.
        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2017</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        Started distribution of Pigment, Fine Paste and Colorants.

        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2018</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        With addition in the Sales force had got a boost and company started with supply of Ultramarine Blue.
        We were authorised to sell stearates and stabilizers for Entire PAN India.

        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2019</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        Started distribution of fillers and additives for paints and coating.
        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2021</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        RIPL Signed with Loha industries.

        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2022</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        Appointed as distributor of biocides and other pigments.
        </p>
      </div>
    </div>
  </div>
  {/* <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2015</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        Achieved zero discharge for whole dispersion & colorant units.
        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2017</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        Established new unit in Vasai for mfg PVC stearates and stabilizers.
        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2018</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        RIPL took the distributionship for Indofil Industries Ltd. For their Coatings and Construction Chemical products - Entire India
        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2019</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        RIPL  has taken the distributionship of Ms. Imerys Aluminates (Formerly Ms. Kerneos Aluminates Technologies) for their Construction Chemcial products - Entire India.
        </p>
      </div>
    </div>
  </div>
  <div className="row p-t-75">
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_year">
        <h2 className="color_red">2020</h2>
      </div>
    </div>
    <div className="col-md-1 col-sm-1 col-xs-12">
      <div className="company_icon">
        <i className="fa fa-calendar-check-o" />
      </div>
    </div>
    <div className="col-md-10 col-sm-10 col-xs-12">
      <div className="company_text">
        <p>
        RIPL has been appointed as the Channel Partner for Tronox Chemicals company to sell all grades of Tio2.
        </p>
      </div>
    </div>
  </div> */}

</div>

        </>
        
        );
    }
}


export  default  Timeline;