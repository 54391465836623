import React from "react";




class ZincOxide extends React.Component{
    render(){
        return(
       <>
        <br />
        <br />
        
        <div className="newsss" style={{textAlign:"center"}}>
      <img src="assets/images/Screenshot (63).png" />
      </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
       </>
       
        
        
        );
    }
}


export  default  ZincOxide;