import React from "react";
import {Link} from 'react-router-dom'




class FoamBoards extends React.Component{
    render(){
        return(
       
          <main className="application-page">
    

          <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Foam Boards &amp; WPC</h1>
                  <br />
                  <br />
                 
                </div>
                <div className="product-img">
                  <picture>
                    <source
                   
                      srcSet="/assets/images/structural-fabricator-aluminium-500x500.webp"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0 mt-9">
                      About Application:
                    </h4>
                    <br />
                  
                    <div className="product-description">
                      <p>
                        PVC foamed sheets have excellent thermal insulation properties
                        that meet the most demanding economic and ecological
                        requirements in the construction and architecture industry. It
                        also finds applications in apparatus engineering, worktops,
                        siding and exhibition stands, doors, interior trimmings of
                        railway carriages and airplanes, etc.
                      </p>
                      <p>
                        One pack stabilizers play an important role in producing
                        homogenous microcellular PVC foams as their density has an
                        Arrhenius-type dependence on temperature and also the
                        ingredients. A homogeneous structural density gives higher
                        mechanical strength to the material and lowers the overall
                        processing cost.
                      </p>
                      <p>
                        Goldstab One Pack Stabilizers provide consistent performance at
                        the processing end which ensures great strength, durability,
                        weathering and chemical resistance at optimum processing cost.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>
          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
          

              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Calcium Based Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td>PVC Rigid Sheets</td>
                      <td>Goldstab CZ 5213</td>
                      <td rowSpan={2}>
                        Perfect balance of heat stability, blowing tendency and
                        lubrication, agents that provide consistency in foam density,
                        sheet thickness and cell structure
                      </td>
                    </tr>
                    <tr>
                      <td>PVC Foam Board and WPC sheets</td>
                      <td>Goldstab CZ 56003 FB</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="table-note gray-text">
                Note: Various other grades available to suit specific needs of customers
              </p>
         
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Lead Based One Pack Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td className="align-middle" rowSpan={5}>
                        PVC Foam Board and WPC sheets
                      </td>
                      <td>Goldstab 3014</td>
                      <td>High Thermal stability and good color hold</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3016 N</td>
                      <td rowSpan={2}>
                        Provides higher output, reducing the processing cost &amp;
                        excellent mechanical and aesthetic properties
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 3051 KA</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3183</td>
                      <td>
                        Lead based stabilizers with balance of internal and external
                        lubricants that controls melt viscosity of the melt and gives
                        uniform cell structure and high heat stability
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 3550 N</td>
                      <td>
                        Well – adjusted lubricating &amp; stabilizer system that
                        provides fast gelation &amp; higher mechanical properties
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div class="d-flex flex-column align-items-end justify-content-end">
                  <a
                      href="https://drive.google.com/u/0/uc?id=1lqmW3LVDotwpezAv4FJGPVtsbE3Dm41u&export=download"
                      download="Brochure"
                      class=" download-brochure-link"
                      >Download Application Brochure
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 24 24"
                          class="download-brochure-icon"
                          viewBox="0 0 24 24"
                          fill="#1a1c80"
                      >
                          <g><rect fill="none" height="24" width="24" /></g>
                          <g>
                              <path
                                  d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"
                              />
                          </g></svg
                  ></a>
                  <a
                      href="https://drive.google.com/u/0/uc?id=1W_6sP9QScL5wSV3qiKeeqRq6oHxdJiq3&export=download"
                      download="Brochure"
                      class=" download-brochure-link"
                      >Download Application Brochure
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 24 24"
                          class="download-brochure-icon"
                          viewBox="0 0 24 24"
                          fill="#1a1c80"
                      >
                          <g><rect fill="none" height="24" width="24" /></g>
                          <g>
                              <path
                                  d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"
                              />
                          </g></svg
                  ></a>
              </div> */}
            </div>
          </section>
      
        
        </main>
        
        
        );
    }
}


export  default  FoamBoards;