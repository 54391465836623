import React from "react";
import {Link} from 'react-router-dom'




class Leather extends React.Component{
    render(){
        return(
       
          <main className="application-page">
        


          <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Leather cloth</h1>
                  <br />
                  <br />
                 
                </div>
                <div className="product-img">
                  <picture>
                    <source
                      
                      srcSet="https://www.goldstab.com/images/applications/leather_cloth.png"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0 mt-9">
                      About Application:
                    </h4>
                    <br />
                  
                    <div className="product-description">
                      <p>
                        Artificial leather made from PVC has proven to be best
                        replacement to leather. It is much more durable and has more
                        resistance to abrasion and wear and tare. Due to many advantages
                        like flexibility in operation, non toxic, weather resistance,
                        PVC is very widely used for high filled floorings, wall
                        covering, leather cloth, films and flexible sheets.
                      </p>
                      <p>
                        Goldstab offer various liquid mixed metal stabilizers and
                        activators with excellent heat stability and good lubrication
                        that provides ease of processing and dispersion. These grades
                        are available in both cadmium based and cadmium free forms with
                        no drop performance over the years. These high quality
                        stabilizers and kickers provide great foaming efficiency and
                        uniform thickness to the leather cloth. It also provides great
                        finish to the substrate.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>


          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
            <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Calcium Based Stabilizer</strong>{" "}
                </h2>
              </div>
              
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={2}>Compact Layer</td>
                      <td>Goldstab CZ 5095</td>
                      <td>Odorless and solvent free heat stabilizer</td>
                    </tr>
                    <tr>
                      <td>Goldstab 6130</td>
                      <td>Good heat stability and color hold</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Liquid Mixed Metal Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={4}>Compact Layer</td>
                      <td>Goldstab 812</td>
                      <td>
                        Premium stabilizer for excellent static and dynamic heat
                        stability
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 831</td>
                      <td>Provides excellent color hold</td>
                    </tr>
                    <tr>
                      <td>Goldstab 837</td>
                      <td>Heavy metal free and non toxic stabilizer</td>
                    </tr>
                    <tr>
                      <td>Goldstab 890</td>
                      <td>
                        Economical stabilizer for good heat stability and color hold
                      </td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>Foamed Layer</td>
                      <td>Goldstab 829</td>
                      <td rowSpan={2}>
                        Provides slow to medium kicking action depending on the machine
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 839</td>
                    </tr>
                    <tr>
                      <td>Goldstab 873</td>
                      <td>Fast kicking action</td>
                    </tr>
                  </tbody>
                </table>
              </div>
       
            </div>
          </section>
       
       
        </main>
        
        
        );
    }
}


export  default  Leather;