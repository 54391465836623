import React from "react";
import {Link} from 'react-router-dom'




class Oxides extends React.Component{
    render(){
        return(
       
          <>
          <section id="feature" className="transparent-bg">
            <div className="container">
              <div className="center wow fadeInDown">
                <div className="ten columns centered text-center">
                 
                  <div className="heading">
              
                
                <h2 className="h1">
             <strong>Our Amazing Range of Shades</strong>{" "}
                </h2>
              </div>
                  <p>
                    Our Product meets all prescribed specification in accordance of IS
                    44 ,BIS standard for Iron Oxide Pigments .
                  </p>
                </div>
              </div>
            </div>
            {/* Page Title End*/}
            {/* Content
           ================================================== */}
            <div className="container">
              <div className="col-md-12">
                <div className="ten columns centered text-center">
                <div className="heading">
              
                
              <h2 className="h1">
           <strong>Shade Card</strong>{" "}
              </h2>
            </div>
                  <table
                    width="100%"
                    border={1}
                    cellPadding={20}
                    cellSpacing={5}
                    bgcolor="#ccc"
                  >
                    <tbody>
                      <tr>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ccc",
                            border: "3px solid #ccc"
                          }}
                        >
                          <strong>Standard shades of Red</strong>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ccc",
                            border: "3px solid #ccc"
                          }}
                        >
                          <strong>Mass Tone </strong>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ccc",
                            border: "3px solid #ccc"
                          }}
                        >
                          <strong>Tint Tone </strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                      </tr>
                      <tr height={50}>
                        <td
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        >
                          110
                          <br /> <small>(Microx  Iron Oxide Powder, Red) </small>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#851f1c",
                            border: "3px solid #fff"
                          }}
                        ></td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#c16f6f",
                            border: "3px solid #fff"
                          }}
                        ></td>
                      </tr>
                      <tr height={50}>
                        <td
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        >
                          120
                          <br />
                          <small>(Microx  Iron Oxide Powder, Red) </small>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#851f1c",
                            border: "3px solid #fff"
                          }}
                        ></td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#c27f7f",
                            border: "3px solid #fff"
                          }}
                        ></td>
                      </tr>
           
               
                      <tr height={50}>
                        <td
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        >
                          130
                          <br />
                          <small>(Microx  Iron Oxide Powder, Red) </small>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#911a14",
                            border: "3px solid #fff"
                          }}
                        ></td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#DB7977",
                            border: "3px solid #fff"
                          }}
                        ></td>
                      </tr>
                      <tr height={50}>
                        <td
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        >
                          180
                          <br />
                          <small>(Microx  Iron Oxide Powder, Red) </small>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#66160B",
                            border: "3px solid #fff"
                          }}
                        ></td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#b08593",
                            border: "3px solid #fff"
                          }}
                        ></td>
                      </tr>
                
               
           
               
                      <tr>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                      </tr>
                      <tr>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ccc",
                            border: "3px solid #ccc"
                          }}
                        >
                          <strong>Standard shades of Yellow</strong>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ccc",
                            border: "3px solid #ccc"
                          }}
                        >
                          <strong>Mass Tone </strong>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ccc",
                            border: "3px solid #ccc"
                          }}
                        >
                          <strong>Tint Tone </strong>
                        </td>
                      </tr>
                      <tr>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                      </tr>
                      <tr height={50}>
                        <td
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        >
                          313
                          <br />
                          <small>( Microx Iron Oxide Powder, Yellow)</small>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#bf8f0e",
                            border: "3px solid #fff"
                          }}
                        ></td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#f7db91",
                            border: "3px solid #fff"
                          }}
                        ></td>
                      </tr>
                      <tr height={50}>
                        <td
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        >
                          920
                          <br />
                          <small>(Microx  Iron Oxide Powder, Yellow) </small>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#bf8f0e",
                            border: "3px solid #fff"
                          }}
                        ></td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#f7db91",
                            border: "3px solid #fff"
                          }}
                        ></td>
                      </tr>
             
                      <tr>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        />
                      </tr>

                      <tr>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ccc",
                            border: "3px solid #ccc"
                          }}
                        >
                          <strong>Standard shades of Black</strong>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ccc",
                            border: "3px solid #ccc"
                          }}
                        >
                          <strong>Mass Tone </strong>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#ccc",
                            border: "3px solid #ccc"
                          }}
                        >
                          <strong>Tint Tone </strong>
                        </td>
                      </tr>
                      <tr height={50}>
                        <td
                          style={{
                            textAlign: "center",
                            backgroundColor: "#fff",
                            border: "3px solid #fff"
                          }}
                        >
                      330
                          <br />
                          <small>(Microx  Iron Oxide Powder, Black) </small>
                        </td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#000000",
                            border: "3px solid #fff"
                          }}
                        ></td>
                        <td
                          width={140}
                          style={{
                            textAlign: "center",
                            backgroundColor: "#808080",
                            border: "3px solid #fff"
                          }}
                        ></td>
                      </tr>
             
                    </tbody>
                  </table>
                </div>
                <div className="center wow fadeInDown">
                  <div className="ten columns centered text-center">
                    <h2 />
                    <p>
                      This information and our technical advice - wether verbal, in
                      writing or by means of trials - are given in good faith but
                      without any warranty and this also applies where proprietary
                      rights of third parties are involved. Our advice does not release
                      you from the obligation to check its validity and to test our
                      products as to their suitability for the intended process and
                      uses. The application, use and processing of our products and the
                      products manufactured by you on the basis of our technical advice
                      are beyond our control and, therefore, entirely your own
                      responsibility. Our products are sold in accordance with our
                      General Conditions of Sale and Delivery.
                    </p>
                  </div>
                </div>
              </div>
            
            </div>
            {/* content End*/}
            <br />
            <br />

            <br />
            <br />
          </section>
             </>
        
        
        
        );
    }
}


export  default  Oxides;