import React from "react";
import {Link} from 'react-router-dom'




class Emulsion extends React.Component{
    render(){
        return(
       
          <>
          <section className="main-banner ptb-40">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-sm-6 col-xs-12">
                 
                </div>
                <div className="col-md-6 col-sm-6 col-xs-12">
                <br />
                <br />
                <br />
                </div>
              </div>
            </div>
            {/* End Container */}
          </section>
          {/* End Section */}
          {/* ==============================================
                       **SERVICES SECTION**
        =============================================== */}
          <section className="services ptb-100 bg-page">
            <div className="container">
              <div className="row">
                <div className="col-md-8 col-sm-8 col-xs-12">
                  <div className="ptb-5">
                    <div className="main-title">
                      <h3 style={{backgroundColor: "#BEC5CC", height:"35px", padding:"4px"}}> <strong style={{marginLeft: "10px"}}>    {" "}Acrylic Polymers for Construction Chemicals</strong></h3>
                      <span className="seperator" />
                    </div>
                    <ul className="list" style={{backgroundColor: "#A8CCED", marginTop:"5px"}}>
                    
                    <li>
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                        
                          {" "}
                          ACRYLIC EMULSIONS FOR PAINTS

                        
                          </li>
                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                       
                          {" "}
                          ACRYLIC EMULSIONS FOR WATERBASED INKS

                        
                      </li>
                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                          {" "}
                          WATER BASED POLYURETHANE DISPERSIONS (PUD'S)
                        
                      </li>
                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                          {" "}
                          NANO-ACRYLICS FOR PAINTS, COATINGS ON METAL, WOOD
                        
                      </li>
                      <li>
                    
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}  HYGIENE COATINGS FOR CLEANROOMS

                        
                      </li>
           
                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}
                      WATERPROOFING CONSTRUCTION CHEMICALS

                        
                      </li>
                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}
                      SELF PRIMING DIRECT TO CONCRETE EMULSIONS

                        
                      </li>
                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}
                      ADVANCED SPECIALTY ARCHITECTURAL EMULSIONS (DTM)


                        
                      </li>
                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}
                      SELF CROSSLINKING EMULSIONS
                        
                      </li>

                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}
                      TIMBER EMULSIONS

                        
                      </li>
                       <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}
                      OVER PRINT VARNISH INDUSTRY


                        
                      </li>
                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}
                      TEXTURE COATINGS & ROOF POINTING EMULSIONS

                        
                      </li>


                      <li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}
                      ELASTOMERIC & WATERPROOFING EMULSIONS

</li>
<li >
                      <i class="fa-sharp fa-solid fa-arrow-right"></i>
                      {" "}
                      WOOD PAINTS

</li>

                    </ul>
                  </div>
                
          
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h3 style={{backgroundColor: "#BEC5CC", height:"30px"}}><strong>Water Based Polyurethane Dispersions (PUD's)</strong></h3>
                      <span className="seperator" />
                    </div>
                    <ul className="list" style={{backgroundColor: "#A8CCED"}}>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                      
                          {" "}
                          KAMTHANE-1435 E
                        {" "}
                        (Elastomeric, anionic Polyurethane dispersion)
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                    
                          {" "}
                          KAMTHANE K-1492{" "}
                        {" "}
                        ("work-horse" aqueous aliphatic polyurethane dispersion){" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMICRYL - 24 SC{" "}
                        {" "}
                        (Self-crosslinking acrylic copolymer emulsion compatible with
                        the PUD's){" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                      
                          {" "}
                          PU WALLGAURD (Hygiene Wall Coating){" "}
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                    
                          {" "}
                          KAMTHANE K-2016{" "}
                        
                        (Aliphatic hybrid polyurethane dispersion for Wood Coating
                        applications){" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                     
                          {" "}
                          KAMTHANE AQUA COLOUR TOP
                        {" "}
                        (Hygiene Aqueous PUD based Floor Coating){" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                     
                          {" "}
                          KAMTHANE K-2833{" "}
                        
                        (Aromatic hybrid polyurethane dispersion for Wood Coating
                        applications){" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                      
                          {" "}
                          TDS Kamthane K 164 PP (Aliphatic PUD){" "}
                        
                        NMP Free, Pure Aliphatic Clear PUD for Wood Coating applications{" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                     
                          {" "}
                          Water based PUD recommendations for Sports timber courts &amp;
                          other parquet type Wooden flooring.
                        {" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                
                          {" "}
                          TDS Kamthane S-1801{" "}
                        
                        Aliphatic Urethane dispersion for filmic primer / Plastic
                        substrates{" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                    
                          {" "}
                          TDS Kamthane 1432{" "}
                        
                        Flexible, solvent free aliphatic polyurethane dispersion for
                        Glass Fibre Sizing / Cable Coating applications{" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                      
                          {" "}
                          Kamthane PC4601{" "}
                        
                        (NMP free, aliphatic hybrid polyurethane dispersion for Clear
                        Concrete Coatings &amp; Pigmented Paints for Floor Coatings){" "}
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Nano-Acrylics for Paints, Coatings on Metal, Wood</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                       
                          {" "}
                          KAMTHANE K- 7000{" "}
                        {" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                       
                          {" "}
                          KAMICRYL - 31 SC HG{" "}
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                      TDS Kamicryl-60 SC HG 
                      </li>
                      <br />
                      <h5>Clear PU Topcoats for Concrete</h5>
                      <br />
                      <li className="ion-android-arrow-forward primary-color">
                   
                          {" "}
                          Kamicryl-CS 0002 - Clear PU PRIMER{" "}
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                  
                          {" "}
                          Kamicryl-CS 0003 HS - Clear PU TOP COAT
                        {" "}
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Hygiene Coatings for Cleanrooms</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
          
                          {" "}
                          PU WALLGUARD (Hygiene Wall Coating){" "}
                        {" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                 
                          {" "}
                          KAMICRYL-1492{" "}
                        
                        ("work-horse" aqueous aliphatic polyurethane dispersion) =
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                     
                          {" "}
                          KAMICRYL 24 SC
                        {" "}
                        (Self-crosslinking acrylic copolymer emulsion compatible with
                        the PUD's){" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
       
                          {" "}
                          Single component Aqueous Polyurethane Paint (PU Paint){" "}
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
              
                          {" "}
                          Kamthane Aqua Colour Top (Hygiene Aqueous PUD based Floor
                          Coating)
                        {" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
         
                          {" "}
                          Kamflex -WP (Aqueous PUD based Heat Reflective, Elastomeric
                          Waterproofing Coating)
                        {" "}
                      </li>
                      <br />
                      <h5>Clear PU Topcoats for Concrete</h5>
                      <br />
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          Kamicryl-CS 0002 - Clear PU PRIMER
                        {" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
               
                          {" "}
                          Kamicryl-CS 0003 HS - Clear PU TOP COAT
                        {" "}
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Waterproofing Construction Chemicals</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                         SEALTITE (Flexible Film Polymer
                        Emulsion for use with Cement for Waterproofing / Structural
                        Rehabilitation, Beam / Column Repairs)
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                       SEALCOAT (Waterproofing Elastomeric
                        Exterior Membrane Coating: Dual properties of Waterproofing
                        &amp; Aesthetic Exterior Paint){" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                         SEALCOAT(Application as an
                        Exterior Coating){" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        KAMICRYL ELASTOFALT (PU
                        modified Bitumen Emulsion for Waterproofing of concrete and
                        block foundations, retaining walls, basements and wet areas such
                        as kitchen, toilet floors and balconies){" "}
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMROOF GFM (Glass Fibre Matt) System
                       {" "}
                        Kamroof GFM (Glass Fibre Matt) System using a combination of
                        above products{" "}
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Self Priming Direct to Concrete Emulsions</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                       
                          {" "}
                          KAMHYB 3120
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                       
                          {" "}
                          KAMICRYL 31 SC HG-tds
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          Kamicryl 340 CC-tds
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          Kamthane PC 612-tds
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                      
                          {" "}
                          Kamthane PC4601-tds
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
           
                          {" "}
                          Kamthane PC4650-tds
                       
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Advanced Specialty Architectural Emulsions</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                      
                          {" "}
                          KAMICRYL PR-C35 tds
                      
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                       
                          {" "}
                          KAMICRYL PR SERIES
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMICRYL PR-C25
                      
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                      
                          {" "}
                          KAMICRYL PR-C18
                      
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMICRYL STR C26
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                      
                          {" "}
                          KAMICRYL PR CX 15 HYB
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMICRYL FPR 320 E
                       
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Self Crosslinking Emulsions</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                        
                          {" "}
                          KAMICRYL 2 ST ZX
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                       
                          {" "}
                          KAMICRYL 12 SC ZX
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                      
                          {" "}
                          KAMICRYL 24 SC ZX
                      
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                     
                          {" "}
                          KAMICRYL 35 SC ZX
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                    
                          {" "}
                          KAMICRYL 48 SC ZX
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        
                          {" "}
                          KAMICRYL K-2074 OR
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMICRYL 63-SCX
                        
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Timber Emulsions</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                      
                          {" "}
                          KAMICRYL HYB S 18
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                        
                          {" "}
                          KAMICRYL TM 15 WX
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMICRYL TM 14
                      
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                      
                          {" "}
                          KAMICRYL UM 19
                        
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Graphic Arts, OVERPRINT VARNISH &amp; INKS</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                      
                          {" "}
                          ACRYLIC DISPERSIONS FOR PAPER &amp; INKS
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                       
                          {" "}
                          KAMPRILL 100 R{" "}
                        
                        (solid acyralic reis)
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        
                          {" "}
                          KAMICRYL OPV - HG 8000
                      
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Texture Coatings &amp; Roof Pointing Emulsions</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                      
                          {" "}
                          KAMICRYL EWR 2700
                        
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                      
                          {" "}
                          KAMICRYL 298 FCX
                          </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMICRYL-297 LV
                       
                      </li>
                    </ul>
                  </div> */}
                  {/* <div className="testimonials-container" />
                  <div className="ptb-5">
                    <div className="main-title">
                      <h5><strong>Elastomeric &amp; Waterproofing Emulsions</strong></h5>
                      <span className="seperator" />
                    </div>
                    <ul className="list">
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                     
                          {" "}
                          KAMFLEX 500 HYB
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                        {" "}
                      
                          {" "}
                          KAMHYB 1466
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMICRYL 2 SC HYB
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                       
                          {" "}
                          KAMICRYL 300 HYB
                       
                      </li>
                      <li className="ion-android-arrow-forward primary-color">
                    
                          {" "}
                          Kamthane RAR THX
                      
                      </li>
                    </ul>
                  </div> */}
                </div>
                
              </div>
            </div>
            {/* End Container */}
          </section>
          {/* End Section */}
        </>
        
        
        );
    }
}


export  default  Emulsion;