import React from "react";

import { ScrollMenu } from "react-horizontal-scrolling-menu";


class MachineColorant extends React.Component{
    render(){
        return(
    
<div className="container-fluid py-5">
  <div className="container">
   
 
    <>
    <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Machine Colorant &amp; Stainers</h1>
                 
                </div>
                <div className="product-img">
                  <picture>
                    <source
                     
                      srcSet="/assets/images/machine-colorants-250x250.webp"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                   
                    <br />
                  
                    <div className="product-description">
                      <br />
                      <br />
                      <br />
                      <p>
                      As one of the leading manufacturers and exporters in this domain, we
            offer our clients superior quality Pigment Paste. The offered
            pigment paste is mainly applicable for coating and coloring of
            reinforced plastics, fiber glass etc. Microfast Fine Pastes are easy
            to use and high in strength and quality products. Having extensive
            application in Paints, Textiles, Paper, Ink, Soaps and Detergent
            industry. We offer extensive product range to meet the demands of
            the customer. Multiple Microfast Fine Pastes can also be mixed in
            various compositions to get desired shades.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>
  <section className="testimonials-one container">
    <div className="row">
    <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                 
                </div>
                <div className="product-img">
                  <picture>
                    <br />
                    <br />

                     <br /> <br />
                     <br />
                    <source
                     
                      srcSet="/assets/images/machine-colorants-716785.jpg"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                   
                    <br />
                  
                    <div className="product-description">
                      <br />
                      <br />
                      <br />
                      <p>
  <strong>Microtint MC </strong>are Machine Dispensable Colorants for
  point of sale tinting of Architectural Paints. It is a set of 15
  colorants suitable for Interior as well as Exterior purpose.
</p>
<p>
  -Microtint colorants are compatible with following types of paints.
  <br />
  -Water based emulsion paints
  <br />
  -Alkyds Paints
  <br />
  -Synthetic Enamels
  <br />
  -Latex paints
</p>
<h4>Key features</h4>
<p>
  -Excellent compatibility with wide range of Architectural Coatings.
  <br />
  -Superior Light and Weather fastness.
  <br />
  -Strict control on batch approval results, to ensure reproducibility
  of color values.
  <br />
  -Options of Exterior and Interior colorants to minimize final shade
  cost.
  <br />
  -Excellent stability with minimum agitation and do not skin.
  <br />
  -Suitable for all types of Dispensing Machines.
  <br />
  -Most Cost Economical.
</p>
<h4>Product Quality</h4>
<p>
  Colorants are manufactured in latest generation of Dispersion
  Machines. This will ensure color reproducibility batch after batch.
  Approval of batch within stringent specified limits ensures good flow,
  rheology, compatibility with wide range of bases, light and weather
  fastness properties.
</p>
<h4>Services and support</h4>
<p>
  Colorants are offered with full support of Color Matching for your own
  fan decks and shade cards. We also assist in selection of Tinting
  Machines, gyro shakers, display racks and provide technical support on
  standardization of bases. We are committed to take care for all your
  tinting needs.
</p>
<p />
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>

          <div className="hidden-mobile">
          <table border={1} className="table table-bordered mb-5">
        <tbody>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td
              className="text-center"
              style={{ width: "22%", verticalAlign: "middle" }}
            >
              MICROTINT MC
            </td>
            <td style={{ verticalAlign: "middle" }}>PIGMENT CI NAME</td>
            <td style={{ width: "15%", verticalAlign: "middle" }}>
              Solid % (+/-5%)
            </td>
            <td style={{ width: "15%", verticalAlign: "middle" }}>
              Specific Gravity
            </td>
            <td className="text-center">MT</td>
            <td className="text-center">RT</td>
            <td className="text-center">Wether Fastness</td>
            <td className="text-center">INT.</td>
            <td className="text-center">EXT.</td>
          </tr>
          <tr>
            <td>Fast Yellow Oxide</td>
            <td>Yellow 42</td>
            <td className="text-center">59</td>
            <td className="text-center">1.76</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>IIT Red Oxide</td>
            <td>Red 101</td>
            <td className="text-center">52</td>
            <td className="text-center">1.83</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>White</td>
            <td>White 6</td>
            <td className="text-center">60</td>
            <td className="text-center">1.85</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Fast Black</td>
            <td>Black 7</td>
            <td className="text-center">10</td>
            <td className="text-center">1.37</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Magenta</td>
            <td>Red 122</td>
            <td className="text-center">20</td>
            <td className="text-center">1.1</td>
            <td className="text-center">7</td>
            <td className="text-center">6</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>HT Violet</td>
            <td>Violet 23</td>
            <td className="text-center">3</td>
            <td className="text-center">1.33</td>
            <td className="text-center">7</td>
            <td className="text-center">6</td>
            <td className="text-center">3</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Fast Yellow</td>
            <td>Yellow 74</td>
            <td className="text-center">6</td>
            <td className="text-center">1.19</td>
            <td className="text-center">6</td>
            <td className="text-center">4</td>
            <td className="text-center">2</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>Fast Green</td>
            <td>Green 7</td>
            <td className="text-center">15</td>
            <td className="text-center">1.31</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Interior Red</td>
            <td>Red 112</td>
            <td className="text-center">5</td>
            <td className="text-center">1.35</td>
            <td className="text-center">6</td>
            <td className="text-center">4</td>
            <td className="text-center">2</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>Fast Blue</td>
            <td>Blue 15:3</td>
            <td className="text-center">15</td>
            <td className="text-center">1.24</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>HT Yellow</td>
            <td>Yellow 138</td>
            <td className="text-center">25</td>
            <td className="text-center">1.19</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">4</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>HT Blue</td>
            <td>Blue 15:1</td>
            <td className="text-center">22</td>
            <td className="text-center">1.12</td>
            <td className="text-center">8</td>
            <td className="text-center">7</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>HT Green</td>
            <td>Green 7</td>
            <td className="text-center">32</td>
            <td className="text-center">1.2</td>
            <td className="text-center">8</td>
            <td className="text-center">7</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Exterior Red</td>
            <td>Red 254</td>
            <td className="text-center">9</td>
            <td className="text-center">1.33</td>
            <td className="text-center">8</td>
            <td className="text-center">7</td>
            <td className="text-center">4</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Orange</td>
            <td>Yellow 139</td>
            <td className="text-center">13</td>
            <td className="text-center">1.18</td>
            <td className="text-center">7</td>
            <td className="text-center">6</td>
            <td className="text-center">4</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
        </tbody>
      </table>
</div>
          <ScrollMenu>
          <div className="table1">
      <table border={1} className="table table-bordered mb-5">
        <tbody>
          <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
            <td
              className="text-center"
              style={{ width: "22%", verticalAlign: "middle" }}
            >
              MICROTINT MC
            </td>
            <td style={{ verticalAlign: "middle" }}>PIGMENT CI NAME</td>
            <td style={{ width: "15%", verticalAlign: "middle" }}>
              Solid % (+/-5%)
            </td>
            <td style={{ width: "15%", verticalAlign: "middle" }}>
              Specific Gravity
            </td>
            <td className="text-center">MT</td>
            <td className="text-center">RT</td>
            <td className="text-center">Wether Fastness</td>
            <td className="text-center">INT.</td>
            <td className="text-center">EXT.</td>
          </tr>
          <tr>
            <td>Fast Yellow Oxide</td>
            <td>Yellow 42</td>
            <td className="text-center">59</td>
            <td className="text-center">1.76</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>IIT Red Oxide</td>
            <td>Red 101</td>
            <td className="text-center">52</td>
            <td className="text-center">1.83</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>White</td>
            <td>White 6</td>
            <td className="text-center">60</td>
            <td className="text-center">1.85</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Fast Black</td>
            <td>Black 7</td>
            <td className="text-center">10</td>
            <td className="text-center">1.37</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Magenta</td>
            <td>Red 122</td>
            <td className="text-center">20</td>
            <td className="text-center">1.1</td>
            <td className="text-center">7</td>
            <td className="text-center">6</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>HT Violet</td>
            <td>Violet 23</td>
            <td className="text-center">3</td>
            <td className="text-center">1.33</td>
            <td className="text-center">7</td>
            <td className="text-center">6</td>
            <td className="text-center">3</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Fast Yellow</td>
            <td>Yellow 74</td>
            <td className="text-center">6</td>
            <td className="text-center">1.19</td>
            <td className="text-center">6</td>
            <td className="text-center">4</td>
            <td className="text-center">2</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>Fast Green</td>
            <td>Green 7</td>
            <td className="text-center">15</td>
            <td className="text-center">1.31</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Interior Red</td>
            <td>Red 112</td>
            <td className="text-center">5</td>
            <td className="text-center">1.35</td>
            <td className="text-center">6</td>
            <td className="text-center">4</td>
            <td className="text-center">2</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">--</td>
          </tr>
          <tr>
            <td>Fast Blue</td>
            <td>Blue 15:3</td>
            <td className="text-center">15</td>
            <td className="text-center">1.24</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>HT Yellow</td>
            <td>Yellow 138</td>
            <td className="text-center">25</td>
            <td className="text-center">1.19</td>
            <td className="text-center">8</td>
            <td className="text-center">8</td>
            <td className="text-center">4</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>HT Blue</td>
            <td>Blue 15:1</td>
            <td className="text-center">22</td>
            <td className="text-center">1.12</td>
            <td className="text-center">8</td>
            <td className="text-center">7</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>HT Green</td>
            <td>Green 7</td>
            <td className="text-center">32</td>
            <td className="text-center">1.2</td>
            <td className="text-center">8</td>
            <td className="text-center">7</td>
            <td className="text-center">5</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Exterior Red</td>
            <td>Red 254</td>
            <td className="text-center">9</td>
            <td className="text-center">1.33</td>
            <td className="text-center">8</td>
            <td className="text-center">7</td>
            <td className="text-center">4</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
          <tr>
            <td>Orange</td>
            <td>Yellow 139</td>
            <td className="text-center">13</td>
            <td className="text-center">1.18</td>
            <td className="text-center">7</td>
            <td className="text-center">6</td>
            <td className="text-center">4</td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
            <td className="text-center">
              <i className="fas fa-circle" />
            </td>
          </tr>
        </tbody>
      </table>
      </div>
      </ScrollMenu>
    </div>
  </section>
</>

  </div>
</div>


        
        );
    }
}


export  default MachineColorant;