import React from "react";

import { ScrollMenu } from "react-horizontal-scrolling-menu";



class Emulsion extends React.Component{
    render(){
        return(


<>
<section className=" container">
  <h3
    style={{
      textAlign: "center",
      fontSize: 49,
      padding: 24,
      color: "red",
      textDecoration: "underline"
    }}
  >
    Paint
  </h3>
  <div className="row ">
    <img
      src="assets/images/custom/Paint.png"
      style={{ width: "100%", height: 400 }}
    />

<div className="hidden-mobile">
<table border={1} className="table table-bordered ">
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td
            rowSpan={2}
            className="text-center"
            style={{ width: "13%", verticalAlign: "middle" }}
          >
            Color
          </td>
          <td rowSpan={2} style={{ verticalAlign: "middle" }}>
            Product
          </td>
          <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
            C.I.No.
          </td>
          <td colSpan={2} className="text-center">
            Light Fastness
          </td>
          <td colSpan={2} className="text-center">
            Application
          </td>
        </tr>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td className="text-center">5%</td>
          <td className="text-center">1%</td>
          <td className="text-center">Interior</td>
          <td className="text-center">Exterior</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FFEA00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5511</td>
          <td>P.Y. 3</td>
          <td className="text-center">7</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FDDA0D", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5509</td>
          <td>P.Y. 74</td>
          <td className="text-center">7</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FFFF8F", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5155</td>
          <td>P.Y. 138</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#DFFF00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5517</td>
          <td>P.Y. 1</td>
          <td className="text-center">7</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#E4D00A", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5518</td>
          <td>P.Y. 12</td>
          <td className="text-center">5</td>
          <td className="text-center">3</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">--</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FAD5A5", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5512</td>
          <td>P.Y. 83</td>
          <td className="text-center">7</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FFD700", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5138</td>
          <td>P.Y. 138</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f8a07a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Orange 5535</td>
          <td>P.O. 5</td>
          <td className="text-center">7</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f68e4f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Orange 5536</td>
          <td>P.O. 13</td>
          <td className="text-center">6D</td>
          <td className="text-center">4</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">--</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f27036", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Orange 5534</td>
          <td>P.O. 34</td>
          <td className="text-center">7</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f36ea7", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5551</td>
          <td>P.R. 8</td>
          <td className="text-center">6D</td>
          <td className="text-center">4</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">--</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e879b0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Micorfast Red 5553</td>
          <td>P.R. 122</td>
          <td className="text-center">8D</td>
          <td className="text-center">7</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f1649b", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5546</td>
          <td>P.R. 146</td>
          <td className="text-center">7D</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f486b3", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5547</td>
          <td>P.V. 19</td>
          <td className="text-center">8</td>
          <td className="text-center">7</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f0557d", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5550</td>
          <td>P.R. 210</td>
          <td className="text-center">7D</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f2707a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5548</td>
          <td>P.R. 112</td>
          <td className="text-center">7D</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ed4344", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5555</td>
          <td>P.R. 254</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f99892", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5549</td>
          <td>P.R. 3</td>
          <td className="text-center">7D</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#8781bf", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Violet 5591</td>
          <td>P.V. 23</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#488ecc", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Blue 5612</td>
          <td>P.B. 15:0</td>
          <td className="text-center">8</td>
          <td className="text-center">7</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#63a8d1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Blue 5615</td>
          <td>P.B. 15:3</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "green", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Green 5631</td>
          <td>P.G. 7</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "black", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Black 5683</td>
          <td>P.Bl. 7</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#C58E38", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow Oxide 920</td>
          <td>P.Y. 42</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "yellow", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 130</td>
          <td>P.R. 101</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
      </tbody>
    </table>
</div>

     <ScrollMenu>
      <div className="table1">
    <table border={1} className="table table-bordered ">
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td
            rowSpan={2}
            className="text-center"
            style={{ width: "13%", verticalAlign: "middle" }}
          >
            Color
          </td>
          <td rowSpan={2} style={{ verticalAlign: "middle" }}>
            Product
          </td>
          <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
            C.I.No.
          </td>
          <td colSpan={2} className="text-center">
            Light Fastness
          </td>
          <td colSpan={2} className="text-center">
            Application
          </td>
        </tr>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td className="text-center">5%</td>
          <td className="text-center">1%</td>
          <td className="text-center">Interior</td>
          <td className="text-center">Exterior</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FFEA00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5511</td>
          <td>P.Y. 3</td>
          <td className="text-center">7</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FDDA0D", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5509</td>
          <td>P.Y. 74</td>
          <td className="text-center">7</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FFFF8F", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5155</td>
          <td>P.Y. 138</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#DFFF00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5517</td>
          <td>P.Y. 1</td>
          <td className="text-center">7</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#E4D00A", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5518</td>
          <td>P.Y. 12</td>
          <td className="text-center">5</td>
          <td className="text-center">3</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">--</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FAD5A5", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5512</td>
          <td>P.Y. 83</td>
          <td className="text-center">7</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FFD700", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 5138</td>
          <td>P.Y. 138</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f8a07a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Orange 5535</td>
          <td>P.O. 5</td>
          <td className="text-center">7</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f68e4f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Orange 5536</td>
          <td>P.O. 13</td>
          <td className="text-center">6D</td>
          <td className="text-center">4</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">--</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f27036", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Orange 5534</td>
          <td>P.O. 34</td>
          <td className="text-center">7</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f36ea7", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5551</td>
          <td>P.R. 8</td>
          <td className="text-center">6D</td>
          <td className="text-center">4</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">--</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e879b0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Micorfast Red 5553</td>
          <td>P.R. 122</td>
          <td className="text-center">8D</td>
          <td className="text-center">7</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f1649b", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5546</td>
          <td>P.R. 146</td>
          <td className="text-center">7D</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f486b3", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5547</td>
          <td>P.V. 19</td>
          <td className="text-center">8</td>
          <td className="text-center">7</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f0557d", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5550</td>
          <td>P.R. 210</td>
          <td className="text-center">7D</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f2707a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5548</td>
          <td>P.R. 112</td>
          <td className="text-center">7D</td>
          <td className="text-center">6</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ed4344", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5555</td>
          <td>P.R. 254</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#f99892", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Red 5549</td>
          <td>P.R. 3</td>
          <td className="text-center">7D</td>
          <td className="text-center">5</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="far fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#8781bf", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Violet 5591</td>
          <td>P.V. 23</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#488ecc", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Blue 5612</td>
          <td>P.B. 15:0</td>
          <td className="text-center">8</td>
          <td className="text-center">7</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#63a8d1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Blue 5615</td>
          <td>P.B. 15:3</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "green", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Green 5631</td>
          <td>P.G. 7</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "black", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Black 5683</td>
          <td>P.Bl. 7</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#C58E38", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow Oxide 920</td>
          <td>P.Y. 42</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "yellow", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microfast Yellow 130</td>
          <td>P.R. 101</td>
          <td className="text-center">8</td>
          <td className="text-center">8</td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
          <td className="text-center">
            <i className="fas fa-circle" />
          </td>
        </tr>
      </tbody>
    </table>
    </div>
    </ScrollMenu>
  </div>
  <h3
    style={{
      textAlign: "center",
      fontSize: 49,
      padding: 24,
      color: "red",
      textDecoration: "underline"
    }}
  >
    Seed Coating
  </h3>
  <div className="row ">
    <img
      src="assets/images/custom/SeedCoating.png"
      style={{ width: "100%", height: 400 }}
    />
<div className="hidden-mobile">
<table border={1} className="table table-bordered ">
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td
            className="text-center"
            style={{ width: "13%", verticalAlign: "middle" }}
          >
            Shade
          </td>
          <td style={{ verticalAlign: "middle" }}>Product</td>
          <td style={{ verticalAlign: "middle" }}>Pigment</td>
          <td style={{ width: "15%", verticalAlign: "middle" }}>C.I.No.</td>
          <td className="text-center">% Non Volatiles (+/-3)</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fffea1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Yellow G</td>
          <td>P.Y. 1</td>
          <td className="text-center">11680</td>
          <td className="text-center">43</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fefe00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Yellow 5G</td>
          <td>P.Y. 74</td>
          <td className="text-center">11741</td>
          <td className="text-center">36</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ffff81", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Yellow 10G</td>
          <td>P.Y. 3</td>
          <td className="text-center">11710</td>
          <td className="text-center">43</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#538bd5", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Blue A2R</td>
          <td>P.B. 15</td>
          <td className="text-center">74160</td>
          <td className="text-center">50</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#01b0f0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Blue B2G</td>
          <td>P.B. 15:3</td>
          <td className="text-center">74160</td>
          <td className="text-center">50</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#00b04e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Green G</td>
          <td>P.G. 7</td>
          <td className="text-center">74260</td>
          <td className="text-center">53</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ff339a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Red F4R</td>
          <td>P.R. 8</td>
          <td className="text-center">12335</td>
          <td className="text-center">50</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fe0000", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Red FGR</td>
          <td>P.R. 112</td>
          <td className="text-center">12370</td>
          <td className="text-center">50</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fe0000", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Perma Red</td>
          <td>P.R. 48:2</td>
          <td className="text-center">15865:2</td>
          <td className="text-center">38</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ffbe00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Orange G</td>
          <td>P.O. 13</td>
          <td className="text-center">21110</td>
          <td className="text-center">42</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#6f2fa1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Purple</td>
          <td>P.V. 23</td>
          <td className="text-center">51319</td>
          <td className="text-center">39</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "black", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Black G</td>
          <td>P.Bl. 7</td>
          <td className="text-center">77226</td>
          <td className="text-center">40</td>
        </tr>
      </tbody>
    </table>
    </div>
     <ScrollMenu>
      <div className="table1">
    <table border={1} className="table table-bordered ">
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td
            className="text-center"
            style={{ width: "13%", verticalAlign: "middle" }}
          >
            Shade
          </td>
          <td style={{ verticalAlign: "middle" }}>Product</td>
          <td style={{ verticalAlign: "middle" }}>Pigment</td>
          <td style={{ width: "15%", verticalAlign: "middle" }}>C.I.No.</td>
          <td className="text-center">% Non Volatiles (+/-3)</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fffea1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Yellow G</td>
          <td>P.Y. 1</td>
          <td className="text-center">11680</td>
          <td className="text-center">43</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fefe00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Yellow 5G</td>
          <td>P.Y. 74</td>
          <td className="text-center">11741</td>
          <td className="text-center">36</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ffff81", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Yellow 10G</td>
          <td>P.Y. 3</td>
          <td className="text-center">11710</td>
          <td className="text-center">43</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#538bd5", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Blue A2R</td>
          <td>P.B. 15</td>
          <td className="text-center">74160</td>
          <td className="text-center">50</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#01b0f0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Blue B2G</td>
          <td>P.B. 15:3</td>
          <td className="text-center">74160</td>
          <td className="text-center">50</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#00b04e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Green G</td>
          <td>P.G. 7</td>
          <td className="text-center">74260</td>
          <td className="text-center">53</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ff339a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Red F4R</td>
          <td>P.R. 8</td>
          <td className="text-center">12335</td>
          <td className="text-center">50</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fe0000", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Red FGR</td>
          <td>P.R. 112</td>
          <td className="text-center">12370</td>
          <td className="text-center">50</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fe0000", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Perma Red</td>
          <td>P.R. 48:2</td>
          <td className="text-center">15865:2</td>
          <td className="text-center">38</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ffbe00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Orange G</td>
          <td>P.O. 13</td>
          <td className="text-center">21110</td>
          <td className="text-center">42</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#6f2fa1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Purple</td>
          <td>P.V. 23</td>
          <td className="text-center">51319</td>
          <td className="text-center">39</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "black", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microsperse Black G</td>
          <td>P.Bl. 7</td>
          <td className="text-center">77226</td>
          <td className="text-center">40</td>
        </tr>
      </tbody>
    </table>
    </div>
    </ScrollMenu>
  </div>
  <h3
    style={{
      textAlign: "center",
      fontSize: 49,
      padding: 24,
      color: "red",
      textDecoration: "underline"
    }}
  >
    INK
  </h3>
  <div className="row ">
    <img
      src="assets/images/custom/INK.png"
      style={{ width: "100%", height: 400 }}
    />
<div className="hidden-mobile">
<table border={1} className="table table-bordered mb-5" id="">
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td className="text-center" style={{ width: "13%" }}>
            Color
          </td>
          <td>Product</td>
          <td style={{ width: "15%" }}>C.I.No.</td>
          <td style={{ width: "15%" }}>Pigment %</td>
          <td>Water Resistance</td>
          <td>Acid Resistance</td>
          <td>Alkali Resistance</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 1</td>
          <td>P.Y. 1</td>
          <td className="text-center">35</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fcf290", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 3</td>
          <td>P.Y. 3</td>
          <td className="text-center">35</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 12</td>
          <td>P.Y. 12</td>
          <td className="text-center">40</td>
          <td className="text-center">5</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#feeb8c", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 13</td>
          <td>P.Y. 13</td>
          <td className="text-center">35</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fce33e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 14</td>
          <td>P.Y. 14</td>
          <td className="text-center">35</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fcba63", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 74</td>
          <td>P.Y. 74</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e6a139", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 138</td>
          <td>P.Y. 138</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FFBF00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Orange 5</td>
          <td>P.O. 5</td>
          <td className="text-center">35</td>
          <td className="text-center">4</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FBCEB1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Orange 13</td>
          <td>P.O. 13</td>
          <td className="text-center">40</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#F2D2BD", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Orange 34</td>
          <td>P.O. 34</td>
          <td className="text-center">35</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "red", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 2</td>
          <td>P.R. 2</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">4</td>
          <td className="text-center">4</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#880808", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 53:1</td>
          <td>P.R. 53:1</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">3</td>
          <td className="text-center">3</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#EE4B2B", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 57:1</td>
          <td>P.R. 57:1</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">4</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#E97451", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 122</td>
          <td>P.R. 122</td>
          <td className="text-center">25</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e879b0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 146</td>
          <td>P.R. 146</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">4</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#8781bf", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Violet 19</td>
          <td>P.V. 19</td>
          <td className="text-center">25</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#3a3168", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Violet 23</td>
          <td>P.V. 23</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#488ecc", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Blue 15:0</td>
          <td>P.B. 15:0</td>
          <td className="text-center">45</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#63a8d1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Blue 15:1</td>
          <td>P.B. 15:1</td>
          <td className="text-center">40</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#33487d", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Blue 15:3</td>
          <td>P.B. 15:3</td>
          <td className="text-center">45</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "green", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Green 7</td>
          <td>P.G. 7</td>
          <td className="text-center">50</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "black", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Black 7</td>
          <td>P.Bl. 7</td>
          <td className="text-center">38</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
      </tbody>
    </table>
    </div>
    <ScrollMenu>
       <div className="table1">
    <table border={1} className="table table-bordered mb-5" id="">
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td className="text-center" style={{ width: "13%" }}>
            Color
          </td>
          <td>Product</td>
          <td style={{ width: "15%" }}>C.I.No.</td>
          <td style={{ width: "15%" }}>Pigment %</td>
          <td>Water Resistance</td>
          <td>Acid Resistance</td>
          <td>Alkali Resistance</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 1</td>
          <td>P.Y. 1</td>
          <td className="text-center">35</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fcf290", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 3</td>
          <td>P.Y. 3</td>
          <td className="text-center">35</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 12</td>
          <td>P.Y. 12</td>
          <td className="text-center">40</td>
          <td className="text-center">5</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#feeb8c", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 13</td>
          <td>P.Y. 13</td>
          <td className="text-center">35</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fce33e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 14</td>
          <td>P.Y. 14</td>
          <td className="text-center">35</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#fcba63", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 74</td>
          <td>P.Y. 74</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e6a139", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Yellow 138</td>
          <td>P.Y. 138</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FFBF00", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Orange 5</td>
          <td>P.O. 5</td>
          <td className="text-center">35</td>
          <td className="text-center">4</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#FBCEB1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Orange 13</td>
          <td>P.O. 13</td>
          <td className="text-center">40</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
          <td className="text-center">4/5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#F2D2BD", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Orange 34</td>
          <td>P.O. 34</td>
          <td className="text-center">35</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "red", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 2</td>
          <td>P.R. 2</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">4</td>
          <td className="text-center">4</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#880808", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 53:1</td>
          <td>P.R. 53:1</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">3</td>
          <td className="text-center">3</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#EE4B2B", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 57:1</td>
          <td>P.R. 57:1</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">4</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#E97451", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 122</td>
          <td>P.R. 122</td>
          <td className="text-center">25</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e879b0", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Red 146</td>
          <td>P.R. 146</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">4</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#8781bf", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Violet 19</td>
          <td>P.V. 19</td>
          <td className="text-center">25</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#3a3168", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Violet 23</td>
          <td>P.V. 23</td>
          <td className="text-center">30</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#488ecc", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Blue 15:0</td>
          <td>P.B. 15:0</td>
          <td className="text-center">45</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#63a8d1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Blue 15:1</td>
          <td>P.B. 15:1</td>
          <td className="text-center">40</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#33487d", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Blue 15:3</td>
          <td>P.B. 15:3</td>
          <td className="text-center">45</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "green", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Green 7</td>
          <td>P.G. 7</td>
          <td className="text-center">50</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
        <tr>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "black", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>Microtint Black 7</td>
          <td>P.Bl. 7</td>
          <td className="text-center">38</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
          <td className="text-center">5</td>
        </tr>
      </tbody>
    </table>
    </div>
   </ScrollMenu>
  </div>
  <h3
    style={{
      textAlign: "center",
      fontSize: 49,
      padding: 24,
      color: "red",
      textDecoration: "underline"
    }}
  >
    Textile
  </h3>
  <div className="row">
    <img
      src="assets/images/custom/Textile.png"
      style={{ width: "100%", height: 400 }}
    />
<div className="hidden-mobile">
<table
      border={1}
      className="table table-bordered"
      style={{ color: "black" }}
    >
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td style={{ width: "15%" }}>Product Name</td>
          <td className="text-center" colSpan={2}>
            Screen Print On Cotton Fabrics
          </td>
          <td className="text-center" style={{ width: "10%" }}>
            Printing Concentration (Reducation 1:4) g/kg s{" "}
          </td>
          <td className="text-center" colSpan={3}>
            Light Fastness Xenon test
          </td>
          <td className="text-center">Wash Fastness 950 C</td>
          <td className="text-center">Fastness to hypoclorite Severe</td>
          <td className="text-center" colSpan={2}>
            Perpriration Fastness
          </td>
          <td className="text-center">Fastness to Dry</td>
        </tr>
        <tr style={{ textCenter: "center" }}>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>4 %</td>
          <td style={{ textAlign: "center" }}>1 %</td>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>1/6 SD</td>
          <td style={{ textAlign: "center" }}>1/3 SD</td>
          <td style={{ textAlign: "center" }}>1/1 SD</td>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>Acidic</td>
          <td style={{ textAlign: "center" }}>Alkaline</td>
          <td style={{ textAlign: "center" }} />
        </tr>
        <tr>
          <td>Yellow F-2G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#edeb70", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#eef2a8", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>-</td>
          <td style={{ textAlign: "center" }}>3</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>1</td>
        </tr>
        <tr>
          <td>Yellow F-G</td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#ead12f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#eee44f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Yellow F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ec9f35", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e7bc3a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>6</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Gold Yellow F-M</td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#ef9e35", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#edc240", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>2-3</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Orange F-N</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#df3536", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e25836", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Orange F-G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e23e35", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e76335", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Orange F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e03b37", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e05f37", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>6</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Red F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d83138", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d83138", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>4</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Red F-N</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d83138", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e73b44", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>4</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Red F-G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d63338", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d63338", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>6</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Pink F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d2375f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e2759e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>1</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Bordeaux F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#9f3134", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#c23442", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
        </tr>
      </tbody>
    </table>
</div>
    <ScrollMenu>
        <div className="table1">
    <table
      border={1}
      className="table table-bordered"
      style={{ color: "black" }}
    >
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td style={{ width: "15%" }}>Product Name</td>
          <td className="text-center" colSpan={2}>
            Screen Print On Cotton Fabrics
          </td>
          <td className="text-center" style={{ width: "10%" }}>
            Printing Concentration (Reducation 1:4) g/kg s{" "}
          </td>
          <td className="text-center" colSpan={3}>
            Light Fastness Xenon test
          </td>
          <td className="text-center">Wash Fastness 950 C</td>
          <td className="text-center">Fastness to hypoclorite Severe</td>
          <td className="text-center" colSpan={2}>
            Perpriration Fastness
          </td>
          <td className="text-center">Fastness to Dry</td>
        </tr>
        <tr style={{ textCenter: "center" }}>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>4 %</td>
          <td style={{ textAlign: "center" }}>1 %</td>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>1/6 SD</td>
          <td style={{ textAlign: "center" }}>1/3 SD</td>
          <td style={{ textAlign: "center" }}>1/1 SD</td>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>Acidic</td>
          <td style={{ textAlign: "center" }}>Alkaline</td>
          <td style={{ textAlign: "center" }} />
        </tr>
        <tr>
          <td>Yellow F-2G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#edeb70", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#eef2a8", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>-</td>
          <td style={{ textAlign: "center" }}>3</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>1</td>
        </tr>
        <tr>
          <td>Yellow F-G</td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#ead12f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#eee44f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Yellow F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ec9f35", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e7bc3a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>6</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Gold Yellow F-M</td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#ef9e35", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#edc240", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>2-3</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Orange F-N</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#df3536", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e25836", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Orange F-G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e23e35", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e76335", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Orange F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e03b37", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e05f37", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>6</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Red F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d83138", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d83138", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>4</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Red F-N</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d83138", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e73b44", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>4</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Red F-G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d63338", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d63338", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>6</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Pink F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d2375f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#e2759e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>1</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Bordeaux F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#9f3134", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#c23442", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
        </tr>
      </tbody>
    </table>
    </div>
    </ScrollMenu>
    <br />
    <br />
    <br />
    <br />
<div className="hidden-mobile">
    <table
      border={1}
      className="table table-bordered"
      style={{ color: "black" }}
    >
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td style={{ width: "15%" }}>Product Name</td>
          <td className="text-center" colSpan={2}>
            Screen Print On Cotton Fabrics
          </td>
          <td className="text-center" style={{ width: "10%" }}>
            Printing Concentration (Reducation 1:4) g/kg s{" "}
          </td>
          <td className="text-center" colSpan={3}>
            Light Fastness Xenon test
          </td>
          <td className="text-center">Wash Fastness 950 C</td>
          <td className="text-center">Fastness to hypoclorite Severe</td>
          <td className="text-center" colSpan={2}>
            Perpriration Fastness
          </td>
          <td className="text-center">Fastness to Dry</td>
        </tr>
        <tr style={{ textCenter: "center" }}>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>4 %</td>
          <td style={{ textAlign: "center" }}>1 %</td>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>1/6 SD</td>
          <td style={{ textAlign: "center" }}>1/3 SD</td>
          <td style={{ textAlign: "center" }}>1/1 SD</td>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>Acidic</td>
          <td style={{ textAlign: "center" }}>Alkaline</td>
          <td style={{ textAlign: "center" }} />
        </tr>
        <tr>
          <td>Red F-M</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d33438", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ea464f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>1</td>
        </tr>
        <tr>
          <td>Violet F-R</td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#382950", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#714e9e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>3</td>
        </tr>
        <tr>
          <td>Navy Blue F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#31396a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#346db4", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Blue F-G</td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#364684", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#3172b6", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Blue F-N</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#34457b", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#3172b6", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Turq. Blue F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#2d4d76", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#258cb9", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Green F-G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#07876e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#2bb6a1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Green F-2G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#0d954d", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#4ebc8f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
        </tr>
        <tr>
          <td>Olive Green F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#314431", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#6c9666", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>3-4</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Brown F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#8e4a33", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#bb793c", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>3</td>
          <td style={{ textAlign: "center" }}>3-4</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Black F-K</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#282828", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#373330", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
        </tr>
        <tr>
          <td>Black F-N</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#282828", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#433f3c", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
        </tr>
      </tbody>
    </table>
</div>

    <ScrollMenu>
    <div className="table1">
    <table
      border={1}
      className="table table-bordered"
      style={{ color: "black" }}
    >
      <tbody>
        <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
          <td style={{ width: "15%" }}>Product Name</td>
          <td className="text-center" colSpan={2}>
            Screen Print On Cotton Fabrics
          </td>
          <td className="text-center" style={{ width: "10%" }}>
            Printing Concentration (Reducation 1:4) g/kg s{" "}
          </td>
          <td className="text-center" colSpan={3}>
            Light Fastness Xenon test
          </td>
          <td className="text-center">Wash Fastness 950 C</td>
          <td className="text-center">Fastness to hypoclorite Severe</td>
          <td className="text-center" colSpan={2}>
            Perpriration Fastness
          </td>
          <td className="text-center">Fastness to Dry</td>
        </tr>
        <tr style={{ textCenter: "center" }}>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>4 %</td>
          <td style={{ textAlign: "center" }}>1 %</td>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>1/6 SD</td>
          <td style={{ textAlign: "center" }}>1/3 SD</td>
          <td style={{ textAlign: "center" }}>1/1 SD</td>
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }} />
          <td style={{ textAlign: "center" }}>Acidic</td>
          <td style={{ textAlign: "center" }}>Alkaline</td>
          <td style={{ textAlign: "center" }} />
        </tr>
        <tr>
          <td>Red F-M</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#d33438", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#ea464f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>1</td>
        </tr>
        <tr>
          <td>Violet F-R</td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#382950", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#714e9e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>3</td>
        </tr>
        <tr>
          <td>Navy Blue F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#31396a", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#346db4", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Blue F-G</td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#364684", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>
            <div
              className="color_div"
              style={{ backgroundColor: "#3172b6", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Blue F-N</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#34457b", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#3172b6", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>6-7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Turq. Blue F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#2d4d76", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#258cb9", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
        </tr>
        <tr>
          <td>Green F-G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#07876e", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#2bb6a1", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Green F-2G</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#0d954d", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#4ebc8f", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
        </tr>
        <tr>
          <td>Olive Green F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#314431", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#6c9666", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>3-4</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5-6</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Brown F-R</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#8e4a33", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#bb793c", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>3</td>
          <td style={{ textAlign: "center" }}>3-4</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>2-3</td>
        </tr>
        <tr>
          <td>Black F-K</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#282828", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#373330", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
        </tr>
        <tr>
          <td>Black F-N</td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#282828", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td>
            <div
              className="color_div"
              style={{ backgroundColor: "#433f3c", textAlign: "center" }}
            >
              &nbsp;
            </div>
          </td>
          <td style={{ textAlign: "center" }}>20</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>7-8</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>4-5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
          <td style={{ textAlign: "center" }}>5</td>
        </tr>
      </tbody>
    </table>
    </div>
   </ScrollMenu>
  </div>
  {/* <h3
    style={{
      textAlign: "center",
      fontSize: 49,
      padding: 24,
      color: "red",
      textDecoration: "underline"
    }}
  >
    Home &amp; Personal care
  </h3> */}
  {/* <div className="row">
    <div className="col-lg-12">
      <h3 style={{ textAlign: "center" }}>Coming Soon</h3>
    </div>
  </div> */}
</section>

          
       
          {/* <div className="container-fluid py-5">
          <div className="container">
          
          <div className="heading">
              
                
                <h2 className="h1" style={{textAlign:"center"}}>
             <strong>Fine Paste</strong>{" "}
                </h2>
              </div>
            <div className="row gx-0 mb-3 mb-lg-0 mt-5">
              <h3></h3>
              <ScrollMenu>
              <table border={1} className="table table-bordered mb-5">
                <tbody>
                  <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
                    <td
                      rowSpan={2}
                      className="text-center"
                      style={{ width: "13%", verticalAlign: "middle" }}
                    >
                      Shade A [4%]
                    
                    </td>
                    
                    <td rowSpan={2} style={{ width: "13%", verticalAlign: "middle" }}>
                    Shade B [1%]
                   
                    </td>
                    <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
                    Microfast Fine Paste
                    </td>
                    <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
                      C.I. Name
                    </td>
                    <td colSpan={2} className="text-center">
                     Light Fastness
                    </td>
                    <td colSpan={2} className="text-center">
                     Weathering Fastness
                    </td>
                    
                  </tr>
                  <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
                    <td className="text-center">4%</td>
                    <td className="text-center">1%</td>
                    <td className="text-center">4%</td>
                    <td className="text-center">1%</td>
                  </tr>
                
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#faed22", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td> <div
                        className="color_div"
                        style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Yellow 5511</td>
                    <td className="text-center">  P.Y. 3</td>
                    <td className="text-center">
                    7
                    </td>
                    <td className="text-center">
                    6
                    </td>
                    <td className="text-center">
                   4
                    </td>
                    <td className="text-center">
                   3
                   </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#fde521", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#fcf290", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Yellow 5509</td>
                    <td className="text-center">P.Y.74</td>
                    <td className="text-center">
                    7
                    </td>
                    <td className="text-center">
                    6
                    </td>
                    <td className="text-center">
                   4
                    </td>
                    <td className="text-center">
                   3
                   </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#fde521", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td> <div
                        className="color_div"
                        style={{ backgroundColor: "#fdf6b0", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                      <td>Yellow 5155</td>
                    <td className="text-center">P.Y. 154</td>
                    <td className="text-center">
                    8
                    </td>
                    <td className="text-center">
                    8
                    </td>
                    <td className="text-center">
                   5
                    </td>
                    <td className="text-center">
                   5
                   </td>
                  
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#fcde24", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td> <div
                        className="color_div"
                        style={{ backgroundColor: "#feeb8c", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                      <td>Yellow 5517</td>
                    <td className="text-center">P.Y. 1</td>
                    <td className="text-center">
                    7
                    </td>
                    <td className="text-center">
                    6
                    </td>
                    <td className="text-center">
                   4
                    </td>
                    <td className="text-center">
                   3
                   </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#fdcb2a", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td> <div
                        className="color_div"
                        style={{ backgroundColor: "#fce33e", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Yellow 5512</td>
                    <td className="text-center">P.Y.83</td>
                    <td className="text-center">
                     7
                    </td>
                    <td className="text-center">
                     6
                    </td>
                    <td className="text-center">
                     4
                    </td>
                    <td className="text-center">
                     3
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#ef6733", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f8a07a", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Orange 5535</td>
                    <td className="text-center">P.o.s</td>
                    <td className="text-center">
                     6
                    </td>
                    <td className="text-center">
5
                    </td>
                    <td className="text-center">
                     3-4
                    </td>
                    <td className="text-center">
                    2
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#f27036", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f68e4f", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Orange 5536</td>
                    <td className="text-center">P.O. 13</td>
                    <td className="text-center">
                     6
                    </td>
                    <td className="text-center">
5
                    </td>
                    <td className="text-center">
                     4-5
                    </td>
                    <td className="text-center">
                    2
                    </td>
                  
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#f04f79", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f36ea7", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 5551</td>
                    <td className="text-center">P.R.8</td>
                    <td className="text-center">6-7</td>
                    <td className="text-center">
                     5
                    </td>
                    <td className="text-center">
                     3
                    </td>
                    <td className="text-center">
                     2-3
                    </td>
                  
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#df4581", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#e878b0", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 5551</td>
                    <td className="text-center">P.R.122</td>
                    <td className="text-center">7-8</td>
                    <td className="text-center">
                      7-8
                    </td>
                    <td className="text-center">4</td>
                    <td className="text-center">
                      4-5
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#ee456e", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f1639b", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 5546</td>
                    <td className="text-center">P.R. 146</td>
                    <td className="text-center">7-8</td>
                    <td className="text-center">
                    6-7
                    </td>
                    <td className="text-center">4</td>
                    <td className="text-center">
                    4-5
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#ec2d61", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f486b3", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 5547</td>
                    <td className="text-center">P.V.19</td>
                    <td className="text-center">7-8</td>
                    <td className="text-center">
                     6-7
                    </td>
                    <td className="text-center">4</td>
                    <td className="text-center">
                     4
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#ed4344", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f0557d", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 5550</td>
                    <td className="text-center">P.R. 210</td>
                    <td className="text-center">7-8</td>
                    <td className="text-center">
                     7
                    </td>
                    <td className="text-center">
                     4-5
                     </td>
                     <td className="text-center">
                     4
                     </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#ee3135", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f2707a", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 5548</td>
                    <td className="text-center">P.R. 112</td>
                    <td className="text-center">7-8</td>
                    <td className="text-center">
                     7
                    </td>
                    <td className="text-center">4</td>
                    <td className="text-center">
                     3
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#ee3135", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#e86e79", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 5555</td>
                    <td className="text-center">P.R. 254</td>
                    <td className="text-center">8</td>
                    <td className="text-center">
                     8
                    </td>
                    <td className="text-center">4</td>
                    <td className="text-center">
                     4
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#ee3135", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f99892", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 5549</td>
                    <td className="text-center">P.R. 3</td>
                    <td className="text-center">7</td>
                    <td className="text-center">
                     3
                    </td>
                    <td className="text-center">4</td>
                    <td className="text-center">
                     2
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#ee3135", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f27b97", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 5554</td>
                    <td className="text-center">P.R. 170</td>
                    <td className="text-center">6-7</td>
                    <td className="text-center">
                     4
                    </td>
                    <td className="text-center">4</td>
                    <td className="text-center">3
                     
                    </td>
                  
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#3a3168", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#8781bf", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Violet 5591</td>
                    <td className="text-center">P.V. 23</td>
                    <td className="text-center">8</td>
                    <td className="text-center">
                    7-8
                    </td>
                    <td className="text-center">4-5</td>
                    <td className="text-center">
                  4
                    </td>
                  </tr>
                 
                </tbody>
              </table>
              </ScrollMenu>

              <ScrollMenu>

              <table border={1} className="table table-bordered mb-5">
                <tbody>
                  <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
                    <td
                      rowSpan={2}
                      className="text-center"
                      style={{ width: "13%", verticalAlign: "middle" }}
                    >
                      Shade A [4%]
                    </td>
                    <td rowSpan={2} style={{ width: "13%", verticalAlign: "middle" }}>
                    Shade B [1%]
                    </td>
                    <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
                    Microfast Fine Paste
                    </td>
                    <td rowSpan={2} style={{ width: "15%", verticalAlign: "middle" }}>
                      C.I. Name
                    </td>
                    <td colSpan={2} className="text-center">
                     Light Fastness
                    </td>
                    <td colSpan={2} className="text-center">
                     Weathering Fastness
                    </td>
                    
                  </tr>
                  <tr style={{ backgroundColor: "#FFE468", color: "black" }}>
                    <td className="text-center">4%</td>
                    <td className="text-center">1%</td>
                    <td className="text-center">4%</td>
                    <td className="text-center">1%</td>
                  </tr>
                
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#30315f", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td> <div
                        className="color_div"
                        style={{ backgroundColor: "#488ecc", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Blue 5612</td>
                    <td className="text-center">P.B. 15:0</td>
                    <td className="text-center">
                    7-8
                    </td>
                    <td className="text-center">
                    6-7
                    </td>
                    <td className="text-center">
                    4-5
                    </td>
                    <td className="text-center">
                   4
                   </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#33487d", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#f4f4f8", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Blue 5615</td>
                    <td className="text-center">P.B.15:3</td>
                    <td className="text-center">
                    8
                    </td>
                    <td className="text-center">
                    7-8
                    </td>
                    <td className="text-center">
                   5
                    </td>
                    <td className="text-center">
                   4-5
                   </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#0b5b40", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td> <div
                        className="color_div"
                        style={{ backgroundColor: "#5fc5af", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                      <td>Green 5631</td>
                    <td className="text-center">P.G. 7</td>
                    <td className="text-center">
                    8
                    </td>
                    <td className="text-center">
                   7-8
                    </td>
                    <td className="text-center">
                   5
                    </td>
                    <td className="text-center">
                  4-5
                   </td>
                  
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#2c2c2c", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td> <div
                        className="color_div"
                        style={{ backgroundColor: "#62696f", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                      <td>Black 5683</td>
                    <td className="text-center">P.BL. 7</td>
                    <td className="text-center">
                    8
                    </td>
                    <td className="text-center">
                    8
                    </td>
                    <td className="text-center">
                   5
                    </td>
                    <td className="text-center">
                  5
                   </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#e6a139", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td> <div
                        className="color_div"
                        style={{ backgroundColor: "#f7e4bf", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Yellow 531</td>
                    <td className="text-center">P.Y. 42</td>
                    <td className="text-center">
                     8
                    </td>
                    <td className="text-center">
                     8
                    </td>
                    <td className="text-center">
                     5
                    </td>
                    <td className="text-center">
                     5
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className="color_div"
                        style={{ backgroundColor: "#a65635", textAlign: "center" }}
                      >
                        &nbsp;
                      </div>
                    </td>
                    <td><div
                        className="color_div"
                        style={{ backgroundColor: "#cc7d76", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></td>
                    <td>Red 530</td>
                    <td className="text-center">P.R. 101</td>
                    <td className="text-center">
                     8
                    </td>
                    <td className="text-center">
8
                    </td>
                    <td className="text-center">
                    5
                    </td>
                    <td className="text-center">
                    5
                    </td>
                  </tr>
              
                
                
                </tbody>
              </table>

              </ScrollMenu>
<div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1" style={{textAlign:"center"}}>
             <strong>Soaps And Detergents</strong>{" "}
                </h2>
              </div>
              <ScrollMenu>
<table class="table table-bordered">
  <thead>
    <tr>
     
      <th scope="col">Sample</th>
      <th scope="col">Detergents</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th></th>
      <td>PIGMENT POWDER</td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#33487d", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Blue Sperse</td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#0b5b40", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Green Sperse </td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>COLORANTS</td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#488ecc", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Blue	5612</td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#62a9cc", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Blue	5613</td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#5dc4ad", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Green	5631</td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#87cfb6", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Green	5632</td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#b4d440", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Green	 5634 </td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#fcfffe", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Red 5551 </td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#f486b3", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Pink	5547 </td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#795ba1", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Violet	6591 </td>
     
    </tr>
    <tr>
      <th><div
                        className="color_div"
                        style={{ backgroundColor: "#fcde24", textAlign: "center" }}
                      >
                        &nbsp;
                      </div></th>
      <td>Microfast Yellow	5517 </td>
     
    </tr>
    <tr>
      <th></th>
      <td>Microfast White </td>
     
    </tr>
  
  </tbody>
</table>
</ScrollMenu>
            </div>
          </div>
          <br/>
          <br/><br/><br/>
        </div> */}
        </>
        
        
        );
    }
}


export  default  Emulsion;