import React from 'react'

import {Link} from 'react-router-dom'
import pic from '../topnav/logos.png'




const Footer = () => {
  return (



<>
  <div className="hangout_bg">
    <div className="container">
      <div className="hang_blk">
        <img
          src="assets/images/icons/hangout.png"
          width={54}
          height={63}
          alt="hangout"
        />{" "}
        Online chating add us on :{" "}
        <strong>
          <em> info@rivomax.in</em>
        </strong>
        <strong />
      </div>
    </div>
  </div>
  <div id="footer">
    <div className="container">
      <div className="contacts">
        <div className="row">
          <div className="col-sm-4">
            <div className="icon">
              <i className="fa fa-map-o" />
            </div>
            <h5>Visit Us</h5>
            <p>
              Rivomax
              <br />
              806, Topiwala Center, Off S.V.Road,
              <br />  Goregaon (W), Mumbai, Maharashtra 400062
            </p>
          </div>
          <div className="col-sm-4">
            <div className="icon">
              <i className="fa fa-envelope-o" />
            </div>
            <h5>Email Us</h5>
            <p>
            info@rivomax.in
              <br />
             
            </p>
          </div>
          <div className="col-sm-4">
            <div className="icon">
              <i className="fa fa-television" />
            </div>
            <h5>Call Us</h5>
            <p>
            022-40160923
              <br />
              +91 9920974892
            </p>
          </div>
        </div>
      </div>
      <div className="row footerlinks">
        <div className="col-sm-4">
          {/* <h3 style={{color:"white"}}><strong>Ware House Address : </strong></h3> */}
          <div className="row">
            <div className="col-sm-7">
        
            <img style={{width:"120px", height:"160px"}}  src={pic} alt="" />
          
            </div>
         
          </div>
        </div>
        <div className="col-sm-4">
          <h3 style={{color:"white"}}><strong>Navigation</strong></h3>
          <div className="row">
            <div className="col-sm-6">
              <ul className="flist navigation">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/our-profile"> Profile</Link>
                </li>
                <li>
                  <Link to="/pigment"> Products</Link>
                </li>
                <li>
                  <Link to="/industries-and-applications">
                    {" "}
                    Industries &amp; Applications
                  </Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-6">
              <ul className="flist navigation">
                <li>
                  <Link to="/career"> Careers</Link>
                </li>
                <li>
                  <Link to="/reach-us"> Reach us</Link>
                </li>
                <li>
                  <Link to="/contact-us"> Contact us</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="sm">
           
            
           
           <div>
           <Link to="https://www.linkedin.com/company/rivomax-industries-pvt-ltd/" target="_blank">Find us on Linkedin
            <img className="linkd" src='/assets/images/sssddd.png' alt='' style={{width: "80px", height: "70px"}} />
            </Link> 
           </div>
           
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="copyright">
    <div className="container">
      <div className="row">
        <div className="col-sm-7">
          Copyright © 2023 Rivomax. All rights reserved.
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{" "}
        |{" "}
          <Link to="privacy-policy.html">Privacy Policy</Link>
        </div>
      </div>
    </div>
  </div>
  <div id="capitol-callback">
  <div className="cpt-circle"></div>
  <div className="cpt-circle-fill"></div>
  <a href="https://wa.me/919920974892" id="WhatsAppBtnDesktop" target="_blank" class="main-button" lang="en">
    <img src="https://nhtagent.com/nht-upload/assets/javascripts/WhatsApp/WhatsApp.png" width="50%" />
  </a>
</div>
</>


    );
    }


export default Footer;
