import React from "react";
import {Link} from 'react-router-dom'




class PVCProfile extends React.Component{
    render(){
        return(
       
          <main className="application-page">
        


          <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">PVC Profile Application</h1>
                  <br />
                  <br />
                 
                </div>
                <div className="product-img">
                  <picture>
                    <source
                    
                      srcSet="/assets/images/pvc-profiles-500x500ss.webp"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0 mt-9">
                      About Application:
                    </h4>
                    <br />
                  
                    <div className="product-description">
                      <p>
                        PVC is being extensively used for window profiles, technical
                        profiles and door profiles due to excellent weathering
                        resistance, impact strength and durability. PVC gives
                        outstanding insulation for heat and noise, and has a very low
                        maintenance cost.
                      </p>
                      <p>
                        Goldstab offers the lead-based and lead-free type of stabilizers
                        that impart excellent heat and UV resistance to PVC profiles.
                        Goldstab stabilizer system provides higher mechanical strength,
                        excellent whiteness and color stability. The one pack system is
                        specially designed with excellent quality additives to ensure
                        smooth extrusion with good luster and finish consistently.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>


          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
             
          
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Calcium Based Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Code
                      </th>
                      <th scope="col" className="text-center">
                        Key features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={2}>Window Profile</td>
                      <td>Goldstab CZ 55008WP</td>
                      <td rowSpan={2}>
                        Perfect balance of heat stability and lubrication, versatile in
                        use for all dimensions and cross sections of profiles. Gives
                        good surface finish, gloss and color.
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 55009WP</td>
                    </tr>
                    <tr>
                      <td>SPC Flooring</td>
                      <td>Goldstab CZ 5209</td>
                      <td>Good heat stability and gloss</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Edge band, PVC Laminates/ Sliding</td>
                      <td>Goldstab CZ 5213</td>
                      <td rowSpan={2}>For better mechanical properties</td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 56001</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="table-note gray-text">
                Note: Various other grades available to suit specific needs of customers
              </p>
            
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Lead Based One Pack Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Code
                      </th>
                      <th scope="col" className="text-center">
                        Key features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={3}>Window profile</td>
                      <td>GOLDSTAB - 2177 WP</td>
                      <td rowSpan={2}>
                        Reduces plate-out effect and provides great brightness ,
                        whiteness index &amp; a very rich feel finish to the profile
                      </td>
                    </tr>
                    <tr>
                      <td>GOLDSTAB - 2180 WP</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3051 KP</td>
                      <td>Good heat stability and lubrication for longer run</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Door profile</td>
                      <td>GOLDSTAB - 2020 KP</td>
                      <td>High lead content for better heat stability</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3051 KP</td>
                      <td>Good heat stability and lubrication for longer run</td>
                    </tr>
                    <tr>
                      <td>PVC Blinds</td>
                      <td>Goldstab 3051 N</td>
                      <td>For brighter finishes</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Edge band, PVC Laminates/ Sliding</td>
                      <td>Goldstab 2020 N</td>
                      <td rowSpan={2}>
                        Provides lubrication and good asthetic properties
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 3182</td>
                    </tr>
                  </tbody>
                </table>
              </div>
           
            </div>
          </section>
        
        </main>
        
        
        );
    }
}


export  default  PVCProfile;