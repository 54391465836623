import React from "react";
import {Link} from 'react-router-dom'




class Footwear extends React.Component{
    render(){
        return(
          
          <main className="application-page">
        
          <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Footwear</h1>
                  <br />
                  <br />
                 
                </div>
                <div className="product-img">
                  <picture>
                    <source
                      
                      srcSet="/assets/images/Neemans-had-raised-footwear-min.jpg"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0 mt-9">
                      About Application:
                    </h4>
                    <br />
                  
                    <div className="product-description">
                      <p>
                        PVC is used for making various types of footwear mainly due to
                        the versatile nature of the polymer. In some cases colored or
                        colorless compounds are processed with the use of only
                        plasticizers to make transparent footwear straps. Goldstab
                        offers solid and liquid stabilizers that provide high
                        transparency and non-yellowing properties to the compounds. In
                        other cases opaque compounds are made to manufacture hard base
                        for floaters. Here, uniform dispersion of all additives and the
                        mechanical properties of the final article along with aesthetics
                        are the most important factors. Goldstab offers formulations
                        with right balance of lubrication and stabilization to give the
                        best performance while processing.
                      </p>
                      <p>
                        In last type PVC is used to manufacture high quality sport shoes
                        with foaming agents as additive in compound to make lightweight
                        yet tough material. The ingredients in stabilizer also affect
                        the “kicking temperature” of the foaming agent which determines
                        overall quality of the foamed article. Special lubricants added
                        in the formulation affect the cell structure and are responsible
                        for uniform dispersion of gas in the matrix.
                      </p>
                      <p>
                        Goldstab liquid mixed metals are available in both Cadmium based
                        and Cadmium free forms. We also provide non-toxic, solid Barium
                        and Zinc based stabilizers for flexible applications to match
                        the needs of customers.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>


          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
             
            <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Mixed Metal Powder Stabilizer</strong>{" "}
                </h2>
              </div>
           
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td>PVC Shoe soles</td>
                      <td>Goldstab 4014 VP</td>
                      <td>General purpose stabilizer for reprocessed material</td>
                    </tr>
                    <tr>
                      <td>Transparent Footwear</td>
                      <td>Goldstab 6130</td>
                      <td>Excellent color hold and transparency</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Opaque Flexible Moulds</td>
                      <td>Goldstab 6140</td>
                      <td>Moderate heat stability</td>
                    </tr>
                    <tr>
                      <td>Goldstab 6141</td>
                      <td>Excellent whiteness and good heat stability</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Foaming Compound</td>
                      <td>Goldstab 6142</td>
                      <td>Good heat stability &amp; transparency</td>
                    </tr>
                    <tr>
                      <td>Goldstab 6160</td>
                      <td>Excellent heat stability &amp; transparency</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="table-note gray-text">
                Note: Various other grades available to suit specific needs of customers
              </p>
            
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Liquid Mixed Metal Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td className="align-middle" rowSpan={4}>
                        Footwear
                      </td>
                      <td>Goldstab 812</td>
                      <td>
                        Premium stabilizer with excellent heat stability &amp;
                        transparency
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 830</td>
                      <td rowSpan={2}>
                        Modearte heat stability and higher clarity and transparency
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 864</td>
                    </tr>
                    <tr>
                      <td>Goldstab 891</td>
                      <td>
                        Premium stabilizer for good heat stability and transparency
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div class="d-flex align-items-end justify-content-end">
                  <a
                      href="https://drive.google.com/u/0/uc?id=1sPM4Ah2_RWlL9_wVcZsMwqH61RooEaXb&export=download"
                      download="Brochure"
                      class=" download-brochure-link"
                      >Download Application Brochure
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 24 24"
                          class="download-brochure-icon"
                          viewBox="0 0 24 24"
                          fill="#1a1c80"
                      >
                          <g><rect fill="none" height="24" width="24" /></g>
                          <g>
                              <path
                                  d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"
                              />
                          </g></svg
                  ></a>
              </div> */}
            </div>
          </section>
          {/* <section class="testimonial-section pt-5 pb-5 overflow-hidden">
          <div class="pt-5 pb-5">
              <div class="testimonial-slider-container ">
                  <div class="slider-arrows-container">
                      <div class="prev-arrow testimonial-prev-arrow slick-slider-arrow">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.666"
                              height="17.342"
                              class="solution-left-arrow slick-left-arrow"
                              viewBox="0 0 20.666 17.342"
                          >
                              <g transform="translate(1 1.414)">
                                  <path
                                      class="slick-a"
                                      d="M-20219-20684l7.408,7.291-7.408,7.223"
                                      transform="translate(20230.258 20684)"
                                  />
                                  <line
                                      class="slick-b"
                                      x1="18.527"
                                      transform="translate(0 7.268)"
                                  />
                              </g>
                          </svg>
                      </div>
                      <div class="next-arrow testimonial-next-arrow slick-slider-arrow">
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.666"
                              height="17.342"
                              class="solution-right-arrow slick-right-arrow"
                              viewBox="0 0 20.666 17.342"
                          >
                              <g transform="translate(1 1.414)">
                                  <path
                                      class="slick-a"
                                      d="M-20219-20684l7.408,7.291-7.408,7.223"
                                      transform="translate(20230.258 20684)"
                                  />
                                  <line
                                      class="slick-b"
                                      x1="18.527"
                                      transform="translate(0 7.268)"
                                  />
                              </g>
                          </svg>
                      </div>
                  </div>
                  <div class="testimonial-slider d-flex justify-content-center">
                      <div
                          class="testimonial-slider-element card d-flex flex-md-row justify-content-between shadow-lg"
                      >
                          <picture>
                              <source
                                  media="(min-width:426px)"
                                  srcset="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaD.png"
                              />
                              <img
                                  class="testimonial-image"
                                  src="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaM.png"
                                  alt="viren Mehta"
                              />
                          </picture>
                          <div
                              class="testimonial-slider-content d-flex flex-column justify-content-center"
                          >
                              <h4 class="person-name blue-text font-24 mb-0">
                                  Viren Mehta
                              </h4>
                              <p class="company-name font-14 mb-0">
                                  Nilkamal Pvt. Ltd.
                              </p>
                              <p class="person-content gray-text font-16">
                                  Goldstab Stabiliser are highly Effective and
                                  Strong. We are using there product form last 10
                                  years.
                              </p>
                          </div>
                      </div>
                      <div
                          class="testimonial-slider-element card d-flex flex-md-row justify-content-between shadow-lg"
                      >
                          <picture>
                              <source
                                  media="(min-width:426px)"
                                  srcset="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaD.png"
                              />
                              <img
                                  class="testimonial-image"
                                  src="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaM.png"
                                  alt="viren Mehta"
                              />
                          </picture>
                          <div
                              class="testimonial-slider-content d-flex flex-column justify-content-center"
                          >
                              <h4 class="person-name blue-text font-24 mb-0">
                                  Viren Mehta
                              </h4>
                              <p class="company-name font-14 mb-0">
                                  Nilkamal Pvt. Ltd.
                              </p>
                              <p class="person-content gray-text font-16">
                                  Goldstab Stabiliser are highly Effective and
                                  Strong. We are using there product form last 10
                                  years.
                              </p>
                          </div>
                      </div>
                      <div
                          class="testimonial-slider-element card d-flex flex-md-row justify-content-between shadow-lg"
                      >
                          <picture>
                              <source
                                  media="(min-width:426px)"
                                  srcset="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaD.png"
                              />
                              <img
                                  class="testimonial-image"
                                  src="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaM.png"
                                  alt="viren Mehta"
                              />
                          </picture>
                          <div
                              class="testimonial-slider-content d-flex flex-column justify-content-center"
                          >
                              <h4 class="person-name blue-text font-24 mb-0">
                                  Viren Mehta
                              </h4>
                              <p class="company-name font-14 mb-0">
                                  Nilkamal Pvt. Ltd.
                              </p>
                              <p class="person-content gray-text font-16">
                                  Goldstab Stabiliser are highly Effective and
                                  Strong. We are using there product form last 10
                                  years.
                              </p>
                          </div>
                      </div>
                      <div
                          class="testimonial-slider-element card d-flex flex-md-row justify-content-between shadow-lg"
                      >
                          <picture>
                              <source
                                  media="(min-width:426px)"
                                  srcset="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaD.png"
                              />
                              <img
                                  class="testimonial-image"
                                  src="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaM.png"
                                  alt="viren Mehta"
                              />
                          </picture>
                          <div
                              class="testimonial-slider-content d-flex flex-column justify-content-center"
                          >
                              <h4 class="person-name blue-text font-24 mb-0">
                                  Viren Mehta
                              </h4>
                              <p class="company-name font-14 mb-0">
                                  Nilkamal Pvt. Ltd.
                              </p>
                              <p class="person-content gray-text font-16">
                                  Goldstab Stabiliser are highly Effective and
                                  Strong. We are using there product form last 10
                                  years.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
            </section> */}
     
        </main>
        
        );
    }
}


export  default  Footwear;