import React from "react";
// import {Link} from 'react-router-dom'




class Biocides extends React.Component{
    render(){
        return(
          <>
   

   <section className="page">
  <div className="pageheader">
  
    <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1" style={{textAlign:"center"}}>
             <strong>Paints &amp; Coatings Biocides</strong>{" "}
                </h2>
              </div>
  </div>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
        <p>
          With goal in mind of "Safe Preservative for Sure Protection", Rivomax
          Industries Pvt. Ltd., introduces complete range of Paint / coating
          preservatives under the brand <strong></strong> to protect
          products during manufacturing, storage, transport and post
          applications, against microbial contamination and attack.
        </p>
        <p>
          Since microorganisms occur everywhere, their destructive activity is
          unavoidable. When stored in the container the microbial growth in
          water-based coating systems causes gassing, discoloration, viscosity
          change, emulsion breakdown, and bad odor. Post application the dry
          paint films are attacked by Fungi and Algae causing discoloration and
          disfiguration thus affecting the aesthetic and protective functions of
          the paint / coatings.
        </p>
        <p>
          <strong></strong> range of preservatives (In-Can as well as Dry
          film) will help you to prevent these undesired deterioration effects
          due to microbial growth in your products as <strong></strong>{" "}
          preservatives are tailored and made Exactly for your Product
          Protection.
        </p>
      </div>
      <div className="col-md-2 text-center">
        <div className="card bg-light">
         
        </div>
      </div>
    </div>
    <p>
      Rivomax Industries Pvt. Ltd. believes in quality products and therefore is
      equipped with latest machinery, highly skilled manpower and modern testing
      laboratories. We are continuously investing in Research and Development to
      serve our customers with quality products tailored to meet their specific
      requirements.
    </p>
    <p>
      The company's marketing team provides its customers their value for money
      and our products are marketed with assured after-sales technical service
      by our experts from Paint and Biocide industries.
    </p>
    <h4 className="h6 titleTop text-medium text-uppercase">
       Paint Preservatives Of International Quality To Choose From,
    </h4>
    <h4 className="h4 titleTop text-danger text-medium text-uppercase">
      {" "}
       – Dry Film Preservatives
    </h4>
    <p>
      A dry film preservative is a substance that is added to paints / coatings
      to prevent decomposition and disfiguration of dry fims of paints due to
      microbial attack and growth on painted surfaces.
    </p>
    <div className="table-responsive">
      <table
        border={0}
        cellSpacing={0}
        cellPadding={0}
        width="100%"
        className="table table-bordered table-stripped"
      >
        <thead className="bg-primary text-white">
          <tr>
            <th width="22%" scope="col">
              Product
            </th>
            <th width="36%" scope="col">
              Active Component
            </th>
            <th width="42%" scope="col">
              Key Performance Benefits
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong> DCT</strong>
            </td>
            <td>Combination of benzimidazolecarbamate, (OIT) and Diuron</td>
            <td>
              High performance, broad spectrum combination of Algaecide &amp;
              Fungicide for exterior paints, coatings, stains and similar
              products
            </td>
          </tr>
          <tr>
            <td>
              <strong> DD 50</strong>
            </td>
            <td>Aqueous of Diuron dispersion</td>
            <td>An excellent high active, high performing algaecide</td>
          </tr>
          <tr>
            <td>
              <strong> DC 40</strong>
            </td>
            <td>Aqueous dispersion of Benzimidazolecarbamate</td>
            <td>Cost effective, high active specialized Fungicide</td>
          </tr>
          <tr>
            <td>
              <strong> DCO</strong>
            </td>
            <td>Glycol based combination of Benzimidazolecarbamate and OIT</td>
            <td>High performance cost effective combination of fungicides</td>
          </tr>
          <tr>
            <td>
              <strong> DF 15</strong>
            </td>
            <td>
              Glycol based solution of 2-octyl-2H-isothiazolin-3-one (OIT)
            </td>
            <td>
              A fungicide for water and solvent based interior paints and
              coatings
            </td>
          </tr>
          <tr>
            <td>
              <strong> DZ 40</strong>
            </td>
            <td>Stabilized formulation of Zinc Pyrithione</td>
            <td>
              A high active broad spectrum antimicrobial with excellent heat and
              pH stability; low water solubility; providing long term protection
            </td>
          </tr>
          <tr>
            <td>
              <strong> DDZ</strong>
            </td>
            <td>Stabilized aqueous dispersion of Zinc Pyrithione and Diuron</td>
            <td>Broad spectrum protection against algae and fungi</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="clearfix" />
    <h4 className="h4 titleTop text-danger text-medium text-uppercase">
       In-Can Preservatives
    </h4>
    <p>
      An in-can preservative is a substance that is added to paints / coatings
      to prevent wet state decomposition and fouling of paints / coatings due to
      excessive bacterial growth leading to undesirable chemical and physical
      changes.
    </p>
    <div className="table-responsive">
      <table
        border={0}
        cellSpacing={0}
        cellPadding={0}
        width="100%"
        className="table table-bordered table-stripped"
      >
        <thead className="bg-primary text-white">
          <tr>
            <th width="22%" scope="col">
              Product
            </th>
            <th width="36%" scope="col">
              Active Component
            </th>
            <th width="42%" scope="col">
              Key Performance Benefits
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong> IM 15</strong>
            </td>
            <td>Combination of (Chloromethyl/methyl isothiazolinone)</td>
            <td>
              An effective bacteriacide &amp; fungicide offering quick kill and
              long lasting efficacy; formaldehyde free
            </td>
          </tr>
          <tr>
            <td>
              <strong> IMF</strong>
            </td>
            <td>
              Combination of Chloromethyl/methyl isothiazolinone and
              Formaldehyde donor
            </td>
            <td>
              An effective and economical bacteriacide &amp; fungicide with head
              space protection
            </td>
          </tr>
          <tr>
            <td>
              <strong> IB 20</strong>
            </td>
            <td>Glycol based formulation of Benzisothiazolinone</td>
            <td>
              Broad spectrum activity; Heat stable and effective over pH range
              of 4 to 12; formaldehyde free
            </td>
          </tr>
          <tr>
            <td>
              <strong> IBM</strong>
            </td>
            <td>Methyl/Benz-isothiazolinone</td>
            <td>
              Cost effective solvent free synergistic preservative suitable for
              formaldehyde sensitive systems
            </td>
          </tr>
          <tr>
            <td>
              <strong> IBR</strong>
            </td>
            <td>
              Combination of Chloromethylmethylisothiazolinone and Bronopol
            </td>
            <td>Rapid acting biocide having broad activity spectrum</td>
          </tr>
          <tr>
            <td>
              <strong> IB 20P</strong>
            </td>
            <td>Aqueous dispersion of Benzisothiazolinone</td>
            <td>
              Broad spectrum activity; Heat stable and effective over pH range
              of 4 to 12; formaldehyde free
            </td>
          </tr>
          <tr>
            <td>
              <strong> IBZ</strong>
            </td>
            <td>
              Aqueous dispersion of Benzisothiazolinone and Zinc Pyrithione
            </td>
            <td>
              Enhanced antifungal activity and very effective at low
              concentrations offering benefits similar to  IB 20
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="clearfix" />
    <h4 className="h4 titleTop text-medium text-uppercase">
      Defoaming And Antifoaming Agents
    </h4>
    <div className="row">
      <div className="col-md-8">
        <p>
          The process of producing paints is complex. It involves an intense
          mixing of components particularly at the stage of grinding pigments
          and fillers. Foaming properties of some components are the reasons for
          generating foam, which results in a series of technical problems
          during the production process of the paint and its packaging.
        </p>
        <p>
          Applying an appropriately-selected defoaming agent is the guarantee of
          getting high quality coats and avoiding problems during the production
          process of the paint. The selected Defoamers should remove the air
          from pigments and filler fast and efficiently counteract foaming
          during dosing and distribution of different components in the setting.
          Hence Rivomax offers two main types of Defoamers for Paint
          application.
        </p>
      </div>
      <div className="col-md-2 text-center">
        <img
          className="w100 img-thumbnail"
          src="assets/images/paint-film-without-defoamer.jpg" alt="rivomax"
        />
        <strong>Paint film without defoamer having fish eyes.</strong>
      </div>
      <div className="col-md-2 text-center">
        <img
          className="w100 img-thumbnail"
          src="assets/images/uniform-paint-film-with-defoamer.jpg" alt="rivomax"
        />
        <strong>Uniform paint film with defoamer</strong>
      </div>
    </div>
    <div className="clearfix" />
    <h4 className="h4 titleTop text-medium text-uppercase">
      Mineral Oil Based Defoamers
    </h4>
    <div className="table-responsive">
      <table
        border={0}
        cellSpacing={0}
        cellPadding={0}
        width="100%"
        className="table table-bordered table-stripped"
      >
        <thead className="bg-primary text-white">
          <tr>
            <th width="20%" scope="col">
              Product Name
            </th>
            <th width="20%" scope="col">
              Chemical Type
            </th>
            <th width="25%" scope="col">
              Composition
            </th>
            <th width="35%" scope="col">
              Application
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>PC DKW</td>
            <td>Non-Silicone</td>
            <td>Blend of hydrocarbons with fatty acid esters</td>
            <td>
              In emulsion paints based on styrene butadiene, acrylic, PVC &amp;
              its copolymers, EVA, vinylidine chlorides and water soluble alkyds
            </td>
          </tr>
          <tr>
            <td>PC SDX</td>
            <td>Non-Silicone</td>
            <td>
              Water based stabilized emulsion of mineral oil with fatty esters
            </td>
            <td>Paints, coatings, adhesives and admixtures</td>
          </tr>
          <tr>
            <td>PC NXZ</td>
            <td>Non-Silicone</td>
            <td>Blend of mineral oil with fatty acid esters</td>
            <td>
              In emulsion paints based on styrene butadiene, acrylic, PVC &amp;
              its copolymers, EVA, vinylidine chlorides and water soluble alkyds
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="clearfix" />
    <h4 className="h4 titleTop text-medium text-uppercase">
      Silicone Defoamers
    </h4>
    <div className="table-responsive">
      <table
        border={0}
        cellSpacing={0}
        cellPadding={0}
        width="100%"
        className="table table-bordered table-stripped"
      >
        <thead className="bg-primary text-white">
          <tr>
            <th width="20%" scope="col">
              Product Name
            </th>
            <th width="20%" scope="col">
              Chemical Type
            </th>
            <th width="25%" scope="col">
              Composition
            </th>
            <th width="35%" scope="col">
              Application
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>SG 20</td>
            <td>Silicone</td>
            <td>Polydimethyl siloxane compound emulsion</td>
            <td>Paints, coatings, adhesives and admixtures</td>
          </tr>
          <tr>
            <td>PC 8030</td>
            <td>Silicone</td>
            <td>Polydimethyl siloxane compound emulsion</td>
            <td>In water based paints where silicone can be compatible</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div className="clearfix" />
  </div>
</section>

</>


        
        );
    }
}


export  default  Biocides;