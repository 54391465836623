import React from "react";



class News extends React.Component{
    render(){
        return(
          <>
    
    <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1" style={{textAlign:"center"}}>
             <strong>Competencies</strong>{" "}
                </h2>
              </div>
    <div className="newsss">
      <img src="assets/images/Screenshot (62).png" />
      </div>

    <div className="newsses" style={{textAlign:"center", marginTop:"10px" }}>
      <img src="assets/images/Screenshot (60).png" />
      </div>
          <br />
          <br />
          <br />
      </>
      
        
        );
    }
}


export  default  News;