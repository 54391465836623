import React from "react";





class FoodDyes extends React.Component{
    render(){
        return(
       
          <section>
         <div className="heading">
                
                
                <h2 className="h1" style={{textAlign:"center"}}>
             <strong>Infinite Spectrum of Bright Colour from Natural Sources</strong>{" "}
                </h2>
              </div>
          <article
            id="post-3717"
            className="post-3717 page type-page status-publish hentry wpautop"
          >
            <div className="at-above-post-page addthis_tool"  />
            <div style={{ padding: "0 25px" }}>
              Naturally derived and creatively applied, Sensient’s colours from natural
              sources unite the bountiful resources from nature with unparalleled
              expertise in colour science and formulation. As the most vertically
              integrated supplier of natural colours, Sensient offers an unmatched range
              of natural colour shades, from simple component solutions to customized
              colour offerings. The dynamic range of Sensient’s natural colours enables
              brands to meet any production needs at specific cost-in-use targets. All
              raw materials for Sensient’s natural colours are qualified through our
              comprehensive food safety program, Certasure™, which includes robust
              testing protocols to not only provide full supply chain visibility but
              also product confidence and brand protection. As long as you are
              formulating with the right solution, colour from natural sources is bold,
              vibrant, and stable across a wide pH range.
            </div>
            <br />
            <br />
            <div className="container">
            <div>
              <div id="accordion" className="panel-group" style={{ margin: 0 }}>
                <div className="panel panel-default" style={{ margin: 0 }}>
                  <div
                    className="panel-heading "
                    style={{
                      overflow: "hidden",
                      backgroundColor: "#fff",
                      position: "relative"
                    }}
                  >
                    <h4 className="panel-title" style={{ float: "left", margin: 0 }}>
                      <a
                        style={{ color: "#c30000" }}
                        href="#collapse1"
                        data-toggle="collapse"
                        data-parent="#accordion"
                      >
                        NATURAL REDS
                        <span
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            color: "gray"
                          }}
                          className="glyphicon glyphicon-triangle-bottom"
                          aria-hidden="true"
                        />{" "}
                      </a>
                    </h4>
                  </div>
                  <div id="collapse1" className="panel-collapse collapse">
                    <div className="panel-body clearfix">
                      <img
                        className="aligncenter size-full wp-image-17594"
                        style={{ float: "right" }}
                        src="/assets/images/natural-red-example (2).jpg"
                        alt="natural-red-example"
                      />
                      <p
                        style={{
                          fontSize: 24,
                          color: "#818386",
                          lineHeight: "initial"
                        }}
                      >
                        From black carrot to beet juice to carmine and many more,
                        Sensient’s range of reds from nature are excellent alternatives
                        to Red 40 and Red 3.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-default" style={{ margin: 0 }}>
                <div
                  className="panel-heading "
                  style={{
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    position: "relative"
                  }}
                >
                  <h4 className="panel-title" style={{ float: "left", margin: 0 }}>
                    <a
                      style={{ color: "#f76600" }}
                      href="#collapse2"
                      data-toggle="collapse"
                      data-parent="#accordion"
                    >
                      NATURAL ORANGES
                      <span
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          color: "gray"
                        }}
                        className="glyphicon glyphicon-triangle-bottom"
                        aria-hidden="true"
                      />
                    </a>
                  </h4>
                </div>
                <div id="collapse2" className="panel-collapse collapse">
                  <div className="panel-body">
                    <img
                      className="aligncenter size-full wp-image-17601"
                      style={{ float: "right" }}
                      src="/assets/images/natural-orange-example (1).jpg"
                      alt="natural-orange-example"
                    />
                    <p
                      style={{ fontSize: 24, color: "#818386", lineHeight: "initial" }}
                    >
                      Bring savory and sweet flavors to life with Sensient’s full suite
                      of orange beta-carotenes, annatto solutions, paprika-based
                      colours, carrot juices, and more.
                    </p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default" style={{ margin: 0 }}>
                <div
                  className="panel-heading "
                  style={{
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    position: "relative"
                  }}
                >
                  <h4 className="panel-title" style={{ float: "left", margin: 0 }}>
                    <a
                      style={{ color: "#e9cd13" }}
                      href="#collapse4"
                      data-toggle="collapse"
                      data-parent="#accordion"
                    >
                      NATURAL YELLOWS
                      <span
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          color: "gray"
                        }}
                        className="glyphicon glyphicon-triangle-bottom"
                        aria-hidden="true"
                      />
                    </a>
                  </h4>
                </div>
                <div id="collapse4" className="panel-collapse collapse">
                  <div className="panel-body">
                    <img
                      className="aligncenter size-full wp-image-17609"
                      style={{ float: "right" }}
                      src="/assets/images/natural-yellow-example.jpg"
                      alt="natural-yellow-example"
                    />
                    <p
                      style={{ fontSize: 24, color: "#818386", lineHeight: "initial" }}
                    >
                      For every application under the sun, Sensient can provide a
                      natural yellow solution with label-friendly options such as
                      turmeric, vegetable juice, annatto, and beta-carotene, to name a
                      few.
                    </p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default" style={{ margin: 0 }}>
                <div
                  className="panel-heading "
                  style={{
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    position: "relative"
                  }}
                >
                  <h4 className="panel-title" style={{ float: "left", margin: 0 }}>
                    <a
                      style={{ color: "#287e1a" }}
                      href="#collapse5"
                      data-toggle="collapse"
                      data-parent="#accordion"
                    >
                      NATURAL GREENS
                      <span
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          color: "gray"
                        }}
                        className="glyphicon glyphicon-triangle-bottom"
                        aria-hidden="true"
                      />
                    </a>
                  </h4>
                </div>
                <div id="collapse5" className="panel-collapse collapse">
                  <div className="panel-body">
                    <img
                      className="aligncenter size-full wp-image-17606"
                      style={{ float: "right" }}
                      src="/assets/images/natural-green-example.jpg"
                      alt="natural-green-example"
                    />
                    <p
                      style={{ fontSize: 24, color: "#818386", lineHeight: "initial" }}
                    >
                      Sensient’s natural green shades wouldn’t be possible without our
                      foundation of blue shades. We can provide brands with blue and
                      yellow components to make green shades or through our advanced
                      blending capabilities, Sensient can offer a single delivery system
                      of greens too.
                    </p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default" style={{ margin: 0 }}>
                <div
                  className="panel-heading "
                  style={{
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    position: "relative"
                  }}
                >
                  <h4 className="panel-title" style={{ float: "left", margin: 0 }}>
                    <a
                      style={{ color: "#1a317e" }}
                      href="#collapse7"
                      data-toggle="collapse"
                      data-parent="#accordion"
                    >
                      NATURAL BLUES
                      <span
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          color: "gray"
                        }}
                        className="glyphicon glyphicon-triangle-bottom"
                        aria-hidden="true"
                      />
                    </a>
                  </h4>
                </div>
                <div id="collapse7" className="panel-collapse collapse">
                  <div className="panel-body">
                    <img
                      className="aligncenter size-full wp-image-17605"
                      style={{ float: "right" }}
                      src="/assets/images/natural-blue-example.jpg"
                      alt="natural-blue-example"
                    />
                    <p
                      style={{ fontSize: 24, color: "#818386", lineHeight: "initial" }}
                    >
                      From sky blue to indigo blueberry shades, Sensient’s unique
                      collection of natural blue sources includes our proprietary
                      vegetable juice, spirulina extract, and pH-stabilized
                      anthocyanins.
                    </p>
                  </div>
                </div>
              </div>
              <div id="accordion-2" className="panel-group" style={{ margin: 0 }}>
                <div className="panel panel-default" style={{ margin: 0 }}>
                  <div
                    className="panel-heading "
                    style={{
                      overflow: "hidden",
                      backgroundColor: "#fff",
                      position: "relative"
                    }}
                  >
                    <h4 className="panel-title" style={{ float: "left", margin: 0 }}>
                      <a
                        style={{ color: "#431a8e" }}
                        href="#collapse8"
                        data-toggle="collapse"
                        data-parent="#accordion-2"
                      >
                        NATURAL PURPLES
                        <span
                          style={{
                            position: "absolute",
                            right: 10,
                            top: 10,
                            color: "gray"
                          }}
                          className="glyphicon glyphicon-triangle-bottom"
                          aria-hidden="true"
                        />
                      </a>
                    </h4>
                  </div>
                  <div id="collapse8" className="panel-collapse collapse">
                    <div className="panel-body">
                      <img
                        className="aligncenter size-full wp-image-17608"
                        style={{ float: "right" }}
                        src="/assets/images/natural_purple.jpg"
                        alt="natural-purple-example"
                      />
                      <p
                        style={{
                          fontSize: 24,
                          color: "#818386",
                          lineHeight: "initial"
                        }}
                      >
                        Sensient’s portfolio of perfect purple shades stem from
                        vegetable juices or simple blends of natural reds and blues.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="panel panel-default" style={{ margin: 0 }}>
                <div
                  className="panel-heading "
                  style={{
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    position: "relative"
                  }}
                >
                  <h4 className="panel-title" style={{ float: "left", margin: 0 }}>
                    <a
                      style={{ color: "#671d1d" }}
                      href="#collapse9"
                      data-toggle="collapse"
                      data-parent="#accordion-2"
                    >
                      NATURAL BROWNS
                      <span
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          color: "gray"
                        }}
                        className="glyphicon glyphicon-triangle-bottom"
                        aria-hidden="true"
                      />
                    </a>
                  </h4>
                </div>
                <div id="collapse9" className="panel-collapse collapse">
                  <div className="panel-body">
                    <img
                      className="aligncenter size-full wp-image-17610"
                      style={{ float: "right" }}
                      src="/assets/images/natural-brown-example.jpg"
                      alt="natural-brown-example"
                    />
                    <p
                      style={{ fontSize: 24, color: "#818386", lineHeight: "initial" }}
                    >
                      Shades ranging from golden honey to amber to dark chocolate and
                      black are all available. Sensient’s solutions include a full suite
                      of caramel colours as well as botanical browns from fruit and
                      vegetable juices, including our{" "}
                      <a
                        style={{ color: "blue" }}
                        href="https://sensientfoodcolors.com/en-ap/color-solutions/natural-brown/"
                      >
                        Sienna™ fruit juice
                      </a>
                      .
                    </p>
                  </div>
                </div>
              </div>
              <div className="panel panel-default" style={{ margin: 0 }}>
                <div
                  className="panel-heading "
                  style={{
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    position: "relative"
                  }}
                >
                  <h4 className="panel-title" style={{ float: "left", margin: 0 }}>
                    <a
                      style={{ color: "#919191" }}
                      href="#collapse10"
                      data-toggle="collapse"
                      data-parent="#accordion-2"
                    >
                      NATURAL OPACITY
                      <span
                        style={{
                          position: "absolute",
                          right: 10,
                          top: 10,
                          color: "gray"
                        }}
                        className="glyphicon glyphicon-triangle-bottom"
                        aria-hidden="true"
                      />
                    </a>
                  </h4>
                </div>
                <div id="collapse10" className="panel-collapse collapse">
                  <div className="panel-body">
                    <img
                      className="aligncenter size-full wp-image-17607"
                      style={{ float: "right" }}
                      src="/assets/images/natural-opacity-example.jpg"
                      alt="natural-opacity-example"
                    />
                    <p
                      style={{ fontSize: 24, color: "#818386", lineHeight: "initial" }}
                    >
                      For whiteness or added opacity, Sensient offers titanium dioxide,
                      calcium carbonate, and our extensive suite of{" "}
                      <a
                        href="https://sensientfoodcolors.com/en-ap/color-solutions/avalanche/"
                        style={{ color: "blue" }}
                      >
                        Avalanche™ alternatives.
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </article>
          <br />
          <br />
          <br />
          
        </section>
        
        
        
        );
    }
}


export  default  FoodDyes;