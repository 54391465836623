import React from "react";
import {Link} from 'react-router-dom'




class Pipes extends React.Component{
    render(){
        return(
       
          <main className="application-page">
          <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Pipes and Fittings</h1>
                </div>
                <div className="product-img">
                  <picture>
                    <source
                
                      srcSet="https://www.goldstab.com/images/applications/pipesM.png"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0">
                      About Application:
                    </h4>
                    <div className="product-description">
                      <p>
                        PVC pipes play a very crucial role in the transport of potable
                        water. PVC pipes are safe, durable, cost-efficient and easy to
                        install. But, one needs to be conscious about the quality of
                        pipes as per regulatory requirements in terms of superior
                        mechanical strength to withstand high pressure in working
                        conditions and excellent chemical resistance to withstand
                        exposure of various chemicals.
                      </p>
                      <p>
                        Goldstab offers a wide range of lead-based one pack as well as
                        lead-free one pack stabilizers such as Calcium Zinc stabilizers
                        and Calcium organic stabilizers. Different formulations are
                        specially designed to fulfill specific requirements of
                        processors for various types of pipes that comply with Indian
                        standards and international standards.
                      </p>
                      <p>Goldstab offers products in both powder and flake forms.</p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>
          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
              <h4 className="font-24 black-text pt-5 "></h4>
            
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Calcium Based Stabilizers</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={4}>
                        Agricultural, Casing, Commercial, Pressure and SWR Pipes (BIS
                        4985, BIS 13592)
                      </td>
                      <td>Goldstab CZ 5208 ND</td>
                      <td>Non-dusting and economical stabilizer</td>
                    </tr>
                    <tr>
                      <td>
                        Goldstab CZ 5209
                        <br />
                        Goldstab CZ 5209 ND
                      </td>
                      <td>Good heat stability and gloss</td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 5216 ND</td>
                      <td>Provides excellent lubrication along with heat stability</td>
                    </tr>
                    <tr>
                      <td>Goldstab OBS 5220</td>
                      <td>Zinc-free stabilizer</td>
                    </tr>
                    <tr>
                      <td>Plumbing Pipe (ASTM D1785)</td>
                      <td>
                        Goldstab CZ 5212
                        <br />
                        Goldstab CZ 5212 ND
                      </td>
                      <td>Premium quality stabilizer for excellent whiteness</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Column Pipe</td>
                      <td>Goldstab CZ 5213</td>
                      <td rowSpan={2}>
                        Suitable stabilizer for superior performance and better
                        mechanical properties
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 5218 ND</td>
                    </tr>
                    <tr>
                      <td>Fittings</td>
                      <td>Goldstab CZ 5392 FT</td>
                      <td>Good heat stability, gloss and mechanical properties</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="table-note gray-text">
                Note: Various other grades available to suit specific needs of customers
              </p>
            
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Lead Based One Pack Stabilizers</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td>Column and Foam Core Pipes</td>
                      <td>Goldstab 3051</td>
                      <td>Excellent gelation along with heat stability</td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>SWR Pipe (BIS 13592)</td>
                      <td>Goldstab 3003 PP</td>
                      <td rowSpan={4}>Superior performance while processing</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3020 M</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3085</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3520</td>
                    </tr>
                    <tr>
                      <td rowSpan={6}>
                        Agriculture, Casing, Corrugated, Plumbing and Pressure Pipe
                        (ASTM D 1785, BIS 4985, BIS 12818)
                      </td>
                      <td>Goldstab 3016 N</td>
                      <td rowSpan={6}>
                        Provides higher output, reducing the processing cost &amp;
                        excellent mechanical and aesthetic properties
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 3018 P</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3051 KA</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3182</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3550 N</td>
                    </tr>
                    <tr>
                      <td>Goldstab PP 3040 K</td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>Commercial Pipes</td>
                      <td>Goldstab 3500 R</td>
                      <td rowSpan={4}>
                        Excellent lubrication allong with heat stability
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 3515</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3520 A</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3545</td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>Conduit Pipe</td>
                      <td>Goldstab 2050</td>
                      <td>Specially designed for single screw</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3014</td>
                      <td rowSpan={3}>High Thermal stability and good color hold</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3051 N</td>
                    </tr>
                    <tr>
                      <td>Goldstab 3184</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Suction Pipe</td>
                      <td>Goldstab 2008 M</td>
                    </tr>
                    <tr>
                      <td>Goldstab 2040</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Fittings</td>
                      <td>Goldstab 3028 FT</td>
                      <td rowSpan={2}>
                        Increases V.S.T. and provides higher thermal stability
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 3058 FT</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Liquid Stabilizers</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td>Suction Hose Pipe</td>
                      <td>Goldstab 815 XL</td>
                      <td>Higher heat resistance</td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>Braided Hose Pipe</td>
                      <td>Goldstab 812</td>
                      <td rowSpan={3}>
                        Higher heat resistance and transparent property
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 901</td>
                    </tr>
                    <tr>
                      <td>Goldstab 975</td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>Garden Pipe</td>
                      <td>Goldstab 983 GT</td>
                      <td rowSpan={3}>Provides translucent to transparent property</td>
                    </tr>
                    <tr>
                      <td>Goldstab 985 GT</td>
                    </tr>
                    <tr>
                      <td>Goldstab 987 GT</td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>Fittings</td>
                      <td>Goldstab 875 PR</td>
                      <td rowSpan={3}>
                        High Thermal stability, good color hold and gloss
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab 875 S</td>
                    </tr>
                    <tr>
                      <td>Goldstab 875 SP</td>
                    </tr>
                  </tbody>
                </table>
              </div>
           
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>  Lubricants</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Key features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td>Goldstab LUB 60 N</td>
                      <td rowSpan={3}>Rigid PVC Pipes</td>
                      <td>
                        Economical combination of internal and external lubricants
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab LUB 60 PW</td>
                      <td>
                        Low melting paraffin waxes for processing pipes with high filler
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab LUB 65</td>
                      <td>
                        Formulation with balance of internal and external lubricants for
                        high filler pipes.
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab LUB 68 FT</td>
                      <td rowSpan={2}>PVC Fittings</td>
                      <td>
                        Economical combination of internal and external lubricants
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab LUB 78 FT</td>
                      <td>
                        Premium combination of lubricants, processing aids and impact
                        modifier
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
           
            </div>
          </section>
 
        </main>
        
        
        );
    }
}


export  default  Pipes;