import React from "react";
import {Link} from 'react-router-dom'




class Career extends React.Component{
    render(){
        return(
          <>
   
   <>
  <div id="inner_banner_mobile" className="visible-xs-block">
    <img
      src="/assets/images/WhatsApp Image 2023-03-01 at 18.36.26.jpg"
      width={601}
      height={401}
      alt="Career"
      style={{ width: "100%", height: "auto" }}
    />
  </div>
  <div id="inner_banner" className="hidden-xs">
    <img
      src="/assets/images/WhatsApp Image 2023-03-01 at 18.36.26.jpg"
      width={3038}
      height={504}
      alt="Career"
      className="img-responsive"
    />
    <div className="abs_content">
      <div className="container">
        <div className="tagline_content">
       
      
        </div>
      </div>
    </div>
  </div>
  <div className="container m40" id="membership">
    <div className="row">
      <div className="col-md-12">
        <div className="heading">
          <div className="titleSeparator3" />
          <p className="no-margin">Working at Rivomax </p>
          <h2 className="h1">
            <strong>Career</strong>{" "}
          </h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-sm-12">
        <p>
          <strong>Quotes:</strong> We admire your talent, send us your resumes
          to{" "}
          <a href="mailto:info@rivomax.in" target="_blank">
          info@rivomax.in
          </a>
        </p>
        <p>
          <strong>Working at Rivomax </strong>
        </p>
        <p>Join hands with our experienced team to enlighten your career </p>
        <p>
          Explore the wide variety of chemicals from our Ocean of Suppliers{" "}
        </p>
        <p>
          If you are interested in becoming part of a leading company, driving
          your own career and working with outstanding brands and outstanding
          people to deliver sustainable business growth with us, then you've
          come to the right place.{" "}
        </p>
        <p>
          <strong>Internship Program : </strong>
        </p>
        <p>
          <strong />
          Join us on Our Journey: Our programs provide the experience, training
          and development you need as a newly-qualified graduate to accelerate
          your career and develop your full potential in a fast-growing and
          ambitious company.{" "}
        </p>
        <p>
          We are ready to shower our Knowledge to young and innovative
          Graduates, Join our Internship program{" "}
        </p>
        <p>
          To guide and support you with your tasks, to coach you and to provide
          useful tips. Rivomax mentors share their knowledge about market trends
          and will give you feedback for your future career. Last but not least,
          your mentor will always be a valuable advisor and personal contact.{" "}
        </p>
        <p>
          <strong>Current vacancies&nbsp;</strong>
        </p>
        <div className="item-block mb30">
          <header>
            <div className="hgroup">
              <h2>
                <a href="javascript:;"> Graduate Chemical Engineer</a>
              </h2>
         
              <p>
                <strong>Description : </strong>Candidate with at least two years
                of working experience in the field of sales for Chemicals and
                Industrial Raw materials. Should be fluent in English.
              </p>
              <p>
                <strong>Expertise : </strong>New Business Development,
                ,Retention of existing Customers, Willing to travel frequently
                in the designated territory.
              </p>
            </div>
            <ul className="job-details row">
              <li className="col-sm-4">
                <i className="fa fa-map-marker" />
                <span>Mumbai</span>
              </li>
              <li className="col-sm-4">
                <i className="fa fa-money" />
                <span>Market standard</span>
              </li>
              <li className="col-sm-4">
                <i className="fa fa-certificate" />
                Chemical Engineer/graduate
              </li>
            </ul>
          </header>
        </div>
        <div className="item-block mb30">
          <header>
            <div className="hgroup">
              <h2>
                <a href="javascript:;">Sale Manager</a>
              </h2>
            
              <p>
                <strong>Description : </strong>Should be smart, Graduate with
                Good Communication skills
              </p>
              <p>
                <strong>Expertise : </strong>Logistic activity and handing
                chemicals
              </p>
            </div>
            <ul className="job-details row">
              <li className="col-sm-4">
                <i className="fa fa-map-marker" />
                <span>Mumbai</span>
              </li>
              <li className="col-sm-4">
                <i className="fa fa-money" />
                <span>Market standard</span>
              </li>
              <li className="col-sm-4">
                <i className="fa fa-certificate" />
                Graduate
              </li>
            </ul>
          </header>
        </div>
        <div className="item-block mb30">
          <header>
            <div className="hgroup">
              <h2>
                <a href="javascript:;"> Logistic Coordinator </a>
              </h2>
          
              <p>
                <strong>Description : </strong> Should be smart, Graduate with
                Good Communication skills
              </p>
              <p>
                <strong>Expertise : </strong> Logistic activity and handing
                chemicals. Minimum experience 5-7 years
              </p>
            </div>
            <ul className="job-details row">
              <li className="col-sm-4">
                <i className="fa fa-map-marker" />
                <span>Mumbai</span>
              </li>
              <li className="col-sm-4">
                <i className="fa fa-money" />
                <span>Market standard</span>
              </li>
              <li className="col-sm-4">
                <i className="fa fa-certificate" />
                MBA
              </li>
            </ul>
          </header>
        </div>
      </div>
    </div>
  </div>
</>


        </>
        
        );
    }
}


export  default  Career;