import React from "react";
import {Link} from 'react-router-dom'




class MembershipAffiations extends React.Component{
    render(){
        return(
    
   
 
<>
  <div id="inner_banner_mobile" className="visible-xs-block">
    <img
      src="images/inner-banner/mobile/3.jpg"
      width={601}
      height={401}
      alt="Membership and Affiliations"
      style={{ width: "100%", height: "auto" }}
    />
  </div>
  <div id="inner_banner" className="hidden-xs">
    <img
      src="images/inner-banner/Membership-and-Affiliations.jpg"
      width={3038}
      height={505}
      alt="Membership and Affiliations"
      className="img-responsive"
    />
    <div className="abs_content">
      <div className="container">
        <div className="tagline_content">
          <h1>Membership and Affiliations</h1>
          <p>
            "Single stone cannot built a castle"; we have joined hands with
            various organizations worldwide
          </p>
        </div>
      </div>
    </div>
  </div>
  <div className="container m40" id="membership">
    <div className="row">
      <div className="col-md-12">
        <div className="heading">
          <div className="titleSeparator3" />
          <p className="no-margin">Our Social Networking Associations</p>
          <h2 className="h1">
            Membership and <strong>Affiliations</strong>
          </h2>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col-md-12">
        <div className="media">
          <div className="media-left ">
            {" "}
            <img src="images/members/members-1.jpg" alt="image" />
          </div>
          <div className="media-body">
            <h2>Indian Small Scale Paint Association</h2>
            <p className="media-heading">
              Indian Small Scale Paint Association was founded to promote and
              help its members to identify their strength and specialize in it .
            </p>
            <p className="media-heading">
              {" "}
              GDCI has been actively attending their conferences and have also
              showcased our products in the exhibitions organised by ISSPA
            </p>
            <p />
          </div>
        </div>
        <div className="media">
          <div className="media-left ">
            {" "}
            <img src="images/members/members-2.jpg" alt="image" />
          </div>
          <div className="media-body">
            <h2>Indian Paint Association</h2>
            <p className="media-heading">
              Indain Paint Assaociation was founded to help paint industries
              both Financially and Technically about their products.
            </p>
            <p className="media-heading">
              GDCI, Follow and spread the good practices learned from the
              conferences which was organized by Indian Paint Association
            </p>
          </div>
        </div>
        <div className="media p-t-20 p-b-20">
          <div className="media-left ">
            {" "}
            <img src="images/members/members-3.jpg" alt="image" />
          </div>
          <div className="media-body">
            <h2>Indian Plastics Institute</h2>
            <p className="media-heading">
              As a member of Indian Plastic Institue , GDCI learn about new
              formulations and interventions in Plastic industries by visiting
              Indian Plastic Institute's conferences and share our knowledge
              with our Suppliers and Clients to enhance their product's quality.
            </p>
          </div>
        </div>
        <div className="media p-t-20 p-b-20">
          <div className="media-left ">
            {" "}
            <img src="images/members/members-5.jpg" alt="image" />
          </div>
          <div className="media-body">
            <h2>The Tamil Nadu Plastics Manufacturers Association</h2>
            <p className="media-heading">
              The Tamil Nadu Plastics Manufacturers Association's aim is to
              unite all the plastic manufacturers and help to sustain in their
              business.{" "}
            </p>
            <p className="media-heading">
              Being a Member ,GDCI works for betterment of the plastic
              industries by giving them quality and affordable cost raw
              materials from our foreign suppliers.
            </p>
          </div>
        </div>
        <div className="media p-t-20 p-b-20">
          <div className="media-left ">
            {" "}
            <img src="images/members/members-4.jpg" alt="image" />
          </div>
          <div className="media-body">
            <h2>Australian Alumni Association</h2>
            <p className="media-heading">
              Our Director is a member of Australian Alumni Association, From
              where he learns about the current Business and Techinical trends
              prevailing in other part of the globe and Spreads it among our
              suppliers and customers which helps us to achieve new heights.
            </p>
          </div>
        </div>
        <div className="media p-t-20 p-b-20">
          <div className="media-left ">
            {" "}
            <img src="images/members/members-6.jpg" alt="image" />
          </div>
          <div className="media-body">
            <h2>Tamilnadu Dyes and Chemicals Merchants Association</h2>
            <p className="media-heading">
              Tamilnadu Dyes and Chemicals Merchants Association is a community
              and social service association founded to protect and develop the
              merchants dealing dyes and chemicals in tamilnadu. GDCI teams with
              other companies in the association and taking joint actions
              towards success in business.
            </p>
          </div>
        </div>
        <div className="media p-t-20 p-b-20">
          <div className="media-left ">
            {" "}
            <img src="images/members/members-7.jpg" alt="image" />
          </div>
          <div className="media-body">
            <h2>All India Rubber Industries Association</h2>
            <p className="media-heading">
              The All India Rubber Industries Association (AIRIA) is a not for
              profit making body serving the rubber industry and trade with the
              objectives of safeguarding and promoting interests of the
              industry.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</>

    
        
        );
    }
}


export  default MembershipAffiations;