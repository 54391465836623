

import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Carousels from "react-elastic-carousel";

import 'react-slideshow-image/dist/styles.css'

import React, { useEffect, useState } from 'react';
import TestiMonialsDetails from './TestiMonialsDetails'
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

import './TestiMonials.css'

// import Carousel from "react-elastic-carousel";
import Item from "./Item";
import Topnav from '../components/topnav/Topnav';


const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2 },
  { width: 768, itemsToShow: 3 },
  { width: 1200, itemsToShow: 4 },
];




class Home extends React.Component{


  
    render(){


      const testiMonials = [
        {
            name: 'Mr. S.R.Marda, Ms. Ritu Marda, Directors',
            description: 'We are happy to be associated with M/s.Rivomax and Chemicals since 2005. They are our supplier for various grades of Cabot Carbon Black which we use for our productions of Masterbatches, RIVOMAX maintains suffcient stocks and are logistically suited to us . Quality of material supplied has always been good and no issues of any type has been faced till date. ',
            address: 'Prabhu Polycolor Pvt Ltd',
            add:'Chennai - India',
            img: '/assets/images/test-1.jpg'
        },
        {
            name: 'Kunal Mutha - CEO',
            description: ' RIVOMAX has helped us ease our supply chain constraints by providing round the clock service with excellent customer oriented approach. We look forward to work with RIVOMAX and continue strengthening our business relationship ',
            address: 'MM Polytech',
            add:'Chennai - India',
            img: '/assets/images/test-2.jpg'
        },
        {
            name: 'S.Viji - Purchase Department',
            description: 'RIVOMAX is an Inter-Dependent Historic Company with Modernistic Approach, Sincerity, Commitments to Quality and Supplies. Longevity of customers relationship and fraternity to customers had lead RIVOMAX for an unparalleled organic growth to where they are Today.',
            address: 'Art Life Commercial India Pvt Ltd',
            add:'Chennai - India',
            img: '/assets/images/test-3.jpg'
        },
        {
            name: 'Manoj Kumar - Sr. Ex. Materials.',
            description: 'The products you handle are really up to the mark of quality well planned for logistics solution, implementing cost-effective &amp; highly reliable solutions are the major focus you have made us to achieve. The back office is so nice with the order follow up &amp; logistics planning',
            address: 'Thejo Engineering Ltd',
            add:'Chennai - India',
            img: '/assets/images/test-4.jpg'
        },
    ]


    
const options = {
  loop: true,
  center: true,
  items: 3,
  margin: 0,
  autoplay: true,
  dots: true,
  autoplayTimeout: 8500,
  smartSpeed: 450,
  nav: false,
  responsive: {
      0: {
          items: 1
      },
      600: {
          items: 3
      },
      1000: {
          items: 3
      }
  }
};
        return(
          <>
       

          <div className="carousel-wrapper">
            <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false}>
                {/* <div className='propd'>
             
                    <img src="/assets/images/template_photo_3.jpg" />
                    
                </div> */}
                <div className='propd'>
               
               
                    <img src="/assets/images/banner/slider1.jpg" alt=''/>
                  
                </div>
                <div className='propd'>
                
               
                    <img src="/assets/images/banner/slider3.jpg" alt=''/>
                </div>
                <div className='propd'>
     
                
                    <img src="/assets/images/banner/slider2.jpg" alt=''/>
                </div>
                
               {/* <div className='propd'>
                <img src="/assets/images/globe_636x.jpg" />
                </div> */}
                
            </Carousel>
        </div>
          
          <div className="container">
            <div className="about text-center m40">
              <h2>
                {" "}
                <strong className="cmp_age">34</strong>{" "}
                <span style={{ display: "inline-block" }}>
                  <span className="cmp_yrs">Years of</span> Industry
                  <strong> Experience</strong>
                </span>{" "}
              </h2>
              <p>
              Rivomax Industries was incorporated in year 2015 as an extended arm for Rivochem Industries Pvt Ltd dealing in multiple product and raw materials across industries like Paper, Printing Ink, Paint, Plastic,
                Rubber, Detergents, Textiles, Leather, PVC , Lamination, Construction
                Chemicals Industry , Plywood Industry and other various industrial
                fields.
              </p>
            </div>
            <div className="row about_us">
              <div className="col-sm-4">
                <img
                  src="/assets/images/img-Trust.jpg"
                  width={480}
                  height={300}
                  alt="Trust"
                  className="img-responsive mb30"
                />
                <h2>Trust</h2>
                <div className="titleSeparator" />
                <p>
                  Trust is the most important ingredient for a business to be successful
                  in long run, We have worked for it and gained it from our principal
                  and customers since our company was founded.
                </p>
              </div>
              <div className="col-sm-4">
                <img
                  src="/assets/images/img-quality.jpg"
                  width={480}
                  height={300}
                  alt="Quality"
                  className="img-responsive mb30"
                />
                <h2>Quality</h2>
                <div className="titleSeparator" />
                <p>
                  Our quality standards are constantly benchmarked against global best
                  practices. This means that these are constantly upgraded to keep pace
                  with dynamics of the global environment.
                </p>
              </div>
              <div className="col-sm-4">
                <img
                  src="/assets/images/img-infrastructure.jpg"
                  width={480}
                  height={300}
                  alt="Infrastructure"
                  className="img-responsive mb30"
                />
                <h2>Infrastructure</h2>
                <div className="titleSeparator" />
                <p>
                  In order to serve the industry right - we have our well maintained
                  stockyard fully equipped with latest storage facilities and materials
                  handling equipments. We do not involve child labor.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="m40 rel" id="ch_msg">
            <div className="container">
              <div className="row rel">
                <div  style={{justifyContent:"center",textAlign:"center" ,display:"flex", backgroundColor:"red"}}>
                <div className="col-md-7">
                  <p className="inspir">
                    An Inspiration<span> forever</span>
                  </p>
                  --------------------------------------------
                  <p>
                  
                  
                    <strong>Jayantilal Solanki</strong>
                  </p>
                  <p> Founder | RIPL Group | Since 1989
 </p>
                  <p>Welcome. </p>
                  <p>
                    {" "}
                    RIPL Team is structured to help  Customers and partners to be more profitable. We deliver  value added proposition to the needs of our stake holders.
                  </p>
                  <p>
                    {" "}
                    Our people make the difference in delivering technical support! .{" "}
                  </p>
                  <p> Thank you for taking the time to learn more about us.</p>
                </div>
                </div>
                <div className="col-md-7 founder">
                  {/* <img
                    src="/assets/images/WhatsApp Image 2023-02-04 at 13.48.27.jpg"
                    width={589}
                    height={35}
                    alt="Founder"
                    className="img-responsive"
                  /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="m40">
            <div className="container text-center">
              <h2 className="h2">
                Industry &amp; <strong>Applications</strong>{" "}
              </h2>
              <div className="titleSeparator" />
              <p>Committed and Dedicated to Serve the Needs of Various Industries</p>
              <div className="row applications">
                <div className="col-xs-4">
                  <img src="/assets/images/icons/adhesives.svg" alt="Adhesives" />{" "}
                  <p>Packaging</p>
                </div>
                <div className="col-xs-4">
                  <img src="/assets/images/icons/construction.svg" alt="Construction" />{" "}
                  <p>Construction</p>
                </div>
                <div className="col-xs-4">
                  <img src="/assets/images/icons/ink.svg" alt="Ink" /> <p>Ink</p>
                </div>
                <div className="clearfix"> </div>
                <div className="col-xs-4">
                  <img
                    src="/assets/images/icons/paint-and-coating.svg"
                    alt="Paint and Coating"
                  />{" "}
                  <p>Paint and Coating</p>
                </div>
                <div className="col-xs-4">
                  <img src="/assets/images/icons/paper.svg" alt="Paper" /> <p>Paper</p>
                </div>
                <div className="col-xs-4">
                  <img src="/assets/images/icons/plastic.svg" alt="Plastic" /> <p>Plastic</p>
                </div>
                <div className="clearfix"> </div>
                <div className="col-xs-4">
                  <img src="/assets/images/icons/rubber.svg" alt="Rubber" /> <p>Rubber</p>
                </div>
                <div className="col-xs-4">
                  <img src="/assets/images/icons/textiles.svg" alt="Textiles" /> <p>Textiles</p>
                </div>
                <div className="col-xs-4">
                  <img src="/assets/images/icons/leather.svg" alt="Leather" /> <p>Leather</p>
                </div>
                <div className="clearfix"> </div>
                <div className="col-xs-4">
                  <img src="/assets/images/icons/detergent-soap.svg" alt="Detergent / Soap" />{" "}
                  <p>Detergent / Soap</p>
                </div>
                <div className="col-xs-4">
                  <img src="/assets/images/icons/pharma.svg" alt="Pharma" /> <p>Pharma</p>
                </div>
                <div className="col-xs-4">
                  <img
                    src="/assets/images/icons/food-and-beverages.svg"
                    alt="Food & Beverages"
                  />{" "}
                  <p>Food &amp; Beverages</p>
                </div>
              </div>
            </div>
          </div>
   
        
          <div id="counts" className="p60 text-center">    
      <div className="container">    
      <div className="row">
        <div className="col-sm-4">
          <h2>200+</h2>
          <p>Products Offering</p>
        </div>
        <div className="col-sm-4">
          <h2>95%</h2>
          <p> Technical Support </p>
        </div>
       
        <div className="col-sm-4">
          <h2>99%</h2>
          <p> Quality</p>
        </div>
    
      </div>
    </div>
  </div>


{/* <div className="m40 partners">
            <div className="container">
              <h2 className="h22" style={{fontSize:"30px",color:"red"}}><strong>What Clients Says</strong> </h2>
              <div className="titleSeparator" />
             
            </div>
          </div>
<OwlCarousel id="customer-testimonoals" className="owl-carousel owl-theme" {...options}>
                            {
                                testiMonials.length === 0 ?
                                    <div class="item">
                                        <div class="shadow-effect">
                                            <img class="img-circle" src="/assets/images/img-Trust.jpg" />

                                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>
                                        </div>
                                        <div class="testimonial-name">
                                            <h5>Rajon Rony</h5>
                                            <small>ITALY</small>
                                        </div>
                                    </div> :
                                    testiMonials.map(testiMonialDetail => {
                                        return (
                                            <TestiMonialsDetails testiMonialDetail={testiMonialDetail} key={testiMonialDetail._key} />

                                        )
                                    })
                            }
                        </OwlCarousel> */}


          <br />
          <br />
          <br />
          <br /><br />
          {/* <div className="m40 partners">
            <div className="container">
              <h2 className="h22" style={{fontSize:"30px",color:"red"}}><strong>Our Principles</strong> </h2>
              <div className="titleSeparator" />
             
            </div>
          </div> */}

{/* <Carousels breakPoints={breakPoints}>
   
          <Item><img src="/assets/images/RS-_Pigments_LOGO_small.jpg"/></Item>
          <Item><img src="/assets/images/logo (1).png"/></Item>
          <Item><img src="/assets/images/part-5.jpg"/></Item>
          <Item><img src="/assets/images/logo (2).png"/></Item>
          <Item><img src="/assets/images/srivilashydrotech-logo.png"/></Item>
          <Item><img src="/assets/images/logo (3).png"/></Item>
  
        </Carousels> */}
        <br />
<br />
        </>
        
        );
    }
}


export  default  Home;