import React from "react";
import {Link} from 'react-router-dom'




class ContactUS extends React.Component{
    render(){
        return(
          <>
          <div id="inner_banner_mobile" className="visible-xs-block">
            <img
              src="/assets/images/Contact-us-banner-1024x264.jpg"
              width={601}
              height={401}
              alt="Contact"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div id="inner_banner" className="hidden-xs">
            <img
              src="/assets/images/Contact-us-banner-1024x264.jpg"
              width={3038}
              height={505}
              alt="Contact  Rivomax"
              className="img-responsive"
            />
            <div className="abs_content">
              <div className="container">
                <div className="tagline_content">
                 
                </div>
              </div>
            </div>
          </div>
          <div className="container m40" id="membership">
            <div className="row">
              <div className="col-md-12">
                <div className="heading">
                  <div className="titleSeparator3" />
                  <p className="no-margin">We love to hear from you</p>
                  <h2 className="h1">
                    Contact<strong> Us</strong>
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="contact">
            <div className="container">
              <div className="row">
                <div className="col-md-4">
                  <h3>Registered Office</h3>
                  <p>
                    <strong style={{ fontSize: 18, color: "#DC343C" }}>
                     Rivomax
                    </strong>
                    <br />
                    806, Topiwala Center, Off S.V.Road, Goregaon (W), Mumbai, Maharashtra 400062
                  </p>
                  <h5 style={{ fontSize: 18, color: "#DC343C" }}><strong>Lab and R&D Centre :</strong></h5>
                  <p>B 40, MIDC, Gane Khadpoli, Chiplun 415605</p>
                  <ul className="contact-details">
                    <li>
                      <i className="lnr lnr-phone" aria-hidden="true" />{" "}
                      <strong>Ph No : </strong>+91 022-40160923
                    </li>
                   
                    <li>
                      <i className="lnr lnr-smartphone" aria-hidden="true" />{" "}
                      <strong>Mobile No : </strong>+91 9920974892
                    </li>
                    <li>
                      <i className="lnr lnr-user" aria-hidden="true" />{" "}
                      <strong>Contact Person : </strong>Mr Rohit Solanki
                    </li>
                    <li>
                      <i className="lnr lnr-envelope" aria-hidden="true" />{" "}
                      <strong>Online Support : </strong>
                      <table>
                        <tbody>
                          <tr>
                            <td width={148}>info@rivomax.in</td>
                          </tr>
                        </tbody>
                      </table>
                    </li>
                  </ul>
                </div>
                <div className="col-md-8 contact_form">
                  <h3>Contact Form</h3>
                  <form name="contact-form" id="EnquiryForm" action="#" method="POST">
                    <div className="row">
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="company"
                            placeholder="Company *"
                            defaultValue=""
                            required=""
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="fname"
                            placeholder="First Name"
                            defaultValue=""
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="lname"
                            placeholder="Last Name"
                            defaultValue=""
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="address"
                            placeholder="Address"
                            defaultValue=""
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="zip"
                            placeholder="ZIP"
                            defaultValue=""
                            onkeypress="return isNumberKey(event,this)"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="city"
                            placeholder="City"
                            defaultValue=""
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="country"
                            placeholder="Country"
                            defaultValue=""
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="email"
                            id="email"
                            placeholder="Email Address *"
                            defaultValue=""
                            pattern="^[A-Za-z0-9](([_\.\-]?[a-zA-Z0-9]+)*)@([A-Za-z0-9]+)(([\.\-]?[a-zA-Z0-9]+)*)\.([A-Za-z]{2,})$"
                            required=" "
                            type="email"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="mobile"
                            placeholder="Phone"
                            defaultValue=""
                            onkeypress="return isNumberKey(event,this)"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div>
                          <input
                            name="fax"
                            placeholder="Fax"
                            defaultValue=""
                            onkeypress="return isNumberKey(event,this)"
                            type="text"
                          />
                        </div>
                      </div>
                      <div className="col-sm-12">
                        <textarea
                          name="message"
                          cols={40}
                          rows={3}
                          placeholder="Message"
                          spellCheck="true"
                          value=""
                          defaultValue={""}
                        />
                      </div>
                      <div className="col-sm-6">
                        <div className="reqDemo_error" />
                        <button
                          type="submit"
                          className="btn btn-contact"
                          value="Submit Message"
                          name="cont_form"
                        >
                          Submit Message
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="cont_details m40">
            <div className="container">
              <div className="row">
                <div className="col-sm-5 ">
                  <div className="branch_img">
                    <img
                      src="/assets/images/contact-form.png"
                      width={756}
                      height={446}
                      className="img-responsive"
                    />
                  </div>
                </div>
                <div className="col-sm-5 col-sm-offset-2 branch">
                  <h3>Branches </h3>
                  <div className="branch_address">
                    <p>
                      <strong>Branches : </strong> Mumbai 
                    </p>
                    <p>
                      <strong>Resident Representative : </strong> Mumbai 
                    </p>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mapss"> <iframe
  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3768.7156185681433!2d72.84553471421407!3d19.163921654184477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b65268f042df%3A0x3f2b31d2ea79c05!2sRivochem%20Industries%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1675500911209!5m2!1sen!2sin"
  style={{width:"100%", height:"586px"}}
/> </div>
        </>
        

        
        );
    }
}


export  default  ContactUS;