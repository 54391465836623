import React from "react";
import './Wire.css';



class Wires extends React.Component{
    render(){
        return(
       
          <main className="application-page">
        <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Wires and Cables</h1>
                </div>
                <div className="product-img">
                  <picture>
                    <source
                    
                      srcSet="/assets/images/power-cable-accessories.png"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0">
                      About Application:
                    </h4>
                    <div className="product-description">
                    <p>
                        Insulations and sheathings of wires and cables are made by
                        covering conducting material with PVC. It provides excellent
                        volume resistivity in all weather conditions. Goldstab has
                        served the wire and cable industry for a long time successfully
                        by fulfilling the ever-changing expectations and requirements of
                        the industry. The use of Goldstab additive imparts long working
                        life of wires and cables and also improves specific attributes
                        such as heat stability, electrical properties and mechanical
                        properties.
                      </p>
                      <p>
                        Today, Goldstab has a wide range of stabilizers covering the
                        complete gamut of applications for service temperatures starting
                        from 70°C to 125°C, sheathing to Fire Retardant and Low Smoke
                        application that provides under-water heat stability and long
                        lasting color. Apart from these, Goldstab offers a range of
                        Lead-free stabilizers, Lead-based one pack stabilizers as well
                        as Lead based individual stabilizers like Tri Basic Lead
                        Sulphate.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>






          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
             
            
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Calcium Based Stabilizer</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Code
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={2}>Sheathing (70°C)</td>
                      <td>Goldstab CZ 5065</td>
                      <td>
                        Versatile &amp; economical formulation for dark coloured
                        sheathing
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 5095</td>
                      <td>Versatile product for brighter shades</td>
                    </tr>
                    <tr>
                      <td>Sheathing (90°C)</td>
                      <td>Goldstab CZ 5095 P</td>
                      <td>Versatile product for brighter shades</td>
                    </tr>
                    <tr>
                      <td rowSpan={3}>Type A wire</td>
                      <td>Goldstab CZ 5088 S</td>
                      <td>Specially designed for bright white shades</td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 5093</td>
                      <td>
                        Good thermal stability and versatile product for brighter shades
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 5094 K</td>
                      <td>Better color hold and lower water absorbtion</td>
                    </tr>
                    <tr>
                      <td rowSpan={4}>Type B (House wire) and Type C wire</td>
                      <td>Goldstab CZ 5094 K</td>
                      <td rowSpan={4}>
                        100 % HVDC passing &amp; excellent colour hold
                      </td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 54095</td>
                    </tr>
                    <tr>
                      <td> Goldstab CZ 54097</td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 54102</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Fire Retardant Low Smoke wire</td>
                      <td>Goldstab CZ 54097 K</td>
                      <td rowSpan={2}>Can sustain upto 105ºC ageing temperatue</td>
                    </tr>
                    <tr>
                      <td>Goldstab CZ 54156</td>
                    </tr>
                    <tr>
                      <td>Automobile Wire Harness</td>
                      <td>Goldstab CZ 54150</td>
                      <td>Specially designed for automobiles</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="table-note gray-text">
                Note: Various other grades available to suit specific needs of customers
              </p>
              
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong>Lead Based One Pack Stabilizers</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td>Sheathing</td>
                      <td>Goldstab 4014 VP</td>
                      <td>General Purpose and reprocessed material</td>
                    </tr>
                    <tr>
                      <td>Type A, Type B and Type C</td>
                      <td>Goldstab 4017</td>
                      <td>Excellent electrical properties such as V.R</td>
                    </tr>
                    <tr>
                      <td rowSpan={2}>Type B (House wire) and Type C</td>
                      <td>Goldstab 4618 K</td>
                      <td>Designed for high speed extruders</td>
                    </tr>
                    <tr>
                      <td>Goldstab 4620</td>
                      <td>Non - dusting stabilizer</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div class="d-flex align-items-end justify-content-end">
                  <a
                      href="https://drive.google.com/u/0/uc?id=10VLeDaaHxMxaxckDztWyWoRlCppxu0iY&export=download"
                      download="Brochure"
                      class=" download-brochure-link"
                      >Download Application Brochure
                      <svg
                          xmlns="http://www.w3.org/2000/svg"
                          enable-background="new 0 0 24 24"
                          class="download-brochure-icon"
                          viewBox="0 0 24 24"
                          fill="#1a1c80"
                      >
                          <g><rect fill="none" height="24" width="24" /></g>
                          <g>
                              <path
                                  d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z"
                              />
                          </g></svg
                  ></a>
              </div> */}
            </div>
          </section>
          {/* <section class="testimonial-section pt-5 pb-5 overflow-hidden">
          <div class="pt-5 pb-5">
              <div class="testimonial-slider-container ">
                  <div class="slider-arrows-container">
                      <div
                          class="prev-arrow testimonial-prev-arrow slick-slider-arrow"
                      >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.666"
                              height="17.342"
                              class="solution-left-arrow slick-left-arrow"
                              viewBox="0 0 20.666 17.342"
                          >
                              <g transform="translate(1 1.414)">
                                  <path
                                      class="slick-a"
                                      d="M-20219-20684l7.408,7.291-7.408,7.223"
                                      transform="translate(20230.258 20684)"
                                  />
                                  <line
                                      class="slick-b"
                                      x1="18.527"
                                      transform="translate(0 7.268)"
                                  />
                              </g>
                          </svg>
                      </div>
                      <div
                          class="next-arrow testimonial-next-arrow slick-slider-arrow"
                      >
                          <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20.666"
                              height="17.342"
                              class="solution-right-arrow slick-right-arrow"
                              viewBox="0 0 20.666 17.342"
                          >
                              <g transform="translate(1 1.414)">
                                  <path
                                      class="slick-a"
                                      d="M-20219-20684l7.408,7.291-7.408,7.223"
                                      transform="translate(20230.258 20684)"
                                  />
                                  <line
                                      class="slick-b"
                                      x1="18.527"
                                      transform="translate(0 7.268)"
                                  />
                              </g>
                          </svg>
                      </div>
                  </div>
                  <div class="testimonial-slider d-flex justify-content-center">
                      <div
                          class="testimonial-slider-element card d-flex flex-md-row justify-content-between shadow-lg"
                      >
                          <picture>
                              <source
                                  media="(min-width:426px)"
                                  srcset="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaD.png"
                              />
                              <img
                                  class="testimonial-image"
                                  src="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaM.png"
                                  alt="viren Mehta"
                              />
                          </picture>
                          <div
                              class="testimonial-slider-content d-flex flex-column justify-content-center"
                          >
                              <h4 class="person-name blue-text font-24 mb-0">
                                  Viren Mehta
                              </h4>
                              <p class="company-name font-14 mb-0">
                                  Nilkamal Pvt. Ltd.
                              </p>
                              <p class="person-content gray-text font-16">
                                  Goldstab Stabiliser are highly Effective and
                                  Strong. We are using there product form last 10
                                  years.
                              </p>
                          </div>
                      </div>
                      <div
                          class="testimonial-slider-element card d-flex flex-md-row justify-content-between shadow-lg"
                      >
                          <picture>
                              <source
                                  media="(min-width:426px)"
                                  srcset="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaD.png"
                              />
                              <img
                                  class="testimonial-image"
                                  src="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaM.png"
                                  alt="viren Mehta"
                              />
                          </picture>
                          <div
                              class="testimonial-slider-content d-flex flex-column justify-content-center"
                          >
                              <h4 class="person-name blue-text font-24 mb-0">
                                  Viren Mehta
                              </h4>
                              <p class="company-name font-14 mb-0">
                                  Nilkamal Pvt. Ltd.
                              </p>
                              <p class="person-content gray-text font-16">
                                  Goldstab Stabiliser are highly Effective and
                                  Strong. We are using there product form last 10
                                  years.
                              </p>
                          </div>
                      </div>
                      <div
                          class="testimonial-slider-element card d-flex flex-md-row justify-content-between shadow-lg"
                      >
                          <picture>
                              <source
                                  media="(min-width:426px)"
                                  srcset="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaD.png"
                              />
                              <img
                                  class="testimonial-image"
                                  src="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaM.png"
                                  alt="viren Mehta"
                              />
                          </picture>
                          <div
                              class="testimonial-slider-content d-flex flex-column justify-content-center"
                          >
                              <h4 class="person-name blue-text font-24 mb-0">
                                  Viren Mehta
                              </h4>
                              <p class="company-name font-14 mb-0">
                                  Nilkamal Pvt. Ltd.
                              </p>
                              <p class="person-content gray-text font-16">
                                  Goldstab Stabiliser are highly Effective and
                                  Strong. We are using there product form last 10
                                  years.
                              </p>
                          </div>
                      </div>
                      <div
                          class="testimonial-slider-element card d-flex flex-md-row justify-content-between shadow-lg"
                      >
                          <picture>
                              <source
                                  media="(min-width:426px)"
                                  srcset="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaD.png"
                              />
                              <img
                                  class="testimonial-image"
                                  src="https://www.goldstab.com/images/applications/pvc-pipes/virenMehtaM.png"
                                  alt="viren Mehta"
                              />
                          </picture>
                          <div
                              class="testimonial-slider-content d-flex flex-column justify-content-center"
                          >
                              <h4 class="person-name blue-text font-24 mb-0">
                                  Viren Mehta
                              </h4>
                              <p class="company-name font-14 mb-0">
                                  Nilkamal Pvt. Ltd.
                              </p>
                              <p class="person-content gray-text font-16">
                                  Goldstab Stabiliser are highly Effective and
                                  Strong. We are using there product form last 10
                                  years.
                              </p>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
            </section> */}
       
        </main>
        
        
        );
    }
}


export  default  Wires;