import React from "react";
import {Link} from 'react-router-dom'




class OurProfile extends React.Component{
    render(){
        return(
    
   
<>
  <div id="inner_banner" className="hidden-xs">
    <img
      src="assets/images/parrot_splash_art.jpg"
      width={3038}
      height={505}
      alt="About  Rivomax"
      className="img-responsive"
    />
    <div className="abs_content">
      <div className="container">
        <br />
        <div className="tagline_content">
         
          <br />
          <br />
   
          <br />
          <p style={{color: "white",fontSize:"25px",fontFamily:"serif", marginTop:"-150px",textAlign:"center"}}>
         
          </p>
        </div>
      </div>
    </div>
  </div>
  <div id="inner_banner_mobile" className="visible-xs-block">
    <img
      src="assets/images/parrot_splash_art.jpg"
      width={601}
      height={401}
      alt="About  Rivomax"
      style={{ width: "100%", height: "auto" }}
    />
  </div>
  <div className="container m40">
    <div className="heading">
      <div className="titleSeparator3" />
     
      <h2 className="h1">
        Company <strong>overview</strong>
      </h2>
    </div>
    <div className="row mb30">
      <div className="col-md-7 col-sm-7 col-xs-12">
        <p>
        
Rivomax Industries Pvt Ltd is trading division of Rivochem Industries pvt ltd. Since our establishment in 1989, we are supplying raw materials across industries like Paper, Printing Ink, Paint, Plastic, rubber, Detergents, Textiles, Leather, PVC and other various industrial fields.




        </p>
        <p>
          {" "}
          We are authorised distributors for the market leaders in each of the category. After four decades of customer service we have created a name for ourselves. It's the reward from our valuable customers for keeping up their trust in us.
        </p>
        <p>
          {" "}
          We also have a research and development unit where we come up with new products to cater the ever-growing needs of customers.
 We offer an extensive range of products and are doing research to improvise and expand our product range.
        </p>
        <p>
          {" "}
       
Our factory has the record to serve quality material consistently to the customers globally. The exemplary production process that we have in place makes our company standout from its competitors. Our growth has been built upon meeting customers need in a cost effective manner.
All products are approved and tested for quality by Quality Control before they are shipped to customers. Our exports have satisfied the needs of global customers and met their stringent needs on color, quality and quantity.
        </p>
      </div>
      <div className="col-md-5 col-sm-5 col-xs-12">
        <div className="finance_about_page">
          <img
            src="assets/images/about_us-page.jpg"
            alt="image"
            className="img-responsive"
          />
        </div>
      </div>
    </div>
  </div>
</>

        
        );
    }
}


export  default OurProfile;