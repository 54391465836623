import React from "react";





class Paints extends React.Component{
    render(){
        return(
       
          <main className="application-page">
       
 <section className="product-section top-space light-gray-bg">
            <div className="container product-section-container   flex-md-row justify-content-between flex-column">

<div className="pp">
<div  style={{ width:"100%", padding:"30px", color:"white"}} className="pipes-and-fitting-image p-5 left-application-part left-product-part">
                <div style={{}}  className="breadcrumbs d-none d-md-block">
              
                </div>
                <div style={{textAlign:"initial"}} className="product-name-container">
                  
                  <h1 style={{lineHeight:"50px", textAlign:"initial", fontWeight:"bolder", color:"#25b7cf"}} className="product-name">Paints and Coatings</h1>
                  <br />
                  <br />
                </div>
                <div className="product-img">
                  <picture>
                    <source
                     
                      srcSet="https://www.goldstab.com/images/applications/paints.png"
                    />
                    <img style={{width:"100%", height:"300px"}}
                      src="../images/applications/pipesM.png"
                      alt="PVC pipes & pipe fittings"
                      title="PVC pipes & pipe fittings"
                      className="img-fluid"
                    />
                  </picture>
                </div>
              </div>
              
              <div style={{width:"100%", }} className="">
                <div className="application-info-container product-info-container ">
                  <div className="product-description-container ">
                    <h4 style={{color:"#b90b0b", fontWeight:"bolder"}} className="product-description-heading pt-3 pt-md-0">
                      About Application:
                    </h4>
                    <div className="product-descriptions">
                      <p>
                        Paint is a homogeneous dispersion of pigments in resin. These
                        resins have long-chain structure that crosslink with each other
                        and form a protective film. Some paints dry physically, while
                        some dry chemically and need external oxidation catalysts like
                        metal octoates.
                      </p>
                      <p>
                        Driers are metal octoates widely used in the paint industry for
                        reducing drying time of the paint after application on the
                        substrate. Goldstab offers driers with very high purity and
                        consistent viscosity that ensures consistent performance in
                        terms of drying time and properties of the paint film after
                        complete drying. Apart from single driers Goldstab also offers
                        combination driers as per the requirements of the customers for
                        their specific needs. We also offer driers in the form of water
                        dispersion.
                      </p>
                    </div>
                  </div>
                  
                </div>
              </div>
</div>
             
            </div>
          </section>




          <section className="medium-gray-bg pt-5 pb-5">
            <div className="container">
           
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Metal Octoates and Naphthanates</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <tbody className="gray-text">
                    <tr>
                      <td>Cobalt Octoate</td>
                      <td>3%, 6%, 8%, 10%, 12%</td>
                    </tr>
                    <tr>
                      <td>Manganese Octoate</td>
                      <td>6%, 8%, 10%</td>
                    </tr>
                    <tr>
                      <td>Lead Octoate</td>
                      <td>18%, 24%, 32%, 36%</td>
                    </tr>
                    <tr>
                      <td>Iron Octoate</td>
                      <td>4%, 6%</td>
                    </tr>
                    <tr>
                      <td>Zinc Octoate</td>
                      <td>6%, 12%, 18%</td>
                    </tr>
                    <tr>
                      <td>Cerelium Octoate</td>
                      <td>6%, 10%, 12%</td>
                    </tr>
                    <tr>
                      <td>Zirconium Octoate</td>
                      <td>6%, 12%, 18%, 24%</td>
                    </tr>
                    <tr>
                      <td>Strontium Octoate</td>
                      <td>10%</td>
                    </tr>
                    <tr>
                      <td>Barium Octoate</td>
                      <td>12%</td>
                    </tr>
                    <tr>
                      <td>Calcium Octoate</td>
                      <td>3%, 5%, 6%, 10%</td>
                    </tr>
                    <tr>
                      <td>Potassium Octoate</td>
                      <td>15%</td>
                    </tr>
                    <tr>
                      <td>Copper Naphthanate</td>
                      <td>6%, 8%, 8.6%</td>
                    </tr>
                    <tr>
                      <td>Zinc Naphthanate</td>
                      <td>6%</td>
                    </tr>
                    <tr>
                      <td>Lithium Naphthanate</td>
                      <td>2%</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Goldstab Lead Base Combination Driers</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={2}>Paint</td>
                      <td>Goldstab 025</td>
                      <td>General purpose combination drier</td>
                    </tr>
                    <tr>
                      <td>Goldstab 026</td>
                      <td>Economical grade for general purpose</td>
                    </tr>
                    <tr>
                      <td>Printing Ink</td>
                      <td>Goldstab 028</td>
                      <td>Extremely fast drying property</td>
                    </tr>
                    <tr>
                      <td>Primer</td>
                      <td>Goldstab 068</td>
                      <td>Moderate drying properties</td>
                    </tr>
                    <tr>
                      <td>Enamel</td>
                      <td>Goldstab 121</td>
                      <td>White and light shade enamel</td>
                    </tr>
                  </tbody>
                </table>
              </div>
           
              <div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> Goldstab Lead Free Combination Driers (Co, Ca, Zr)</strong>{" "}
                </h2>
              </div>
              <div className="product-table-container overflow-auto">
                <table className="product-table table table-bordered white-bg">
                  <thead className="blue-text product-table-heading">
                    <tr>
                      <th scope="col" className="text-center">
                        Application
                      </th>
                      <th scope="col" className="text-center">
                        Product Grade
                      </th>
                      <th scope="col" className="text-center">
                        Key Features
                      </th>
                    </tr>
                  </thead>
                  <tbody className="gray-text">
                    <tr>
                      <td rowSpan={2}>Paint</td>
                      <td>Goldstab 223</td>
                      <td>General purpose combination drier</td>
                    </tr>
                    <tr>
                      <td>Goldstab 233</td>
                      <td>For fast drying</td>
                    </tr>
                    <tr>
                      <td>Enamel</td>
                      <td>Goldstab 2E9</td>
                      <td>White and light shade enamel</td>
                    </tr>
                    <tr>
                      <td>Printing Ink</td>
                      <td>Goldstab 369</td>
                      <td>Extremely fast drying property</td>
                    </tr>
                    <tr>
                      <td>Water dispersion paint</td>
                      <td>Goldstab 933</td>
                      <td>Water dispersion drier for Alkyd</td>
                    </tr>
                  </tbody>
                </table>
              </div>
           
            </div>
          </section>
        
        
        </main>
        
        
        );
    }
}


export  default  Paints;