import React from "react";
import {Link} from 'react-router-dom'




class CZStearates extends React.Component{
    render(){
        return(
          <>
         
          
          <div className="container m40" id="membership">
            <div className="row">
              <div className="col-md-12">
                <div className="heading">
                 
              
                  <h2 className="h1">
                    <strong>MICROSTAB ADDITIVES</strong>{" "}
                  </h2>
                  <p>
                    <strong>
                  ADDITIVES ENGINEERED FOR PVC COMPOUNDS & MASTERBATCH.
                  </strong>
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <p>
                  <strong>About Us:</strong> Rivochem Industries India Pvt Ltd incorporated in year 1989, situated in Mumbai, Maharashtra. Company is 
indulged in Pigment, Colorants and Additives Manufacturing.{" "}
                 
                </p>
                <p>
                Rivochem Industries India Pvt Ltd incorporated in year 1989, situated in Mumbai, Maharashtra. Company is 
indulged in Pigment, Colorants and Additives Manufacturing.In Year 2017, RIPL diversified to manufacturing and exports of PVC Stabilizers and Stearates under the brand 
MICROSTAB. Being already catering to various industries such as soaps, detergents, paints, coatings, 
construction, textiles, paper, inks, plastics & polymers this new diversification helped to increase the portfolio 
and reach.

                </p>
               
                <p>We are ISO 9001:2015 Quality Management System and ISO 14001:2015 Environmental Management System 
certified company.  </p>
                <p>
                Our growth has been built upon meeting customers need in a cost effective manner. All products are approved 
and tested for quality by Quality Control before they are shipped to customers. Our QC & R&D are installed 
with the equipment necessary for the consistent and quality assurance.
{" "}
                </p>
              
                <p>
                  <strong>Vision& Mission: : </strong>
                </p>
                <p>
                  <strong />
                  Rivochem Industries Pvt Ltd’s vision is to meet customer demands with a comprehensive range of products. 
Our mission is to cater to new global markets and meet ever-changing customer demands with ground-breaking 
products. We are undertaking extensive product research and relentlessly pursue technological perfection to 
give customers cost effective and quality products.
{" "}
                </p>
                
              
                <p>
                  <strong>Manufacturing Facility:&nbsp;</strong>
                </p>
                <p>
                Our installed capacity is 3000 MT per annum with expansion capacity of same.
                </p>
             
                <div className="heading">
                
                
                <h2 className="h1">
             <strong> PVC ONE PACK – LEAD BASED</strong>{" "}
                </h2>
              </div>
                <p>
                MICROSTAB Lead Based is highly efficient Heat Stabilizer, lubricant chalet compound. It gives excellent 
Heat Stability and Flow properties at optimum dosages.

                </p>
                <div>
                  <img src="/assets/images/ssss.png" alt=" " />
                </div>
                <p>
                  <strong>Application:&nbsp;</strong>
                </p>
                <p>
                PVC Pipes, Profiles, Fittings, Suction Hose & Cables

                </p>
                <p>
                  <strong>Form:&nbsp;</strong>
                </p>
                <p>
                P – Powder, F – Flake

                </p>

                <p>
                  <strong>PVC ONE PACK – NON TOXIC
:&nbsp;</strong>
                </p>
                <p>
                MICROSTAB Calcium-Zinc stabilizer are based on combination of Calcium and Zinc Soaps, engineered 
together with organic and inorganic co-stabilizers to provide heat stability and lubricity to suit broad 
spectrum of processing conditions.
                </p>
                <p>
                Increased efficiency is achieved as a result of the synergistic effects of the compounds in comparison 
with single component at satisfactory dosage levels.
                </p>
                <div>
<img src="/assets/images/ddd.png" alt="" />
                </div>

                <p>
                Dosage may vary as per the formulation with filler.
                </p>
                
                <p>
                  <strong>Why Calcium Zinc?
:&nbsp;</strong>
</p>
<p>
  -Non Toxic
</p>
<p>
  -Phenol Free & Heavy Metals Free
</p>
<p>
  -Good Dynamic and Static Stability
</p>

<p>
      
<div className="heading">
                <div className="titleSeparator3" />
                
                <h2 className="h1">
             <strong> STEARATES & ONE PACK LUBRICAN</strong>{" "}
                </h2>
              </div>
</p>
<div>
<img src="/assets/images/sim.png" alt="" />
                </div>

                <p>
                  <strong>ONE PACK LUBRICANT
:&nbsp;</strong>
</p>

<p>
One pack Lubricant is specially formulated lubricant with internal and external lubricants with special 
processing aid for PVC

</p>


<p>
                  <strong>Quality Policy
:&nbsp;</strong>
</p>
<p>
Our quality control team is continuously working on innovation and upgradation of products at our in house 
well equipped lab. We support to our clients with technical inputs for maximum performance of our products.
</p>


<p>
                  <strong>Technical Support:

:&nbsp;</strong>
</p>

<p>
Our customer satisfaction approach enables us to use our resources and technical expertise at client's site. It 
results cost effective solutions for our client's.
</p>

<p>
                  <strong>Delivery & Supply Chain:
:&nbsp;</strong>
</p>
<p>
Our strategic location allow us to ship the goods with a short notice to domestic market and JNPT Mumbai.
</p>
              </div>
            </div>
          </div>
        </>
        

        
        );
    }
}


export  default  CZStearates;