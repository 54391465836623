import React from "react";
import {Link} from 'react-router-dom'




class WhyRevo extends React.Component{
    render(){
        return(
       
          <>
          <div id="inner_banner_mobile" className="visible-xs-block">
            <img
              src="assets/images/1000_F_274712828_KpT4bvY0aQoEcRCYuoIW71mjSfRJEVLO.jpg"
              width={601}
              height={401}
              alt="Why Rivomax"
              style={{ width: "100%", height: "auto" }}
            />
          </div>
          <div id="inner_banner" className="hidden-xs">
            <img
              src="assets/images/1000_F_274712828_KpT4bvY0aQoEcRCYuoIW71mjSfRJEVLO.jpg"
              width={3038}
              height={505}
              alt="Why Rivomax"
              className="img-responsive"
            />
            <div className="abs_content">
              <div className="container">
                <div className="tagline_content">
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  {/* <h1 style={{color: "red",fontSize:"40px",fontFamily:"serif"}}>Why Rivomax ?</h1>
                  <br />
                  <p style={{color: "red",fontSize:"40px",fontFamily:"serif"}}>
                    "Everyone needs a reason to believe and not to believe", here's the
                    reason for you to Choose Rivomax
                  </p> */}
                </div>
              </div>
            </div>
          </div>
          <div className="container m40" id="membership">
            <div className="row">
              <div className="col-md-12">
                <div className="heading">
                  <div className="titleSeparator3" />
                  <p className="no-margin">THE THING THAT MAKES US DIFFERENT</p>
                  <h2 className="h1">
                    Why <strong> Rivomax</strong>{" "}
                  </h2>
                </div>
              </div>
            </div>
            <div className="m40">
              <div className="container">
                <div className="row mb30">
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                    <div className="feature_box">
                      <div className="icon icon-halloween-test-tube" />
                      <h3>Four Decads of Experiences</h3>
                      <p>
                        Rivomax has vast experience of more than 4 decades in handling
                        their principal and valueable customers. We have grown by
                        experience and serving the market and keeping our customers
                        intact.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                    <div className="feature_box">
                      <div className="icon icon-customer" />
                      <h3>Customer Focused</h3>
                      <p>
                        At Rivomax - Customer is the Centrifugal - all our services and
                        action are defined by their demands for smooth functioning. We
                        own our customers and act as there profit generating zones for
                        them.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                    <div className="feature_box">
                      <i className="fa fa-location-arrow" />
                      <h3>Wide Range of Products</h3>
                      <p>
                        At Rivomax we have aged by years in adding various product
                        complementary to market needs and demands. We handle almost 1232
                        products to cater to all the demands of the market and industry.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mb30">
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                    <div className="feature_box">
                      <div className="icon icon-quality" />
                      <h3>High Quality</h3>
                      <p>
                        We take utmost care in selecting a product which we will be
                        promoting in the market. This has been our motto and this has
                        helped us in gaining trust of our customers. Rivomax stands for
                        QUALITY.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                    <div className="feature_box">
                      <div className="icon icon-business" />
                      <h3>Pan India Network</h3>
                      <p>
                        Rivomax has its prescence under PAN INDIA basis. We cater customers
                        from Kashmir to Kanyakumari and from Gujarat to Seven Sisters.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                    <div className="feature_box">
                      <div className="icon icon-ecological" />
                      <h3>Environmental Focus</h3>
                      <p>
                        Rivomax believes in trading with only Non-Hazardous chemicals. All
                        our principals have approvals from various statutory agencies
                        and follow the routine strictly.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row mb30">
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                    <div className="feature_box">
                      <div className="icon icon-internet" />
                      <h3>Representing Over 10 Branches</h3>
                      <p>
                        Since the time of our inception we have been trading with more
                        than 20 worldwide brands to cater the needs of Industry and
                        market.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                    <div className="feature_box">
                      <div className="icon icon-forklift-with-boxes" />
                      <h3>Warehousing</h3>
                      <p>
                        As growing market needs better services - We moved to Madhavaram
                        to serve better in present and for future. We have well equipped
                        warehouse/ team and own fleets to deliver services on time.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-4 col-xs-12 text-center">
                    <div className="feature_box">
                      <div className="icon icon-truck" />
                      <h3>Providing Complete Solutions</h3>
                      <p>
                        We beleieve in marketing and not selling. We're hand in glove
                        with our principals to train our customers team for better
                        results and upgrade their skills. We are here to stay and not to
                        fade.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
        
        );
    }
}


export  default  WhyRevo;